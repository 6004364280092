import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Editor } from '@tinymce/tinymce-react'
import AceEditor from 'react-ace'
import 'brace/mode/javascript'
import 'brace/theme/monokai'
import 'brace/snippets/javascript'
import 'brace/ext/language_tools'
import _ from 'lodash'

export default class SectionFormCodeEditor extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    editable: PropTypes.bool.isRequired,
    handleSectionContent: PropTypes.func.isRequired,
    section: PropTypes.object.isRequired,
    templateVariable: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = { content: '' }
  }

  componentDidMount = () => {
    const { section, templateVariable } = this.props
    this.setState({ content: section.content[templateVariable.name] })
  }

  handleEditorChange = (content, editor) => {
    const { section, handleSectionContent, templateVariable } = this.props
    this.setState({ content }, handleSectionContent(section, templateVariable, content))
  }

  render() {
    const { editable } = this.props
    const { content } = this.state
    // Disable error messaging in the ACE editor gutter, since we use the editor
    //   for fragments which are invalid as standalone HTML.
    let editor = ace.edit(document.getElementById('section-form-code-editor'))
    editor.getSession().setUseWorker(false)
    editor.getSession().setUseWrapMode(true)

    return (
      <div id="code-editor">
        <AceEditor
          editorProps={{
            $blockScrolling: Infinity,
          }}
          mode="javascript"
          theme="monokai"
          name="section-form-code-editor"
          onChange={this.handleEditorChange}
          readOnly={!editable}
          fontSize={14}
          showGutter={true}
          style={{ width: '100%' }}
          highlightActiveLine={true}
          value={content}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showLineNumbers: true,
            tabSize: 2,
          }}
        />
      </div>
    )
  }
}
