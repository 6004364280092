import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

export default class CreateSegmentFormDialog extends Component {
  // keydown/keyup event.keyCodes:
  // 32 -> Spacebar
  static propTypes = {
    dialogTitle: PropTypes.string.isRequired,
    handleCreate: PropTypes.func.isRequired,
    openFormDialog: PropTypes.object.isRequired,
    segmentHelper: PropTypes.object.isRequired,
    title: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.state = {
      open: false,
      newSegment: {
        name: '',
        range_start: '',
        range_end: '',
        nickname: '',
      },
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleColumnChange = event => {
    let segmentUpdated = this.state.newSegment
    if (event.target.numeric) {
      segmentUpdated[event.target.name] = Number(event.target.value)
    } else {
      segmentUpdated[event.target.name] = event.target.value
    }
    this.setState({ newSegment: segmentUpdated })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  renderCreateButton = () => {
    const { handleCreate } = this.props
    const { newSegment } = this.state
    return (
      <Button
        onClick={event => {
          handleCreate(event, newSegment), this.handleClose()
        }}
        color="primary">
        Create
      </Button>
    )
  }

  render() {
    const { newSegment, open } = this.state
    const { dialogText, dialogTitle, handleCreate, openFormDialog, segmentHelper } = this.props

    return (
      <div>
        <Button style={{ textTransform: 'none' }} onClick={this.handleClickOpen}>
          {openFormDialog}
        </Button>
        <Dialog open={open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{dialogText}</DialogContentText>
            <TextField
              autoFocus
              fullWidth
              required
              margin="normal"
              id="name"
              label="Name"
              type="name"
              name="name"
              onChange={this.handleColumnChange}
              InputLabelProps={{ shrink: true }}
              value={newSegment.name}
            />
            <TextField
              fullWidth
              margin="normal"
              id="nickname"
              label="Nickname"
              type="Name"
              name="nickname"
              onChange={this.handleColumnChange}
              InputLabelProps={{ shrink: true }}
              value={newSegment.nickname}
            />
            <TextField
              fullWidth
              required
              id="range-start"
              label="Range Start"
              name="range_start"
              onChange={this.handleColumnChange}
              type="number"
              InputLabelProps={{ shrink: true }}
              margin="dense"
              value={newSegment.range_start}
            />
            <TextField
              fullWidth
              required
              id="range-end"
              label="Range End"
              name="range_end"
              onChange={this.handleColumnChange}
              type="number"
              InputLabelProps={{ shrink: true }}
              margin="dense"
              value={newSegment.range_end}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            {segmentHelper.validToCreate(newSegment) ? this.renderCreateButton() : null}
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
