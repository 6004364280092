import { CampaignAPI } from '../apis/campaign_api.js.jsx'
import { DateUtils } from '../utils.js.jsx'
import Moment from 'moment'

class CampaignHelper {
  constructor() {
    this.updateQueue = []
    this.updateTimer = null

    this.flushUpdates = this.flushUpdates.bind(this)
  }

  flushUpdates() {
    if (this.updateTimer) {
      clearTimeout(this.updateTimer)
      this.updateTimer = null
    }
    let promises = this.updateQueue.map(function(simpleCampaign) {
      return CampaignAPI.patch(simpleCampaign.id, simpleCampaign).catch(function(error) {
        console.error('Failed to update campaign with id: ' + simpleCampaign.id + '. ' + error)
      })
    })
    this.updateQueue = []
    return Promise.all(promises)
  }

  update(simpleCampaign) {
    if (!this.updateTimer) {
      this.updateTimer = setTimeout(this.flushUpdates, 3000)
    }
    let index = this.updateQueue
      .map(function(queuedCampaign) {
        return queuedCampaign.id === simpleCampaign.id
      })
      .indexOf(true)
    if (index >= 0) {
      this.updateQueue[index] = Object.assign(this.updateQueue[index], simpleCampaign)
    } else {
      this.updateQueue.push(simpleCampaign)
    }
  }

  index(params) {
    return CampaignAPI.index(params)
  }

  blank(id) {
    return {
      id: id,
      name: '',
      date: '',
      sent_at: '',
      email_list_id: '',
      wait_time_minutes: 0,
      subject_test_size: 0,
      reviewed: false,
      ab_test_campaign_id: '',
    }
  }

  find(id) {
    return new Promise(function(resolve, reject) {
      return CampaignAPI.show(id)
        .then(function(jsonData) {
          if (jsonData.name === null) {
            jsonData.name = ''
          }
          if (jsonData.sent_at === null) {
            jsonData.sent_at = ''
          }
          jsonData.date = DateUtils.prettyDateFromISOString(jsonData.date)
          resolve(jsonData)
        })
        .catch(function(error) {
          console.error('Failed to load campaign id: ' + id + '. ' + error)
        })
    })
  }

  configureEmails(campaignId) {
    return CampaignAPI.configureEmails(campaignId)
  }

  schedule(campaignId, isotime) {
    return CampaignAPI.schedule(campaignId, isotime)
  }

  unschedule(campaignId) {
    return CampaignAPI.unschedule(campaignId)
  }

  sendToSailthru(campaignId, scheduledIsoTime) {
    return CampaignAPI.sendToSailthru(campaignId, scheduledIsoTime)
  }

  patchSailthruCampaigns(campaignId) {
    return CampaignAPI.patchSailthruCampaigns(campaignId)
  }

  // Returns an object useful time data to represent the scheduled
  // time in pacific time
  // {
  //     isodate: '2018-04-01T09:00:00Z',
  //     date: new Date(),
  //     offset: -8;
  //     hour: 9,
  //     hourString: '09',
  //     minute: 0,
  //     minuteString: '00'
  // }
  getScheduledTimeInPacificTime(simpleCampaign) {
    let timeObj = {}
    if (['', null, undefined].indexOf(simpleCampaign.scheduled_time) < 0) {
      timeObj.isodate = simpleCampaign.scheduled_time
      timeObj.date = new Date(timeObj.isodate)
      timeObj.offset = DateUtils.getPacificTimeOffset(timeObj.isodate) == '-08:00' ? -8 : -7
      let tmpHour = timeObj.date.getUTCHours() + timeObj.offset
      timeObj.hour = ((tmpHour % 24) + 24) % 24
      timeObj.hourString = timeObj.hour.toString()
      if (timeObj.hourString.length == 1) {
        timeObj.hourString = '0' + timeObj.hourString
      }
      timeObj.minute = timeObj.date.getUTCMinutes()
      timeObj.minuteString = timeObj.minute.toString()
      if (timeObj.minuteString.length == 1) {
        timeObj.minuteString = '0' + timeObj.minuteString
      }
    }
    return timeObj
  }

  getEstimatedEmailSizes = campaignId => {
    return CampaignAPI.getEstimatedEmailSizes(campaignId)
  }

  reports(campaignId) {
    return CampaignAPI.reports(campaignId)
  }

  validToCreate(newCampaign) {
    if (
      newCampaign.name &&
      newCampaign.name.length > 0 &&
      newCampaign.email_list_id != null &&
      newCampaign.email_list_id > 0 &&
      newCampaign.date &&
      new Moment(newCampaign.date).format('YYYY-MM-DD') > new Moment().subtract(1, 'day').format('YYYY-MM-DD')
    ) {
      return true
    } else {
      return false
    }
  }

  associateAds(campaignId) {
    return CampaignAPI.associateAds(campaignId)
  }

  createAndConfigure(newCampaign) {
    CampaignAPI.create(newCampaign).then(campaign => {
      window.location.href = `/campaigns/${campaign.id}/build`
    })
  }

  // takes an array of email objects, sends each of them the specified test emails, and
  // yields a single Promise for the resulting resolves or first reject.
  testSend = (campaignId, emailIds, testSendRecipients) => {
    return Promise.all(emailIds.map(emailId => CampaignAPI.testSend(campaignId, emailId, testSendRecipients)))
  }
}

export { CampaignHelper }
