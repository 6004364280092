import React, { Component } from 'react'
import PropTypes from 'prop-types'

// This component will display boxes that can be toggled on and off.
export default class ToggleBoxes extends Component {
  static propTypes = {
    // boxes: A list of hashes. Each hash should have 2 entries, name (will
    //   be displayed in the box) and enabled (if true, box will be blue)
    boxes: PropTypes.arrayOf(PropTypes.object).isRequired,
    // toggleFunc: when a box is clicked, this function will be called with the related box's name
    toggleFunc: PropTypes.func.isRequired,
    // menuOptions: A list of strings that will be displayed in a menu
    menuOptions: PropTypes.array,
    // menuFunc: when a menu item is clicked it will call this function with the related string
    menuFunc: PropTypes.func,
    // displayMenu: if false, the menu will not be displayed, and the menu props will not be used
    displayMenu: PropTypes.bool,
    editable: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)
  }

  classFor(box) {
    let classString = 'segment-toggle-box'
    if (box.enabled) {
      classString += ' active-segment'
    }
    if (this.props.editable) {
      classString += ' clickable'
    }
    return classString
  }

  boxFuncFor(box) {
    return event => (this.props.editable ? this.props.toggleFunc(box.name) : null)
  }

  menuSelect() {
    return event => {
      return this.props.editable ? this.props.menuFunc(event.target.value) : null
    }
  }

  renderMenu() {
    const { displayMenu, menuOptions } = this.props
    if (displayMenu && menuOptions && menuOptions.length > 0) {
      return (
        <select className="segment-toggle-menu" value="" onChange={this.menuSelect()}>
          <option value="">Add A Segment</option>
          {menuOptions.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      )
    }
    return null
  }

  renderToggleBoxes() {
    return (
      <div className="segment-toggle-boxes">
        {this.props.boxes.map(box => {
          return (
            <div className={this.classFor(box)} onClick={this.boxFuncFor(box)} key={box.name}>
              {box.name}
            </div>
          )
        })}
      </div>
    )
  }

  render() {
    return (
      <div className="toggle-boxes">
        {this.renderToggleBoxes()}
        {this.renderMenu()}
      </div>
    )
  }
}
