import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FontAwesome from 'react-fontawesome'

// This contains the UI for click_count and url for the report section details body.
export default class ReportSectionForm extends Component {
  static propTypes = {
    urls: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)
  }

  urlSums() {
    const { urls } = this.props
    let cumulativeClicks = {}

    for (const [key, value] of Object.entries(urls)) {
      if (cumulativeClicks[value.sg_event_url]) {
        cumulativeClicks[value.sg_event_url] += value.click_count
      } else {
        cumulativeClicks[value.sg_event_url] = value.click_count
      }
    }
    return cumulativeClicks
  }

  renderUrlsAndClicks() {
    const { urls } = this.props
    if (urls == null) {
      return []
    }
    let cumulativeClicks = this.urlSums()
    let eSRWUrls = []
    for (const [key, value] of Object.entries(cumulativeClicks)) {
      eSRWUrls.push(
        <td>
          <span className="table-clicks">{`${value}`}</span>
          <a className="url-link" href={`${key}`}>{`${key}`}</a>
        </td>,
      )
    }
    return eSRWUrls
  }

  render() {
    const { urls } = this.props
    let idKey = 0
    if (urls) {
      return (
        <div key={''}>
          <table className="report-section-urls">
            <tbody>
              {this.renderUrlsAndClicks().map(form => (
                <tr key={idKey++}>{form}</tr>
              ))}
            </tbody>
          </table>
        </div>
      )
    }
  }
}
