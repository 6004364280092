import { SectionTemplateVariableAPI } from '../apis/section_template_variable_api.js.jsx'
import { BatchFunctionCaller } from '../utils.js.jsx'

class SectionTemplateVariableHelper {
  constructor(updateCallback) {
    this.updateQueue = new BatchFunctionCaller(this.bulkUpdateSectionTemplatesVariables, 2000, updateCallback)
  }

  bulkUpdateSectionTemplatesVariables(stvDictionary) {
    let promiseArray = []
    for (let stvId in stvDictionary) {
      SectionTemplateVariableAPI.patch(stvId, stvDictionary[stvId])
    }
    return Promise.all(promiseArray)
  }

  // Future updates: Make this operate like AdHelper where it merges
  // unknown keys in the 'options' key because 'options' is a json blob.
  // Requires changes to the rails controller/model to update properly
  // For now options will be update in full
  queueUpdate(stvId, key, newValue) {
    let queueObject = this.updateQueue.getQueueItem(stvId) || { id: stvId }
    queueObject[key] = newValue
    this.updateQueue.queue(stvId, queueObject)
  }

  create(variableData) {
    return SectionTemplateVariableAPI.create(variableData)
  }

  index(params) {
    return SectionTemplateVariableAPI.index(params || {})
  }

  update(variableId, variableData) {
    return SectionTemplateVariableAPI.update(variableId, variableData)
  }

  del(stvId) {
    return SectionTemplateVariableAPI.del(stvId)
  }

  image = (stvId, imgData) => {
    return SectionTemplateVariableAPI.image(stvId, imgData)
  }

  blank() {
    return {
      section_template_: 0,
      name: '',
      variable_type: '',
      ordering: 0,
      default_value: '',
      options: {},
    }
  }
}

export { SectionTemplateVariableHelper }
