import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Checkbox,
  TextField,
  FormControl,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  InputLabel,
  InputLabelProps,
  NativeSelect,
} from '@material-ui/core'
import Moment from 'moment'

class CreateCampaignDialog extends Component {
  static propTypes = {
    campaignHelper: PropTypes.object.isRequired,
    createCampaignDialogOpen: PropTypes.bool.isRequired,
    dialogTitle: PropTypes.string.isRequired,
    emailListHelper: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleCreateAndConfigure: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      isAbTestCampaign: false,
      date: new Moment(Date.now()).add(1, 'days').format('YYYY-MM-DD'),
      newEmailListId: '',
      name: 'CHANGE ME',
    }
  }

  handleChanges = event => {
    if (event.target) {
      if (event.target.checked) {
        this.setState({ ...this.state, [event.target.name]: event.target.checked })
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }
  }

  renderCreateButton = () => {
    const { emailListHelper, handleCreateAndConfigure, handleClose } = this.props
    const { date, isAbTestCampaign, name, newEmailListId } = this.state
    return (
      <Button
        onClick={event => {
          handleCreateAndConfigure({
            date: date,
            name: name,
            email_list_id: newEmailListId,
            template: emailListHelper.nameForId(newEmailListId),
            is_ab_test_campaign: isAbTestCampaign,
          }),
            handleClose()
        }}
        color="primary">
        Configure
      </Button>
    )
  }

  validateAndRenderConfigureButton = () => {
    const { date, name, newEmailListId } = this.state
    const { campaignHelper } = this.props
    if (campaignHelper.validToCreate({ date: date, name: name, email_list_id: newEmailListId })) {
      return this.renderCreateButton()
    }
  }

  render() {
    const { date, isAbTestCampaign, name, newEmailListId } = this.state
    const {
      createCampaignDialogOpen,
      campaignHelper,
      dialogText,
      dialogTitle,
      emailListHelper,
      handleClose,
      handleCreate,
      handleOpenCreateCampaignDialog,
      openFormDialog,
    } = this.props

    return (
      <div>
        <Button style={{ textTransform: 'none' }} onClick={handleOpenCreateCampaignDialog}>
          {openFormDialog}
        </Button>
        <Dialog
          open={createCampaignDialogOpen}
          onEntered={event => this.handleChanges(event)}
          onClose={handleClose}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContent>
            <InputLabel htmlFor={`select-dropdown-campaign`}>Email List</InputLabel>
            <NativeSelect
              id={`select-dropdown-campaign-email-list`}
              fullWidth
              name="newEmailListId"
              onChange={this.handleChanges}
              required
              value={newEmailListId}>
              <option key={`option-${emailListHelper.nameForId(newEmailListId)}`} value={newEmailListId}>
                {!_.isEmpty(newEmailListId) && emailListHelper.nameForId(newEmailListId)}
              </option>
              {emailListHelper.emailLists
                .filter(emailList => emailList.name != emailListHelper.nameForId(newEmailListId))
                .map(emailList => (
                  <option key={`option-${emailList.id}`} value={emailList.id}>
                    {emailList.name}
                  </option>
                ))}
            </NativeSelect>
            <TextField
              autoFocus
              fullWidth
              margin="normal"
              id="name"
              label="Name"
              type="text"
              name="name"
              onChange={this.handleChanges}
              InputLabelProps={{ shrink: true }}
              value={name}
            />
            <form>
              <TextField
                required
                fullWidth
                id="date"
                label="Send Date"
                type="date"
                name="date"
                onChange={this.handleChanges}
                InputLabelProps={{ shrink: true }}
                value={date}
              />
            </form>
          </DialogContent>
          <DialogActions>
            {this.validateAndRenderConfigureButton()}
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default CreateCampaignDialog
