import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core'

export default class CreateDripCampaignDialog extends Component {
  static propTypes = {
    dripCampaignHelper: PropTypes.object.isRequired,
    createDripCampaignDialogOpen: PropTypes.bool.isRequired,
    dialogTitle: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleOpenCreateDripCampaignDialog: PropTypes.func.isRequired,
    handleCreateAndNavigateTo: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      name: 'CHANGE ME',
    }
    this.default_email_envelope_attributes = {
      from_email: 'samparr@thehustle.co',
      from_name: 'Sam',
      reply_to_email: 'news@thehustle.co',
      reply_name: 'H.E.R.T. (Hustle Emergency Response Team)',
      unsubscribe_group: 2425,
      ip_pool: 'marketing',
    }
  }

  handleChanges = event => {
    if (event.target) {
      this.setState({ [event.target.name]: event.target.value })
    }
  }

  renderCreateButton = () => {
    const { handleCreateAndNavigateTo, handleClose } = this.props
    const { name } = this.state
    return (
      <Button
        onClick={event => {
          handleCreateAndNavigateTo(Object.assign({ name: name }, this.default_email_envelope_attributes))
          handleClose()
        }}
        color="primary">
        Create
      </Button>
    )
  }

  validateAndRenderCreateButton = () => {
    const { name } = this.state
    const { dripCampaignHelper } = this.props
    if (dripCampaignHelper.validNameForIndex(name)) {
      return this.renderCreateButton()
    }
  }

  render() {
    const { name } = this.state
    const {
      createDripCampaignDialogOpen,
      dripCampaignHelper,
      dialogText,
      dialogTitle,
      handleOpenCreateDripCampaignDialog,
      handleClose,
      handleCreate,
      openFormDialog,
    } = this.props

    return (
      <div>
        <Button style={{ textTransform: 'none' }} onClick={handleOpenCreateDripCampaignDialog}>
          {openFormDialog}
        </Button>
        <Dialog
          open={createDripCampaignDialogOpen}
          onEntered={event => this.handleChanges(event)}
          onClose={handleClose}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              required
              fullWidth
              margin="normal"
              id="name"
              label="Name"
              type="text"
              name="name"
              onChange={this.handleChanges}
              InputLabelProps={{ shrink: true }}
              value={name}
            />
          </DialogContent>
          <DialogActions>
            {this.validateAndRenderCreateButton()}
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
