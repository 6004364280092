import React from 'react'
import ArticleAPI from '../apis/article_api.js.jsx'
import { SectionHelper } from './section_helper.js.jsx'
import { DateUtils } from '../utils.js.jsx'
import { Link } from '@material-ui/core'
import Moment from 'moment'

export default class ArticleHelper {
  constructor() {
    this.updateQueue = []
    this.updateTimer = null

    this.flushUpdates = this.flushUpdates.bind(this)
    this.sectionHelper = new SectionHelper()
  }

  flushUpdates() {
    if (this.updateTimer) {
      clearTimeout(this.updateTimer)
      this.updateTimer = null
    }
    let promises = this.updateQueue.map(function(simpleArticle) {
      return ArticleAPI.patch(simpleArticle.id, simpleArticle).catch(function(error) {
        console.error(`Failed to update article with id: ${simpleArticle.id}`)
      })
    })
    this.updateQueue = []
    return Promise.all(promises)
  }

  deleteArticle(articleId) {
    return ArticleAPI.del(articleId)
  }

  update(simpleArticle) {
    if (!this.updateTimer) {
      this.updateTimer = setTimeout(this.flushUpdates, 3000)
    }
    let index = this.updateQueue
      .map(function(queuedArticle) {
        return queuedArticle.id === simpleArticle.id
      })
      .indexOf(true)
    if (index >= 0) {
      this.updateQueue[index] = Object.assign(this.updateQueue[index], simpleArticle)
    } else {
      this.updateQueue.push(simpleArticle)
    }
  }

  updateSlug(articleId, newSlug) {
    return ArticleAPI.patch(articleId, { slug: newSlug })
  }

  index() {
    return ArticleAPI.index()
  }

  blank(id) {
    return {
      id: id,
      name: '',
      published_at: '',
      edited_at: '',
      published: false,
      reviewed: false,
    }
  }

  setBlankProperties(jsonData) {
    if (jsonData.name == null) {
      jsonData.name = ''
    }
    return jsonData
  }

  articlesSections = articleId => {
    return ArticleAPI.articlesSections(articleId)
  }

  find(id) {
    return new Promise((resolve, reject) => {
      return ArticleAPI.show(id)
        .then(jsonData => {
          this.setBlankProperties(jsonData)
          jsonData.date = DateUtils.prettyDateFromISOString(jsonData.date)
          resolve(jsonData)
        })
        .catch(error => {
          console.error(`Failed to load article id: ${id}.`)
          reject(error)
        })
    })
  }

  validateSlug(prevSlug, newSlug) {
    return newSlug && prevSlug != newSlug && newSlug.trim().length > 3
  }

  validToCreate(newArticle) {
    if (newArticle.name && newArticle.name.trim().length > 0 && newArticle.slug && newArticle.slug.trim().length > 3) {
      return true
    } else {
      return false
    }
  }

  createAndNavigateTo(newArticle) {
    return new Promise((resolve, reject) => {
      ArticleAPI.create(newArticle)
        .then(article => {
          // Articles should always have a metadata section
          this.sectionHelper
            .createSectionForArticle('Article Metadata', article.id, 0)
            .then(metadata => {
              window.location.href = `/articles/${article.id}/build`
              resolve(true)
            })
            .catch(error => {
              console.error('Could not create article metadata section.')
              console.error(error)
              reject(false)
            })
        })
        .catch(error => {
          console.error('Could not create article. The slug used must be unique.')
          console.error(error)
          reject(error)
        })
    })
  }

  replicateAndGoToNewArticle(articleId) {
    return new Promise((resolve, reject) => {
      ArticleAPI.replicate(articleId)
        .then(res => {
          window.location.href = `/articles/${res.id}/build`
          resolve(true)
        })
        .catch(error => {
          console.error('Could not replicate this article. Possibly because the {slug}_copy is already taken.', error)
          reject(false)
        })
    })
  }

  renderArticleLink = articleSlug => {
    let envURLToArticle
    switch (location.hostname) {
      case 'hustlecms-staging.herokuapp.com':
        envURLToArticle = `https://the-hustle-trends-staging.herokuapp.com/articles/${articleSlug}`
        break
      case 'localhost':
        envURLToArticle = `${location.hostname}:3002/articles/${articleSlug}`
        break
      default:
        envURLToArticle = `https://trends.co/articles/${articleSlug}`
        break
    }

    return (
      <Link
        className="article-link"
        key={`link-${articleSlug}`}
        href={envURLToArticle}
        target="_blank"
        rel="noopener noreferrer">
        {articleSlug}
      </Link>
    )
  }
}
