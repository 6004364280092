import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import Section from './section.js.jsx'
import SectionSlot from './section_slot.js.jsx'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import SectionTemplateMenu from './section_template_menu.js.jsx'

@DragDropContext(HTML5Backend)
export default class SectionBoard extends Component {
  static propTypes = {
    addSection: PropTypes.func.isRequired,
    availableEmails: PropTypes.object.isRequired,
    campaignId: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    editable: PropTypes.bool.isRequired,
    sectionsList: PropTypes.array.isRequired,
    selectSidebarSection: PropTypes.func.isRequired,
    selectedSidebarSection: PropTypes.number,
    updateSectionOrderings: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      anchorEl: null,
    }
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  findSection = id => {
    const { sectionsList } = this.props
    const section = sectionsList.find(s => s.id === id)

    return {
      section,
      index: sectionsList.indexOf(section),
    }
  }

  renderSection = section => {
    const {
      availableEmails,
      campaignId,
      classes,
      editable,
      sectionsList,
      selectedSidebarSection,
      sortSections,
      updateSectionOrderings,
    } = this.props
    let isSelected = section.id == selectedSidebarSection
    return (
      <Section
        availableEmails={availableEmails}
        key={section.id}
        classes={classes}
        campaignId={campaignId}
        editable={editable}
        findSection={this.findSection}
        isSelected={isSelected}
        section={section}
        sectionsList={sectionsList}
        updateSectionOrderings={updateSectionOrderings}
      />
    )
  }

  renderSectionSlot = section => {
    const { classes, sectionsList, selectSidebarSection } = this.props
    return (
      <div
        key={`section-slot-${section.id}`}
        onClick={() => {
          selectSidebarSection(section.id)
        }}>
        <SectionSlot findSection={this.findSection} section={section} sectionsList={sectionsList}>
          {this.renderSection(section)}
        </SectionSlot>
      </div>
    )
  }

  checkForEmptySectionsListCSSClass = () => {
    const { classes, sectionsList } = this.props
    return sectionsList.length > 0 ? '' : classes.addSectionButtonContain
  }

  renderAddSectionButton = () => {
    const { classes, addSection, editable, sectionsList, setSectionType } = this.props
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)
    return (
      // The empty + Add Section button requires some special SCSS to render correctly in size
      <div className={this.checkForEmptySectionsListCSSClass()}>
        <Button
          disabled={!editable}
          key="add-section"
          children={!editable ? 'Not Editable' : '+ Add Section'}
          variant="outlined"
          className={classes.addSectionButton}
          aria-owns={open ? 'menu-appbar' : null}
          aria-haspopup="true"
          onClick={this.handleMenu}
        />
        <Menu
          className={classes.menu}
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={this.handleClose}>
          <SectionTemplateMenu addSection={addSection} handleClose={this.handleClose} divisionNames={['email']} />
        </Menu>
      </div>
    )
  }

  render() {
    const { classes, sectionsList } = this.props
    return (
      <div className={this.checkForEmptySectionsListCSSClass()}>
        {sectionsList.map(section => this.renderSectionSlot(section))}
        {this.renderAddSectionButton()}
      </div>
    )
  }
}
