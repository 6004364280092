import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputLabel,
  NativeSelect,
  TextField,
  Typography,
} from '@material-ui/core'
import PageHelper from '../helpers/page_helper.js.jsx'
import { SectionHelper } from '../helpers/section_helper.js.jsx'
import Moment from 'moment'

export default class PageBuilderConfigure extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    pageHelper: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    editable: PropTypes.bool.isRequired,
    selectedIndex: PropTypes.number.isRequired,
    show: PropTypes.bool.isRequired,
    updatePage: PropTypes.func.isRequired,
    updatePageSlug: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      estimatedPageSize: '',
      slug: '',
      slugUpdating: false,
      slugUpdateError: false,
    }

    this.pageHelper = new PageHelper()
  }

  componentDidMount = () => {
    this.setEstimates()
  }

  setEstimates = () => {
    const { page, pageHelper } = this.props

    pageHelper
      .getEstimatedPageSize(page.id)
      .then(estimatedPageSize => this.setState({ estimatedPageSize: estimatedPageSize['estimated_size'] }))
      .catch(error => console.error(error))
  }

  componentDidUpdate(prevProps) {
    const { selectedIndex } = this.props
    if (prevProps.selectedIndex !== 0 && selectedIndex === 0) {
      this.setEstimates()
    }
  }

  handleRenameSlug = event => {
    this.setState({ slug: event.target.value.trim(), slugUpdateError: false })
  }

  handleUpdatePageSlug = () => {
    this.setState({ slugUpdating: true })
    this.props
      .updatePageSlug(this.state.slug)
      .then(result => {
        this.setState({ slugUpdating: false, slugUpdateError: false })
      })
      .catch(error => {
        this.setState({ slugUpdating: false, slugUpdateError: true })
      })
  }

  getListingOptions = () => {
    return ['Unpublished', 'Published']
  }

  getListingStatus = () => {
    const { page } = this.props

    if (page.published) {
      return 'Published'
    } else {
      return 'Unpublished'
    }
  }

  deletePage = page => {
    const { pageHelper, editable } = this.props

    if (!editable) {
      console.log('Page is not editable')
      return
    }
    if (window.confirm(`Really delete the ${page.title} page?`)) {
      pageHelper.deletePage(page.id).then(() => {
        document.location.href = '/pages'
      })
    }
  }

  renderUpdateSlugText = () => {
    const { slugUpdateError } = this.state
    let text

    if (this.state.slugUpdating) {
      text = 'Slug Updating...'
    } else if (!slugUpdateError) {
      text = 'Update Slug'
    } else {
      text = 'Error updating slug. Try again with a unique slug.'
    }

    return text
  }

  renderDeletePageButton = page => {
    const { editable } = this.props

    return (
      <Button
        disabled={!editable}
        className="delete-button"
        onClick={() => {
          this.deletePage(page)
        }}
        children="Delete Page"
        color="secondary"
        variant="contained"
      />
    )
  }

  renderEstimatedPageSize = () => {
    const { page, pageHelper } = this.props
    const { estimatedPageSize } = this.state

    return (
      <Fragment>
        <Typography className="estimated-page-size">Estimated File Size as Email: ~{estimatedPageSize}</Typography>
        <hr />
      </Fragment>
    )
  }

  render() {
    const { page, pageHelper, classes, editable, show, updatePage } = this.props
    const { slug, slugUpdateError } = this.state
    if (!show) {
      return null
    }
    let listingStatus = this.getListingStatus()

    return (
      <div id="page-configure-container">
        {this.renderEstimatedPageSize()}
        <FormControl className={classes.formControl}>
          <TextField
            disabled
            type="text"
            name="id"
            margin="normal"
            className="page-configure-id"
            label="Page ID"
            onChange={updatePage}
            value={page.id}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            disabled={!this.props.editable}
            type="text"
            name="name"
            margin="normal"
            className="page-configure-name"
            label="Name"
            onChange={updatePage}
            value={page.name}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            disabled={!this.props.editable}
            type="text"
            multiline
            name="description"
            margin="normal"
            className="page-configure-description"
            label="Description"
            placeholder="Description"
            onChange={updatePage}
            value={page.description || ''}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            disabled={!this.props.editable}
            type="text"
            name="slug"
            margin="normal"
            className="page-configure-slug"
            label="Slug"
            onChange={this.handleRenameSlug}
            value={slug.length > 0 ? slug : page.slug}
          />
          <Button
            disabled={!pageHelper.validateSlug(page.slug, slug)}
            color={slugUpdateError ? 'secondary' : 'primary'}
            variant="contained"
            onClick={this.handleUpdatePageSlug}
            children={this.renderUpdateSlugText()}
          />
        </FormControl>
        <FormControl key={`form-control-listing`} className="select-dropdown-listing">
          <InputLabel key={`input-label-listing`} htmlFor={`select-dropdown-listing`}>
            Listing Status
          </InputLabel>
          <NativeSelect
            disabled={!this.props.editable}
            key={`native-select-listing`}
            value={listingStatus}
            onChange={updatePage}
            name={'Listing Status'}
            input={<Input key={`input-listing`} name={'Listing Status'} id={`select-dropdown-listing`} />}>
            <option key={`default-option-listing`} value={listingStatus}>
              {listingStatus}
            </option>
            {this.getListingOptions()
              .filter(listingOption => listingOption != listingStatus)
              .map(listingOption => (
                <option key={`option-${listingOption}`} value={listingOption}>
                  {listingOption}
                </option>
              ))}
          </NativeSelect>
        </FormControl>
        <FormControl className="delete-page-form">{this.renderDeletePageButton(page)}</FormControl>
      </div>
    )
  }
}
