import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Button, TextField, Typography } from '@material-ui/core'
import CampaignPreview from './campaign_preview.js.jsx'
import FormHelper from '../helpers/form_helper.js.jsx'
import Split from 'react-split'
import _ from 'lodash'

export default class CampaignBuilderEditor extends Component {
  static propTypes = {
    builderType: PropTypes.string.isRequired,
    availableEmails: PropTypes.object.isRequired,
    campaign: PropTypes.object.isRequired,
    campaignHelper: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    contactFillVerifier: PropTypes.bool.isRequired,
    createNewEmail: PropTypes.func.isRequired,
    deleteEmail: PropTypes.func.isRequired,
    deleteSection: PropTypes.func.isRequired,
    editable: PropTypes.bool.isRequired,
    emails: PropTypes.array.isRequired,
    getSection: PropTypes.func.isRequired,
    handleCloseAddSegmentPopup: PropTypes.func.isRequired,
    handleShowAddSegmentPopup: PropTypes.func.isRequired,
    markAsReviewed: PropTypes.func.isRequired,
    newEmail: PropTypes.object.isRequired,
    previewDirty: PropTypes.bool.isRequired,
    previewUpdated: PropTypes.func.isRequired,
    replicateSectionForEmails: PropTypes.func.isRequired,
    sectionHelper: PropTypes.object.isRequired,
    sectionsList: PropTypes.array.isRequired,
    segmentHelper: PropTypes.object.isRequired,
    selectedIndex: PropTypes.number,
    selectedSectionEstimatedSize: PropTypes.object.isRequired,
    selectedSectionId: PropTypes.number,
    setSegment: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    showAddSegmentPopup: PropTypes.bool.isRequired,
    templateVariables: PropTypes.object.isRequired,
    toggleEmailSegment: PropTypes.func.isRequired,
    updateCampaign: PropTypes.func.isRequired,
    updateEmail: PropTypes.func.isRequired,
    updateSectionColumn: PropTypes.func.isRequired,
    updateSectionContent: PropTypes.func.isRequired,
    uploadImage: PropTypes.func.isRequired,
  }

  segmentBoxes = () => {
    const { availableEmails, getSection, sectionsList, selectedSectionId } = this.props
    let section = getSection(selectedSectionId)
    let segmentBoxes = []
    for (let emailId in availableEmails) {
      let enabled = section.emails_sections.some(es => es.email_id == emailId)
      let segmentName = availableEmails[emailId]
      segmentBoxes.push({ section: section, name: segmentName, emailId: emailId, enabled: enabled })
    }
    return segmentBoxes.sort((a, b) => (a.name < b.name ? 1 : -1))
  }

  renderSegmentToggleBoxes = () => {
    const { availableEmails, classes, toggleEmailSegment } = this.props
    return (
      <div id="segment-toggle-boxes">
        {this.segmentBoxes().map(box => (
          <div
            key={box.name}
            className={`segment-toggle-box ${box.enabled ? '' : 'disabled-segment-toggle-box'}`}
            onClick={() => {
              toggleEmailSegment(box.section, box.emailId)
            }}>
            {box.name}
          </div>
        ))}
      </div>
    )
  }

  renderSelectedSectionEstimatedSize = () => {
    const { selectedSectionEstimatedSize, selectedSectionId } = this.props
    if (!selectedSectionId === selectedSectionEstimatedSize.section_id) {
      return null
    }
    return (
      <div className="estimated-size">
        <Typography variant="h6">Section size: ~{selectedSectionEstimatedSize['estimated_size']}</Typography>
      </div>
    )
  }

  handleChangeNickname = event => {
    const { getSection, sectionsList, selectedSectionId, updateSectionColumn } = this.props
    let section = getSection(selectedSectionId)
    let eventValue
    if (event.target.value.trim() == '') {
      eventValue = ''
    } else {
      eventValue = event.target.value
    }
    updateSectionColumn(section, { nickname: eventValue })
  }

  handleSectionContent = (section, templateVariable, value) => {
    this.props.updateSectionContent(section.id, { [templateVariable.name]: value })
  }

  handleUploadImage = (name, sectionId, templateVariableId) => event => {
    const { uploadImage } = this.props
    let file = event.target.files[0]
    if (file) {
      if (event.target['tiny_mce_image'] === true) {
        return uploadImage(sectionId, { srcFile: file, urlField: event.target.name, tiny_mce_image: true })
      } else {
        uploadImage(sectionId, { srcFile: file, urlField: event.target.name })
      }
    }
  }

  addedFunctions = () => {
    return {
      handleSectionContent: this.handleSectionContent,
      handleUploadImage: this.handleUploadImage,
    }
  }

  renderTemplateVariables = section => {
    const { campaign, builderType, classes, editable, sectionHelper, selectedSectionId, templateVariables } = this.props
    let sectionTemplateVariables = templateVariables[selectedSectionId]

    // sort section template variables to fix a production only bug where campaign editor sections were
    // appearing with reversed section template variable ordering

    if (_.isEmpty(sectionTemplateVariables)) {
      return null
    }
    sectionTemplateVariables.sort((a, b) => a.ordering - b.ordering)
    return sectionTemplateVariables.map(templateVariable => {
      return FormHelper.renderVariableByType(
        section,
        templateVariable,
        editable,
        classes,
        this.addedFunctions,
        sectionHelper,
        builderType,
        campaign,
      )
    })
  }

  renderDeleteSectionButton = section => {
    const { deleteSection, editable } = this.props
    return (
      <Button
        disabled={!editable}
        className="delete-button"
        onClick={() => {
          deleteSection(section)
        }}
        children="Delete Section"
        color="secondary"
      />
    )
  }

  renderReplicateSectionButton = section => {
    const { editable, replicateSectionForEmails } = this.props

    return (
      <Button
        disabled={!editable}
        className="replicate-button"
        onClick={() => {
          replicateSectionForEmails(section)
        }}
        children="Replicate Section"
        variant="contained"
      />
    )
  }

  render() {
    const {
      availableEmails,
      classes,
      editable,
      emails,
      getSection,
      previewDirty,
      previewUpdated,
      sectionsList,
      selectedSectionId,
      show,
    } = this.props
    let section = getSection(selectedSectionId)
    if (!show) {
      return null
    }
    if (!section || !selectedSectionId) {
      return (
        <div id="campaign-builder-editor-empty">
          <Typography>Please select a section in the sidebar.</Typography>
        </div>
      )
    }

    return (
      <div id="campaign-builder-editor">
        <Split
          sizes={[50, 50]}
          minSize={35}
          expandToMin={false}
          gutterSize={10}
          gutterAlign="center"
          snapOffset={30}
          dragInterval={1}
          direction="horizontal"
          cursor="col-resize"
          className="split-div">
          <div id="editor">
            <div id="header">
              <TextField
                disabled={!editable}
                type="text"
                fullWidth
                name="nickname"
                margin="normal"
                className="section-title-nickname"
                label={`Nickname for ${section.section_type}`}
                onChange={this.handleChangeNickname}
                value={section.nickname || ''}
              />
              <div id="segment-boxes">{this.renderSegmentToggleBoxes()}</div>
            </div>
            {this.renderSelectedSectionEstimatedSize()}
            <div id="body">
              {this.renderTemplateVariables(section)}
              {this.renderDeleteSectionButton(section)}
            </div>
          </div>
          <CampaignPreview
            availableEmails={availableEmails}
            classes={classes}
            emails={emails}
            previewDirty={previewDirty}
            previewHTMLType="section"
            previewUpdated={previewUpdated}
            sectionId={section.id}
            sectionContent={section.content}
          />
        </Split>
      </div>
    )
  }
}
