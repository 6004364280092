class EmailAPI {
  static jsonHeaders() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    }
  }

  // This updates the specified section with new section data
  // data is an object like { ordering: 1 } or { content: { previewText: 'foobar' } }
  // The server will merge content updates into the DB. You only
  // need to specify content updates, not the entire content hash
  static patch(emailId, data) {
    return new Promise(
      (function(that) {
        return function(resolve, reject) {
          var body = { email: data }
          var options = {
            credentials: 'same-origin',
            method: 'PATCH',
            body: JSON.stringify(body),
            headers: that.jsonHeaders(),
          }
          var url = '/emails/' + emailId + '.json'
          var request = new Request(url, options)
          fetch(request).then(function(response) {
            response
              .json()
              .then(jsonData => resolve(jsonData))
              .catch(error => reject(error))
          })
        }
      })(this),
    )
  }

  static del(emailId) {
    return new Promise((resolve, reject) => {
      var options = {
        credentials: 'same-origin',
        method: 'DELETE',
        headers: this.jsonHeaders(),
      }
      var url = '/emails/' + emailId + '.json'
      var request = new Request(url, options)
      fetch(request)
        .then(jsonData => resolve(jsonData))
        .catch(error => reject(error))
    })
  }

  static create(data) {
    return new Promise(
      (function(that) {
        return function(resolve, reject) {
          var body = { email: data }
          var options = {
            credentials: 'same-origin',
            method: 'POST',
            body: JSON.stringify(body),
            headers: that.jsonHeaders(),
          }
          var url = '/emails.json'
          var request = new Request(url, options)
          fetch(request).then(function(response) {
            response
              .json()
              .then(jsonData => resolve(jsonData))
              .catch(error => reject(error))
          })
        }
      })(this),
    )
  }

  // Returns a promise that will yield an array of section json data
  // with the specified emailId
  static show(emailId) {
    return new Promise(function(resolve, reject) {
      var url = '/emails/' + emailId + '.json'
      var request = new Request(url, { credentials: 'same-origin' })
      fetch(request).then(function(response) {
        response
          .json()
          .then(jsonData => resolve(jsonData))
          .catch(error => reject(error))
      })
    })
  }

  static preview(emailId) {
    return new Promise((resolve, reject) => {
      let url = `/emails/${emailId}/preview.html`
      let request = new Request(url, { credentials: 'same-origin' })
      fetch(request).then(response => {
        response
          .text()
          .then(responseText => resolve(responseText))
          .catch(error => {
            console.error(`Error fetching email preview for emailId: ${emailId}`)
            reject(error)
          })
      })
    })
  }

  // Returns a promise that will yield an array of email json data
  static index(params) {
    var paramsStr = Object.keys(params)
      .map(function(key) {
        return key + '=' + encodeURIComponent(params[key])
      })
      .join('&')
    return new Promise(function(resolve, reject) {
      var url = '/emails.json?' + paramsStr
      var request = new Request(url, { credentials: 'same-origin' })
      fetch(request).then(function(response) {
        response
          .json()
          .then(jsonData => resolve(jsonData))
          .catch(error => reject(error))
      })
    })
  }

  static updateSendGrid(emailId) {
    return new Promise(
      function(resolve, reject) {
        var body = {}
        var options = {
          credentials: 'same-origin',
          method: 'POST',
          body: JSON.stringify(body),
          headers: this.jsonHeaders(),
        }
        var url = '/emails/' + emailId + '/update_send_grid.json'
        var request = new Request(url, options)
        fetch(request).then(function(response) {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => reject(error))
        })
      }.bind(this),
    )
  }
}

export { EmailAPI }
