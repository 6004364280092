import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import NativeSelect from '@material-ui/core/NativeSelect'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import EmailLineView from './email_line_view.js.jsx'
import { SectionHelper } from '../helpers/section_helper.js.jsx'
import CampaignBuilderEditSegmentsDialog from './campaign_builder_edit_segments_dialog.js.jsx'
import CampaignBuilderSubjectLines from './campaign_builder_subject_lines.js.jsx'

export default class CampaignConfigure extends Component {
  static propTypes = {
    aBTestCampaign: PropTypes.object.isRequired,
    aBTestCampaignHelper: PropTypes.object.isRequired,
    campaign: PropTypes.object.isRequired,
    campaignReviewed: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    editable: PropTypes.bool.isRequired,
    emails: PropTypes.array.isRequired,
    isAbTestCampaign: PropTypes.func.isRequired,
    isAbTestCampaignPopulated: PropTypes.func.isRequired,
    setCampaignConfigured: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    updateCampaign: PropTypes.func.isRequired,
    updateSubjectsValid: PropTypes.func.isRequired,
    updateSubjectLines: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      aBTestCampaign,
      aBTestCampaignHelper,
      campaign,
      campaignReviewed,
      classes,
      editable,
      emails,
      isAbTestCampaign,
      isAbTestCampaignPopulated,
      setCampaignConfigured,
      show,
      updateCampaign,
      updateSubjectsValid,
      updateSubjectLines,
    } = this.props
    if (!show) {
      return null
    }

    return (
      <div id="campaign-configure-container">
        <FormControl className={classes.formControl}>
          <TextField
            required
            disabled={!this.props.editable}
            type="text"
            name="name"
            margin="normal"
            className="campaign-configure-name"
            label="Name"
            onChange={updateCampaign}
            value={campaign.name}
          />
        </FormControl>
        <FormControl className={classes.formControl} id="configure-date">
          <TextField
            disabled={!this.props.editable}
            required
            fullWidth
            id="date"
            label="Send Date"
            type="date"
            name="date"
            onChange={updateCampaign}
            InputLabelProps={{ shrink: true }}
            value={campaign.date}
          />
        </FormControl>
        <FormControl className={classes.formControl} id="email-subject-line">
          <FormControlLabel
            key={`form-control-subject-line`}
            control={
              <CampaignBuilderSubjectLines
                aBTestCampaign={aBTestCampaign}
                aBTestCampaignHelper={aBTestCampaignHelper}
                campaign={campaign}
                campaignReviewed={campaignReviewed}
                classes={classes}
                editable={editable}
                isAbTestCampaign={isAbTestCampaign}
                isAbTestCampaignPopulated={isAbTestCampaignPopulated}
                setCampaignConfigured={setCampaignConfigured}
                show={true}
                updateSubjectsValid={updateSubjectsValid}
                updateSubjectLines={updateSubjectLines}
              />
            }
            label="Email Subject Line*"
            labelPlacement="top"
          />
        </FormControl>
        <FormControl className={classes.formControl} id="email-line-view">
          {emails.map(email => (
            <EmailLineView campaign={campaign} email={email} key={email.id} />
          ))}
        </FormControl>
      </div>
    )
  }
}
