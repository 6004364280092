import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import _ from 'lodash'

const SectionFormTextField = props => (
  <FormControl className={props.classes.formControl}>
    <TextField
      disabled={!props.editable}
      label={_.capitalize(props.templateVariable.name)}
      className={props.classes.textField}
      fullWidth
      margin="normal"
      name={props.templateVariable.name}
      value={props.section.content[props.templateVariable.name] || ''}
      onChange={event =>
        props.handleSectionContent(props.section, props.templateVariable, _.get(event, 'target.value'))
      }
    />
  </FormControl>
)

export default SectionFormTextField

SectionFormTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  handleSectionContent: PropTypes.func.isRequired,
  section: PropTypes.object.isRequired,
  templateVariable: PropTypes.object.isRequired,
}
