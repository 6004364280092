import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import LinkIcon from '@material-ui/icons/Link'
import TextField from '@material-ui/core/TextField'
import _ from 'lodash'

const SectionFormUrlTextField = props => (
  <FormControl className={props.classes.formControl}>
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <LinkIcon />
          </InputAdornment>
        ),
      }}
      disabled={!props.editable}
      placeholder="https://adlinkurl.com/"
      label={_.capitalize(props.templateVariable.name)}
      name={props.templateVariable.name}
      fullWidth
      margin="normal"
      onChange={event =>
        props.handleSectionContent(props.section, props.templateVariable, _.get(event, 'target.value').trim())
      }
      value={props.section.content[props.templateVariable.name] || ''}
    />
  </FormControl>
)

export default SectionFormUrlTextField

SectionFormUrlTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  handleSectionContent: PropTypes.func.isRequired,
  section: PropTypes.object.isRequired,
  templateVariable: PropTypes.object.isRequired,
}
