import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CampaignAPI } from '../apis/campaign_api.js.jsx'
import Button from '@material-ui/core/Button'
import { CampaignHelper } from '../helpers/campaign_helper.js.jsx'
import { DateUtils } from '../utils.js.jsx'
import Moment from 'moment'

export default class CampaignBuilderDeliverButton extends Component {
  static propTypes = {
    campaign: PropTypes.object.isRequired,
    configured: PropTypes.bool.isRequired,
    deliverable: PropTypes.bool.isRequired,
    editable: PropTypes.bool.isRequired,
    handleSchedulingCampaign: PropTypes.func.isRequired,
    loadCampaign: PropTypes.func.isRequired,
    markCampaignSent: PropTypes.func,
    propertyPresentInCampaign: PropTypes.func.isRequired,
    scheduledHour: PropTypes.string.isRequired,
    scheduledMinute: PropTypes.string.isRequired,
    scheduling: PropTypes.bool.isRequired,
    schedulingCampaign: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      error: false,
      processing: false,
    }

    this.campaignHelper = new CampaignHelper()
  }

  scheduleCampaignButtonText = () => {
    const { campaign, propertyPresentInCampaign, schedulingCampaign } = this.props
    if (campaign != null) {
      if (schedulingCampaign) {
        return 'Working on that'
      } else if (!propertyPresentInCampaign('scheduled_time')) {
        return 'Schedule Autoposter'
      } else {
        return 'Unschedule Autoposter'
      }
    }
  }

  scheduleCampaign = (hour, minute) => {
    const { handleSchedulingCampaign, campaign, loadCampaign } = this.props
    let time = new Moment(campaign.date + 'T' + (hour || 9) + ':' + (minute || 0)).toISOString()
    handleSchedulingCampaign(true)
    this.campaignHelper.schedule(campaign.id, time).then(() => {
      loadCampaign()
      handleSchedulingCampaign(false)
    })
  }

  unscheduleCampaign = () => {
    const { campaign, handleSchedulingCampaign, loadCampaign } = this.props
    handleSchedulingCampaign(true)
    this.campaignHelper.unschedule(campaign.id).then(() => {
      loadCampaign()
      handleSchedulingCampaign(false)
    })
  }

  campaignScheduler = () => {
    const { campaign, propertyPresentInCampaign, scheduledHour, scheduledMinute, schedulingCampaign } = this.props
    if (!propertyPresentInCampaign('scheduled_time')) {
      return this.scheduleCampaign(scheduledHour, scheduledMinute)
    } else {
      return this.unscheduleCampaign()
    }
  }

  displayText = () => {
    const { campaign, propertyPresentInCampaign, scheduling } = this.props
    if (propertyPresentInCampaign('sent_at')) {
      return 'Campaign has been sent'
    }
    if (this.state.error) {
      return 'ERROR'
    }
    if (this.state.processing) {
      return 'Sending'
    } else {
      return this.scheduleCampaignButtonText()
    }
  }

  render() {
    const {
      campaign,
      configured,
      deliverable,
      editable,
      propertyPresentInCampaign,
      scheduling,
      showDeliverDialog,
    } = this.props

    const campaignScheduledOrSent = propertyPresentInCampaign('sent_at') || propertyPresentInCampaign('scheduled_time')

    return (
      <Button
        id="deliver-button"
        color={campaignScheduledOrSent ? 'primary' : 'secondary'}
        disabled={!configured || this.state.processing}
        variant="contained"
        onClick={this.campaignScheduler}>
        {this.displayText()}
      </Button>
    )
  }
}
