import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  NativeSelect,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import DripCampaignBuilderPreview from './drip_campaign_builder_preview.js.jsx'
import DripCampaignTestSendButton from './drip_campaign_test_send_button.js.jsx'
import { SectionHelper } from '../helpers/section_helper.js.jsx'
import { EmailValidator } from '../utils.js.jsx'
import Split from 'react-split'
import Moment from 'moment'
import _ from 'lodash'
import parser from 'cron-parser'

export default class DripCampaignBuilderTestActivate extends Component {
  static propTypes = {
    dripCampaign: PropTypes.object.isRequired,
    dripCampaignHelper: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    editable: PropTypes.bool.isRequired,
    previewDirty: PropTypes.bool.isRequired,
    previewUpdated: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    testSendEmails: PropTypes.object.isRequired,
    updateDripCampaign: PropTypes.func.isRequired,
    updateTestSendEmails: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      newTestEmail: '',
    }
  }

  handleUpdateTestSendEmails = event => {
    const { newTestEmail } = this.state
    this.setState({ newTestEmail: event.target.value })
    if (newTestEmail.includes('@') && newTestEmail[newTestEmail.length - 1] == ',') {
      this.checkNewTestEmailForEmails()
    }
  }

  checkNewTestEmailForEmails = () => {
    const { newTestEmail } = this.state
    const { updateTestSendEmails, testSendEmails } = this.props

    let possibleEmails = newTestEmail.trim().split(',')
    let newTestEmails = []
    let newEmailValidator, trimmedNewTestEmail
    possibleEmails.map(possibleEmail => {
      trimmedNewTestEmail = possibleEmail.trim()
      newEmailValidator = new EmailValidator(trimmedNewTestEmail)
      if (newEmailValidator.valid()) {
        newTestEmails.push(trimmedNewTestEmail)
      }
    })
    newTestEmails.map(newTestEmail => {
      if (!Object.keys(testSendEmails).includes(newTestEmail)) {
        updateTestSendEmails(newTestEmail)
      }
    })
  }

  printCrontabScheduleToDateString = () => {
    const { dripCampaign } = this.props

    let interval
    try {
      interval = parser
        .parseExpression(dripCampaign.crontab_schedule)
        .next()
        .toString()
    } catch (error) {
      interval = error.message
      console.error(error)
    }
    return interval
  }

  renderTestSendEmails = () => {
    const { dripCampaign, dripCampaignHelper, classes, testSendEmails, updateTestSendEmails } = this.props
    const { newTestEmail } = this.state

    return (
      <div className="test-sends">
        {Object.entries(testSendEmails).map(name => {
          return (
            <FormControlLabel
              key={`form-control-${name[0]}`}
              control={
                <Checkbox
                  key={`checkbox-${name[0]}`}
                  checked={testSendEmails[name[0]]}
                  onChange={() => {
                    updateTestSendEmails(name[0])
                  }}
                  value={name[0]}
                  name="checkbox"
                  classes={{
                    root: classes.root,
                    checked: classes.checked,
                  }}
                />
              }
              label={name[0]}
            />
          )
        })}
        <FormControl className={classes.formControl}>
          <TextField
            className={classes.textField}
            placeholder="Add more emails separated by commas"
            label="Test Send Emails"
            margin="normal"
            name="text"
            value={newTestEmail}
            onChange={event => {
              this.handleUpdateTestSendEmails(event, newTestEmail)
            }}
          />
        </FormControl>
        <FormControl key={`form-control-send-test-emails`} id="send-test-emails">
          <DripCampaignTestSendButton
            dripCampaign={dripCampaign}
            dripCampaignHelper={dripCampaignHelper}
            displayText="Send Test Email"
            testSendEmails={testSendEmails}
          />
        </FormControl>
      </div>
    )
  }

  renderActivateLabel = () => {
    const { composed, configured, dripCampaignHelper } = this.props

    if (dripCampaignHelper.isActionable(composed, configured)) {
      return `Activate for Sending: next run at ${this.printCrontabScheduleToDateString()}`
    } else {
      return `Configure and Compose must be completed before this Drip Campaign can be Activated`
    }
  }

  render() {
    const {
      classes,
      composed,
      configured,
      dripCampaign,
      dripCampaignHelper,
      editable,
      previewDirty,
      previewUpdated,
      show,
      updateDripCampaign,
    } = this.props
    if (!show) {
      return null
    }

    return (
      <div id="drip-campaign-deliver-container">
        <Split
          sizes={[50, 50]}
          minSize={100}
          expandToMin={false}
          gutterSize={10}
          gutterAlign="center"
          snapOffset={30}
          dragInterval={1}
          direction="horizontal"
          cursor="col-resize"
          className="split-div">
          <div id="deliver">
            <div id="drip-campaign-test-sender">
              <FormControlLabel
                className="activate"
                control={
                  <Checkbox
                    disabled={!dripCampaignHelper.isActionable(composed, configured)}
                    id="active"
                    type="checkbox"
                    onChange={updateDripCampaign}
                    checked={dripCampaign.active}
                    className={classes.checkbox}
                    name="active"
                    classes={{
                      root: classes.root,
                      checked: classes.checked,
                    }}
                  />
                }
                label={this.renderActivateLabel()}
              />
              <div className="header">
                <Typography className={classes.body1}>
                  Drip Campaign Name:&nbsp;
                  <div style={{ fontWeight: 400 }}>{dripCampaign.email_envelope['name']}</div>
                </Typography>
                <Typography className={classes.body1}>
                  Email Subject:&nbsp;
                  <div style={{ fontWeight: 400 }}>{dripCampaign.email_envelope['subject']}</div>
                </Typography>
                <Typography className={classes.body1}>
                  Page ID:&nbsp;
                  <div style={{ fontWeight: 400 }}>{dripCampaign.email_envelope['page_id']}</div>
                </Typography>
                <Typography className={classes.body1}>
                  From Name:&nbsp;
                  <div style={{ fontWeight: 400 }}>{dripCampaign.email_envelope['from_name']}</div>
                </Typography>
                <Typography className={classes.body1}>
                  From (Email):&nbsp;
                  <div style={{ fontWeight: 400 }}>{dripCampaign.email_envelope['from_email']}</div>
                </Typography>
                <Typography className={classes.body1}>
                  Reply Name:&nbsp;
                  <div style={{ fontWeight: 400 }}>{dripCampaign.email_envelope['reply_name']}</div>
                </Typography>
                <Typography className={classes.body1}>
                  Reply to (Email):&nbsp;
                  <div style={{ fontWeight: 400 }}>{dripCampaign.email_envelope['reply_to_email']}</div>
                </Typography>
              </div>
              <Typography className={classes.body1}>
                <div className="section-header">Test Sends:</div>
              </Typography>
              {this.renderTestSendEmails()}
            </div>
          </div>
          <DripCampaignBuilderPreview
            classes={classes}
            previewDirty={previewDirty}
            previewUpdated={previewUpdated}
            pageId={Number(_.get(dripCampaign, ['email_envelope', 'page_id']))}
          />
        </Split>
      </div>
    )
  }
}
