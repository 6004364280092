export default class EmailListAPI {
  // Returns a promise that will yield an array of email_list json data
  static index() {
    return new Promise((resolve, reject) => {
      let url = `/email_lists.json`
      let request = new Request(url, { credentials: 'same-origin' })
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => {
              console.error('Failed to retrieve email_lists', request)
              reject(error)
            })
        })
        .catch(error => {
          console.error('Failed to retrieve email_lists', request)
          reject(error)
        })
    })
  }
}
