import { AdAPI } from '../apis/ad_api.js.jsx'
import { AdSegmentAPI } from '../apis/ad_segment_api.js.jsx'
import AdsSectionAPI from '../apis/ads_section_api.js.jsx'
import { SectionHelper } from './section_helper.js.jsx'
import { BatchFunctionCaller } from '../utils.js.jsx'

class AdHelper {
  constructor() {
    this.updateQueue = new BatchFunctionCaller(this.bulkUpdateAds, 3000)
    this.sectionHelper = new SectionHelper()

    this.queueUpdate = this.queueUpdate.bind(this)
    this.index = this.index.bind(this)
    this.cleanAdData = this.cleanAdData.bind(this)
  }

  // adDictionary is an object that looks like { id: { name: 'leesa', elevator_pitch: 'soft mattresses'} }
  bulkUpdateAds(adDictionary) {
    let promiseArray = []
    for (let adId in adDictionary) {
      AdAPI.patch(adId, adDictionary[adId]['cpId'], adDictionary[adId])
    }
    return Promise.all(promiseArray)
  }

  queueUpdate(adId, cpId, key, newValue) {
    let queueObject = this.updateQueue.getQueueItem(adId) || { cpId: cpId }
    if (this.columnAttributes().indexOf(key) >= 0) {
      queueObject[key] = newValue
    } else {
      if (queueObject['ad_data'] != null) {
        queueObject['ad_data'][key] = newValue
      } else {
        queueObject['ad_data'] = { [key]: newValue }
      }
    }
    this.updateQueue.queue(adId, queueObject)
  }

  create(cpId, adData) {
    adData = adData || {}
    return AdAPI.create(cpId, { ad_data: adData }).then(ad => {
      return this.cleanAdData(ad)
    })
  }

  addSection(ad, sectionType, ordering) {
    let params = { section_type: sectionType, ordering: ordering }
    return AdsSectionAPI.create(ad.client_profile_id, ad.id, params).then(adsSection => {
      return this.sectionHelper.show(adsSection.section_id).then(section => {
        return { ad: ad, section: section }
      })
    })
  }

  deleteSection(ad, sectionId) {
    return AdsSectionAPI.destroy(ad.client_profile_id, ad.id, sectionId)
  }

  sections(ad) {
    return new Promise((resolve, reject) => {
      AdsSectionAPI.index(ad.client_profile_id, ad.id)
        .then(adsSections => {
          this.sectionHelper
            .index(adsSections.map(as => as.section_id))
            .then(sections => {
              // Sort the sections by their ads sections ordering
              adsSections.sort((as1, as2) => as1.ordering - as2.ordering)
              let sortedSections = adsSections.map(as => sections.find(s => s.id == as.section_id))
              resolve(sortedSections)
            })
            .catch(error => {
              console.error(`Failed to load sections while loading ads_sections: ${error}`)
            })
        })
        .catch(error => {
          console.error(`Failed to load ads_sections: ${error}`)
        })
    })
  }

  updateAdSectionOrderings(ad, sections) {
    return Promise.all(
      sections.map((section, index) => {
        AdsSectionAPI.update(ad.client_profile_id, ad.id, section.id, { ordering: index })
      }),
    )
  }

  index(cpid) {
    return AdAPI.index(cpid).then(ads => {
      return ads.map(this.cleanAdData)
    })
  }

  getAdReport(adId, cpId) {
    return AdAPI.getAdReport(adId, cpId)
  }

  // Remove any nulls or undefined values from the ad data. Then
  // default any missing values to blank
  cleanAdData = ad => {
    for (let key in ad.ad_data) {
      if (ad.ad_data[key] == null) {
        delete ad.ad_data[key]
      }
    }
    for (let key in ad) {
      if (ad[key] == null) {
        delete ad[key]
      }
    }
    ad.ad_data = Object.assign(this.blank()['ad_data'], ad.ad_data)
    return Object.assign(this.blank(), ad)
  }

  columnAttributes() {
    return [
      'status',
      'gdoc',
      'send_date',
      'segment_id',
      'line_item_id',
      'line_item_synced_at',
      'docusign_envelope_id',
      'docusign_envelope_name',
      'docusign_envelope_email',
    ]
  }

  adDataAttributes() {
    return [
      'custom_url',
      'product_to_promote',
      'product_cost',
      'promo_code',
      'promo_details',
      'tagline',
      'sponsor_name',
    ]
  }

  // Updates the key with the new value in the given client
  // profile. It understands the specific data structure of a client
  // profile
  updateSimpleAd(ad, key, newValue) {
    if (this.columnAttributes().indexOf(key) >= 0) {
      ad[key] = newValue
    } else {
      ad['ad_data'][key] = newValue
    }
    return ad
  }

  // Return a blank client profile
  blank() {
    return {
      status: '',
      gdoc: '',
      send_date: '',
      ad_segments: [],
      ad_data: {
        custom_url: '',
        product_to_promote: '',
        product_cost: '',
        promo_code: '',
        promo_details: '',
        sponsor_name: '',
        tagline: '',
        tagline_link: '',
        header_image_link: '',
      },
    }
  }

  addAdSegment(ad, segmentId) {
    return AdSegmentAPI.create(ad.id, segmentId).then(adSegment => {
      ad.ad_segments.push(adSegment)
      return this.cleanAdData(ad)
    })
  }

  destroy(adId, cpId) {
    return AdAPI.destroy(adId, cpId)
  }

  removeAdSegment(ad, segmentId) {
    return AdSegmentAPI.del(ad.id, segmentId).then(() => {
      ad.ad_segments = ad.ad_segments.filter(adSegment => adSegment.segment_id != segmentId)
      return this.cleanAdData(ad)
    })
  }

  approveAd(ad, recipientEmail, recipientName) {
    return AdAPI.signApprovalContract(ad.client_profile_id, ad.id, recipientEmail, recipientName)
  }

  approvalStatus(ad) {
    if (ad.status === 'approved') {
      return Promise.resolve({ approval_status: 'approved' })
    } else if (ad.docusign_envelope_id == null) {
      return Promise.resolve({ approval_status: 'begin' })
    } else {
      return AdAPI.approvalStatus(ad.client_profile_id, ad.id)
    }
  }

  show(adid, cpid) {
    return AdAPI.show(adid, cpid).then(ad => this.cleanAdData(ad))
  }

  duplicate(ad, cpId) {
    return new Promise((resolve, reject) => {
      let newAdData = {
        status: ad['status'],
        gdoc: ad['gdoc'],
        ad_data: ad['ad_data'],
        send_date: ad['send_date'],
        line_item_synced_at: ad['line_item_synced_at'],
        line_item_id: ad['line_item_id'],
        docusign_envelope_id: ad['docusign_envelope_id'],
        docusign_envelope_name: ad['docusign_envelope_name'],
        docusign_envelope_email: ad['docusign_envelope_email'],
      }
      AdAPI.create(cpId, newAdData)
        .then(resolve)
        .catch(reject)
    })
  }
}

export { AdHelper }
