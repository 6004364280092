import React from 'react'
import { Link } from '@material-ui/core'
import EmailSendJobAPI from '../apis/email_send_job_api.js.jsx'
import EmailEnvelopeAPI from '../apis/email_envelope_api.js.jsx'
import { SectionHelper } from './section_helper.js.jsx'
import { EmailValidator } from '../utils.js.jsx'
import Moment from 'moment'
import _ from 'lodash'

export default class DripCampaignHelper {
  constructor() {
    this.updateQueue = []
    this.updateTimer = null

    this.flushUpdates = this.flushUpdates.bind(this)
    this.sectionHelper = new SectionHelper()
    this.emailSendJobAPI = new EmailSendJobAPI()
    this.emailEnvelopeAPI = new EmailEnvelopeAPI()
  }

  flushUpdates = () => {
    if (this.updateTimer) {
      clearTimeout(this.updateTimer)
      this.updateTimer = null
    }
    let promises = this.updateQueue.map(simpleDripCampaign => {
      return this.emailSendJobAPI.update(simpleDripCampaign).catch(error => {
        console.error(`Failed to update drip campaign with id: ${simpleDripCampaign.id}`)
      })
    })
    this.updateQueue = []
    return Promise.all(promises)
  }

  deleteDripCampaign = dripCampaign => {
    this.emailEnvelopeAPI.destroy({ id: dripCampaign.email_envelope_id })
    this.emailSendJobAPI.destroy({ id: dripCampaign.id })
    return
  }

  update = simpleDripCampaign => {
    if (!this.updateTimer) {
      this.updateTimer = setTimeout(this.flushUpdates, 3000)
    }
    let index = this.updateQueue
      .map(queuedDripCampaign => {
        return queuedDripCampaign.id === simpleDripCampaign.id
      })
      .indexOf(true)
    if (index >= 0) {
      this.updateQueue[index] = Object.assign(this.updateQueue[index], simpleDripCampaign)
    } else {
      this.updateQueue.push(simpleDripCampaign)
    }
  }

  updateName = (emailEnvelopeId, newName) => {
    return new Promise((resolve, reject) => {
      return this.emailEnvelopeAPI
        .update({ id: emailEnvelopeId, name: newName })
        .then(res => {
          let msg
          if (res.name == 'has already been taken') {
            msg = 'Error: Name must be unique.'
            console.error(msg)
            reject(msg)
          }
          msg = ''
          resolve()
        })
        .catch(reject)
    })
  }

  index = params => {
    return this.emailSendJobAPI.index(params)
  }

  blank = id => {
    return {
      id: id,
      name: '',
      description: '',
      csv_url: '',
      active: false,
      email_envelope_id: '',
      crontab_schedule: '',
    }
  }

  setBlankProperties = jsonData => {
    if (jsonData.name == null) {
      jsonData.name = ''
    }
    return jsonData
  }

  show = params => {
    return new Promise((resolve, reject) => {
      return this.emailSendJobAPI
        .show({ id: params.id, include_email_envelope: params.include_email_envelope })
        .then(jsonData => {
          this.setBlankProperties(jsonData)
          resolve(jsonData)
        })
        .catch(error => {
          console.error(`Failed to load drip campaign id: ${params}.`)
          reject(error)
        })
    })
  }

  validateName = (prevName, newName) => {
    return newName && prevName != newName && newName.trim().length > 3
  }

  validNameForIndex = name => {
    if (!_.isEmpty(name) && name.trim().length > 3) {
      return true
    }
    return false
  }

  isNamed = newDripCampaign => {
    let name = _.get(newDripCampaign, ['email_envelope', 'name'])
    if (!_.isEmpty(name) && name.trim().length > 3) {
      return true
    }
    return false
  }

  isConfigured = newDripCampaign => {
    if (
      !_.isEmpty(newDripCampaign.csv_url) &&
      !_.isEmpty(newDripCampaign.crontab_schedule) &&
      this.isNamed(newDripCampaign)
    ) {
      return true
    }
    return false
  }

  isComposed = newDripCampaign => {
    let pageId = _.get(newDripCampaign, ['email_envelope', 'page_id'])
    let pageIdPresent = pageId && !_.isEmpty(pageId.toString())

    if (
      pageIdPresent &&
      !_.isEmpty(newDripCampaign.email_envelope) &&
      !_.isEmpty(newDripCampaign.email_envelope.subject) &&
      !_.isEmpty(newDripCampaign.email_envelope.from_name) &&
      !_.isEmpty(newDripCampaign.email_envelope.reply_name) &&
      !_.isEmpty(newDripCampaign.email_envelope.from_email) &&
      new EmailValidator(newDripCampaign.email_envelope.from_email) &&
      !_.isEmpty(newDripCampaign.email_envelope.reply_to_email) &&
      new EmailValidator(newDripCampaign.email_envelope.reply_to_email) &&
      !_.isEmpty(newDripCampaign.email_envelope.unsubscribe_group.toString())
    ) {
      return true
    }
    return false
  }

  isActionable = (composed, configured) => {
    return composed && configured
  }

  isDeliverable = (composed, configured, active) => {
    return configured && composed && active
  }

  testSend = (dripCampaignId, testSendRecipients) => {
    return new Promise((resolve, reject) => {
      this.emailSendJobAPI
        .testWithEmails({ id: dripCampaignId, emails: testSendRecipients })
        .then(results => {
          resolve(results)
        })
        .catch(error => {
          console.error(`Failed to send test email for Drip Campaign ID: ${dripCampaignId}`, error)
          reject(error)
        })
    })
  }

  renderCsvUrlLink = csvUrl =>
    !_.isEmpty(csvUrl) && (
      <Link className="csv-url-link" key={`link-${csvUrl}`} href={csvUrl} target="_blank" rel="noopener noreferrer">
        {csvUrl}
      </Link>
    )

  createAndNavigateTo = newDripCampaign => {
    return new Promise((resolve, reject) => {
      this.emailEnvelopeAPI
        .create(newDripCampaign)
        .then(emailEnvelope => {
          if (emailEnvelope['name'] !== newDripCampaign['name']) {
            let errorMsg = 'Could not create drip campaign email_envelope. The name used must be unique.'
            console.error(errorMsg)
            reject(new Error(errorMsg))
            return
          } else {
            this.emailSendJobAPI
              .create({ email_envelope_id: emailEnvelope.id })
              .then(dripCampaign => {
                window.location.href = `/email_send_jobs/${dripCampaign.id}/build`
                resolve(true)
              })
              .catch(error => {
                console.error(
                  `Failed to create drip campaign email_send_job with email_envelope_id: ${emailEnvelope.id}`,
                  error,
                )
                reject(error)
                return
              })
          }
        })
        .catch(error => {
          console.error('Could not create drip campaign email_envelope. Something went wrong.')
          reject(error)
          return
        })
    })
  }
}
