class AdAPI {
  static jsonHeaders() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    }
  }

  static passcodeParamPath(path) {
    let passcode = new URL(window.location).searchParams.get('passcode')
    if (passcode != null) {
      let url = new URL(path, 'https://example.com')
      url.searchParams.append('passcode', passcode)
      return url.pathname + url.search
    } else {
      return path
    }
  }

  // This updates the specified ad with new ad data
  static patch(adId, cpId, data) {
    return new Promise((resolve, reject) => {
      let body = { ad: data }
      let options = {
        credentials: 'same-origin',
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = `/clients/${cpId}/ads/${adId}`
      let request = new Request(this.passcodeParamPath(url), options)
      fetch(request).then(response => {
        response
          .json()
          .then(jsonData => resolve(jsonData))
          .catch(error => {
            console.error('Failed to update ad id ' + adId, request)
            reject(error)
          })
      })
    })
  }

  static destroy(adId, cpId) {
    return new Promise((resolve, reject) => {
      let options = {
        credentials: 'same-origin',
        method: 'DELETE',
        headers: this.jsonHeaders(),
      }
      let url = `/clients/${cpId}/ads/${adId}`
      let request = new Request(url, options)
      fetch(request).then(response => {
        response
          .json()
          .then(jsonData => resolve(jsonData))
          .catch(error => {
            console.error('Failed to delete ad id: ' + adId, request)
            reject(error)
          })
      })
    })
  }

  static create(cpId, data) {
    return new Promise((resolve, reject) => {
      let body = { ad: data }
      let options = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = `/clients/${cpId}/ads/`
      let request = new Request(url, options)
      fetch(request).then(response => {
        if (response.status == 201) {
          response.json().then(jsonData => resolve(jsonData))
        } else {
          console.error('Failed to create a new ad', request)
          reject(response)
        }
      })
    })
  }

  // Returns a promise that will yield the ad json with for the
  // specified id and client profile id
  static show(adId, cpId) {
    return new Promise((resolve, reject) => {
      let url = `/clients/${cpId}/ads/${adId}`
      let request = new Request(this.passcodeParamPath(url), { credentials: 'same-origin' })
      fetch(request).then(response => {
        response
          .json()
          .then(jsonData => resolve(jsonData))
          .catch(error => {
            console.error('Failed to find a ad id: ' + adId, request)
            reject(error)
          })
      })
    })
  }

  static getAdReport(adId, cpId) {
    return new Promise((resolve, reject) => {
      let url = `/clients/${cpId}/ads/${adId}/report`
      let request = new Request(this.passcodeParamPath(url), { credentials: 'same-origin' })
      fetch(request).then(response => {
        response
          .json()
          .then(jsonData => resolve(jsonData))
          .catch(error => {
            console.error('Failed to find a ad id: ' + adId, request)
            reject(error)
          })
      })
    })
  }

  // Returns a promise that will yield an array of ads json data
  static index(cpId) {
    return new Promise((resolve, reject) => {
      let url = `/clients/${cpId}/ads/`
      let request = new Request(this.passcodeParamPath(url), { credentials: 'same-origin' })
      fetch(request).then(response => {
        response
          .json()
          .then(jsonData => resolve(jsonData))
          .catch(error => {
            console.error('Failed to retrieve ads', request)
            reject(error)
          })
      })
    })
  }

  static signApprovalContract(cpid, adid, recipientEmail, recipientName) {
    return new Promise((resolve, reject) => {
      let body = {}
      if (recipientEmail != null && recipientName != null) {
        body = { recipient_email: recipientEmail, recipient_name: recipientName }
      }
      let options = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = `/clients/${cpid}/ads/${adid}/sign_approval_contract`
      let request = new Request(this.passcodeParamPath(url), options)
      fetch(request).then(response => {
        response
          .json()
          .then(jsonData => resolve(jsonData))
          .catch(error => {
            console.error(`Failed to start contract signing process: ${error}`, request)
            reject(error)
          })
      })
    })
  }

  static approvalStatus(cpid, adid) {
    return new Promise((resolve, reject) => {
      let options = {
        credentials: 'same-origin',
        method: 'POST',
        headers: this.jsonHeaders(),
      }
      let url = `/clients/${cpid}/ads/${adid}/approval_status`
      let request = new Request(this.passcodeParamPath(url), options)
      fetch(request).then(response => {
        response
          .json()
          .then(jsonData => resolve(jsonData))
          .catch(error => {
            console.error(`Failed to retrieve approval status: ${error}`, request)
            reject(error)
          })
      })
    })
  }
}

export { AdAPI }
