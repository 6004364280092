class SectionTemplateVariableAPI {
  static jsonHeaders() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    }
  }

  static index(params) {
    let paramsStr = Object.keys(params)
      .map(key => key + '=' + encodeURIComponent(params[key]))
      .join('&')
    let url = '/section_template_variables?' + paramsStr
    let request = new Request(url, { credentials: 'same-origin' })
    return fetch(request)
      .then(response => response.json())
      .catch(error => {
        console.error('Unable to fetch section templates: ' + error)
      })
  }

  static create(variableData) {
    return new Promise((resolve, reject) => {
      let body = { section_template_variable: variableData }
      let options = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = '/section_template_variables.json'
      let request = new Request(url, options)
      fetch(request).then(response => {
        if (response.status == 201) {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => reject(error))
        } else {
          reject('Could not create new section template variable: ' + response.statusText)
        }
      })
    })
  }

  static patch(variableId, variableData) {
    return new Promise((resolve, reject) => {
      let body = { section_template_variable: variableData }
      let options = {
        credentials: 'same-origin',
        method: 'PUT',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = '/section_template_variables/' + variableId + '.json'
      let request = new Request(url, options)
      fetch(request).then(response => {
        response
          .json()
          .then(jsonData => resolve(jsonData))
          .catch(error => reject(error))
      })
    })
  }

  static del(variableId) {
    return new Promise((resolve, reject) => {
      let options = {
        credentials: 'same-origin',
        method: 'DELETE',
        headers: this.jsonHeaders(),
      }
      let url = '/section_template_variables/' + variableId + '.json'
      let request = new Request(url, options)
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  }

  static image(imgData) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          let body = {
            section_template_variable: {
              image: {
                src: reader.result,
                url_field: imgData.urlField,
                filename: imgData.srcFile.name,
              },
            },
          }
          let url = '/section_template_variables/image.json'
          let options = {
            credentials: 'same-origin',
            method: 'POST',
            body: JSON.stringify(body),
            headers: this.jsonHeaders(),
          }
          let request = new Request(url, options)
          fetch(request).then(response => {
            response
              .json()
              .then(jsonData => resolve(jsonData))
              .catch(error => reject(error))
          })
        },
        false,
      )

      reader.readAsDataURL(imgData.srcFile)
    })
  }
}

export { SectionTemplateVariableAPI }
