import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReportSectionForm from './report_section_form.js.jsx'
import FontAwesome from 'react-fontawesome'

// This contains the UI that allows users to view the sections
export default class ReportSection extends Component {
  static propTypes = {
    activeSegments: PropTypes.array.isRequired,
    availableEmails: PropTypes.object.isRequired,
    sectionData: PropTypes.object.isRequired,
    urls: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      expanded: false,
    }
  }

  segmentBoxClass(emailId) {
    let activeSegment = this.props.sectionData.emails_sections.some(es => es.email_id == emailId)
    let classNames = {
      'report-toggle-box-disabled': true,
      'segment-toggle-box': true,
      'active-segment': activeSegment,
    }
    let result = []
    for (let key in classNames) {
      if (classNames[key]) {
        result.push(key)
      }
    }
    return result.join(' ')
  }

  sectionToggleBoxes() {
    let availableEmails = this.props.availableEmails
    let toggleBoxes = []
    for (let emailId in availableEmails) {
      toggleBoxes.push(
        <div className={this.segmentBoxClass(emailId)} key={emailId}>
          {availableEmails[emailId]}
        </div>,
      )
    }
    // Always show 3A before 3B, etc
    toggleBoxes.sort((box1, box2) =>
      box1.props.children > box2.props.children ? 1 : box1.props.children == box2.props.children ? 0 : -1,
    )
    return <div className="segment-toggle-boxes report-toggle-box-disabled">{toggleBoxes}</div>
  }

  urlClickSums() {
    const { urls } = this.props
    let loadingIcon = <FontAwesome name="spinner" spin style={{ color: 'gray' }} />
    let cumulativeClicks = 0

    if (urls == null) {
      return null
    }
    for (const [key, value] of Object.entries(urls)) {
      if (key && value && value.click_count) {
        cumulativeClicks += value.click_count
      }
    }
    return cumulativeClicks
  }

  renderHeaderContentByType() {
    const { sectionData } = this.props
    let result = ''
    switch (sectionData.section_type) {
      case 'Preview':
        result = sectionData.content['Preview Text']
        break
      case 'Spunky Masthead':
        result = sectionData.content['Alt Text']
        break
      case 'Masthead':
        result = sectionData.content['Alt Text']
        break
      case 'Hero Image':
        result = sectionData.content['Alt Text']
        break
      case 'brief':
      case 'share_me_now_brief':
        result = sectionData.content.wpPermalink
        break
      case 'Native Advertorial':
        result = sectionData.content['CTA Text']
        break
      case 'things_you_should_header':
        result = 'Things You Should Header'
        break
      case 'single_thing_you_should':
        result = sectionData.content.title
        break
      case 'Padding':
        result = 'Padding'
        break
      case 'Song of the Day':
        result = sectionData.content['Featured Song']
        break
      case 'extra_html':
        result = 'Extra HTML'
        break
      case 'monday_morning_review':
        result = sectionData.content.story
        break
      case 'Footer':
        result = `${sectionData.content['Fake Name']} "${sectionData.content['Middle Name']}" ${
          sectionData.content['Fake Title']
        }`
        break
      default:
        result = ''
        break
    }
    return result
  }

  render() {
    const { expanded } = this.state
    const { activeSegments, sectionData, urls } = this.props
    let expandButton = this.state.expanded ? (
      <FontAwesome
        onClick={() => {
          this.setState({ expanded: !this.state.expanded })
        }}
        name="angle-down"
        size="2x"
        style={{ color: 'black' }}
      />
    ) : (
      <FontAwesome
        onClick={() => {
          this.setState({ expanded: !this.state.expanded })
        }}
        name="angle-right"
        size="2x"
        style={{ color: 'black' }}
      />
    )
    let sectionHeader = (
      <span
        className="sectionhead-header truncate"
        onClick={() => {
          this.setState({ expanded: !this.state.expanded })
        }}>
        {this.props.sectionData.section_type.toUpperCase()}
        <span className="sectionhead-header-preview truncate">
          {' '}
          &nbsp; &nbsp; {this.renderHeaderContentByType()} &nbsp; &nbsp; ({this.urlClickSums()})
        </span>
      </span>
    )
    return (
      <div className="report-section">
        <div className="sectionhead">
          {sectionHeader}
          {this.sectionToggleBoxes()}
          <span className="expand-button">
            <div className="buttsbox">
              <button className="butts">{expandButton}</button>
            </div>
          </span>
        </div>
        {expanded ? <ReportSectionForm urls={urls} /> : ''}
      </div>
    )
  }
}
