class SubjectAPI {
  static jsonHeaders() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    }
  }

  // Returns a promise that will yield an array of subject json data
  static index(params) {
    var paramsStr = Object.keys(params)
      .map(function(key) {
        return key + '=' + encodeURIComponent(params[key])
      })
      .join('&')

    var url = '/subjects.json?' + paramsStr
    var request = new Request(url, { credentials: 'same-origin' })
    return fetch(request)
      .then(response => {
        return response.json()
      })
      .catch(error => {
        console.log('Error while retrieving subjects for campaign: ' + params + '. ' + error)
        return error
      })
  }

  static patch(id, updates) {
    let body = { subject: updates }
    let options = {
      credentials: 'same-origin',
      method: 'PUT',
      body: JSON.stringify(body),
      headers: this.jsonHeaders(),
    }
    let url = '/subjects/' + id + '.json'
    let request = new Request(url, options)
    return fetch(request)
      .then(response => {
        return response.json()
      })
      .catch(error => {
        console.log('Error while updating subject with id: ' + id + '. ' + error)
      })
  }

  // This deletes the specified section using the API
  // Returns a promise that will yield nothing, but will expose an error
  // delete is a JS keyword
  static delete(id) {
    var options = {
      credentials: 'same-origin',
      method: 'DELETE',
    }
    var url = '/subjects/' + id + '.json'
    var request = new Request(url, options)
    return fetch(request).catch(error => {
      console.log('Error while deleting subject with id: ' + id + '. ' + error)
    })
  }

  static create(params) {
    var body = { subject: params }
    var options = {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(body),
      headers: this.jsonHeaders(),
    }
    var url = '/subjects.json'
    var request = new Request(url, options)
    return fetch(request)
      .then(response => {
        return response.json()
      })
      .catch(error => {
        console.log('Error while creating subject: ' + params + '. ' + error)
      })
  }
}

export { SubjectAPI }
