import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import _ from 'lodash'

const SectionFormCheckbox = props => {
  let varName = props.templateVariable.name
  let varValue = props.section.content[varName]
  if (varValue == null) {
    varValue = false
  }
  return (
    <FormControlLabel
      control={
        <Checkbox
          disabled={!props.editable}
          checked={varValue}
          onChange={event =>
            props.handleSectionContent(props.section, props.templateVariable, event.target.checked || false)
          }
          classes={{
            root: props.classes.root,
            checked: props.classes.checked,
          }}
        />
      }
      label={_.capitalize(varName)}
    />
  )
}

export default SectionFormCheckbox

SectionFormCheckbox.propTypes = {
  classes: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  handleSectionContent: PropTypes.func.isRequired,
  section: PropTypes.object.isRequired,
  templateVariable: PropTypes.object.isRequired,
}
