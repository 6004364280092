class SnippetAPI {
  static jsonHeaders() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    }
  }

  // This updates the specified snippet with new snippet data
  // data is an object like { ordering: 1 } or { content: { previewText: 'foobar' } }
  // The server will merge content updates into the DB. You only
  // need to specify content updates, not the entire content hash
  static patch(snippetId, data) {
    return new Promise(
      function(resolve, reject) {
        var body = { snippet: data }
        var options = {
          credentials: 'same-origin',
          method: 'PATCH',
          body: JSON.stringify(body),
          headers: this.jsonHeaders(),
        }
        var url = '/snippets/' + snippetId
        var request = new Request(url, options)
        fetch(request).then(function(response) {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => reject(error))
        })
      }.bind(this),
    )
  }

  static destroy(snippetId) {
    return new Promise(
      function(resolve, reject) {
        var options = {
          credentials: 'same-origin',
          method: 'DELETE',
          headers: this.jsonHeaders(),
        }
        var url = '/snippets/' + snippetId
        var request = new Request(url, options)
        fetch(request).then(function(response) {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => reject(error))
        })
      }.bind(this),
    )
  }

  static create(data) {
    return new Promise(
      function(resolve, reject) {
        var body = { snippet: data }
        var options = {
          credentials: 'same-origin',
          method: 'POST',
          body: JSON.stringify(body),
          headers: this.jsonHeaders(),
        }
        var url = '/snippets'
        var request = new Request(url, options)
        fetch(request).then(function(response) {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => reject(error))
        })
      }.bind(this),
    )
  }

  // Returns a promise that will yield an array of snippet json data
  // with the specified snippetId
  static show(snippetId) {
    return new Promise(function(resolve, reject) {
      var url = '/snippets/' + snippetId
      var request = new Request(url, { credentials: 'same-origin' })
      fetch(request).then(function(response) {
        response
          .json()
          .then(jsonData => resolve(jsonData))
          .catch(error => reject(error))
      })
    })
  }

  // Returns a promise that will yield an array of snippet json data
  static index(params) {
    var paramsStr = Object.keys(params)
      .map(function(key) {
        return key + '=' + encodeURIComponent(params[key])
      })
      .join('&')
    return new Promise(function(resolve, reject) {
      var url = '/snippets?' + paramsStr
      var request = new Request(url, { credentials: 'same-origin' })
      fetch(request).then(function(response) {
        response
          .json()
          .then(jsonData => resolve(jsonData))
          .catch(error => reject(error))
      })
    })
  }
}

export { SnippetAPI }
