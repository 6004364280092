import React from 'react'
import PropTypes from 'prop-types'
import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  Editor,
  EditorState,
  convertFromHTML,
  convertToRaw,
  createEmpty,
} from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'

export default class RichTextEditorSectionContainer extends React.Component {
  static propTypes = {
    editable: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
    // protects against crash from setting contentBlocks to null
    this.state = { editorState: null }
    if (props.value) {
      const blocksFromHTML = convertFromHTML(props.value)
      if (blocksFromHTML.contentBlocks != null) {
        const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
        this.state.editorState = EditorState.createWithContent(state)
      }
    }
    if (this.state.editorState == null) {
      this.state.editorState = EditorState.createEmpty()
    }
    this.focus = () => this.refs.editor.focus()
    this.logState = () => {
      const content = this.state.editorState.getCurrentContent()
      let raw = convertToRaw(content)
      console.log(convertToRaw(content))
      let fn = stateToHTML
    }
  }

  onChange = editorState => {
    if (this.props.editable) {
      if (stateToHTML(this.state.editorState.getCurrentContent()) !== stateToHTML(editorState.getCurrentContent())) {
        this.setState({ editorState })
        this.props.onChange(this.props.name, stateToHTML(editorState.getCurrentContent()))
      } else {
        this.setState({ editorState })
      }
    }
  }

  render() {
    return (
      <div style={this.styles()}>
        <Editor
          readOnly={!this.props.editable}
          editorState={this.state.editorState}
          onChange={!this.props.editable ? () => {} : this.onChange}
          ref="editor"
        />
      </div>
    )
  }

  styles() {
    return {
      border: '1px solid #ddd',
      padding: '6px',
      margin: '6px 0 7px',
    }
  }
}
