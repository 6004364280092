class ClientProfileAPI {
  static jsonHeaders() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    }
  }

  static passcodeParamPath(path) {
    let passcode = new URL(window.location).searchParams.get('passcode')
    if (passcode != null) {
      let url = new URL(path, 'https://example.com')
      url.searchParams.append('passcode', passcode)
      return url.pathname + url.search
    } else {
      return path
    }
  }

  // This updates the specified client_profile with new client_profile data
  // data is an object like { ordering: 1 } or { content: { previewText: 'foobar' } }
  // The server will merge content updates into the DB. You only
  // need to specify content updates, not the entire content hash
  static patch(clientProfileId, data) {
    return new Promise((resolve, reject) => {
      var body = { client_profile: data }
      var options = {
        credentials: 'same-origin',
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      var url = '/clients/' + clientProfileId
      var request = new Request(this.passcodeParamPath(url), options)
      fetch(request).then(response => {
        response
          .json()
          .then(jsonData => resolve(jsonData))
          .catch(error => {
            console.error('Failed to update client profile id ' + clientProfileId)
            reject(error)
          })
      })
    })
  }

  static destroy(clientProfileId) {
    return new Promise((resolve, reject) => {
      var options = {
        credentials: 'same-origin',
        method: 'DELETE',
        headers: this.jsonHeaders(),
      }
      var url = '/clients/' + clientProfileId
      var request = new Request(url, options)
      fetch(request).then(response => {
        response
          .json()
          .then(jsonData => resolve(jsonData))
          .catch(error => {
            console.error('Failed to delete client profile id: ' + clientProfileId)
            reject(error)
          })
      })
    })
  }

  // Returns a promise that will yield an array of clientProfile json data
  // with the specified clientProfileId
  static show(clientProfileId) {
    return new Promise((resolve, reject) => {
      var url = '/clients/' + clientProfileId
      var request = new Request(this.passcodeParamPath(url), { credentials: 'same-origin' })
      fetch(request).then(response => {
        response
          .json()
          .then(jsonData => resolve(jsonData))
          .catch(error => {
            console.error('Failed to find a client profile id: ' + clientProfileId)
            reject(error)
          })
      })
    })
  }

  // Returns a promise that will yield an array of clientProfile json data
  static index(params) {
    var paramsStr = Object.keys(params)
      .map(key => {
        return key + '=' + encodeURIComponent(params[key])
      })
      .join('&')
    return new Promise((resolve, reject) => {
      var url = '/clients?' + paramsStr
      var request = new Request(url, { credentials: 'same-origin' })
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => {
              console.error('Failed to retrieve client profiles.')
              reject(error)
            })
        })
        .catch(error => {
          console.error('Failed to retrieve client profiles')
          reject(error)
        })
    })
  }

  static sync(cpId) {
    var options = {
      credentials: 'same-origin',
      method: 'POST',
      headers: this.jsonHeaders(),
    }
    var url = '/clients/' + cpId + '/sync'
    var request = new Request(url, options)
    return fetch(request)
      .then(response => {
        return response.json().then(jsonData => jsonData)
      })
      .catch(error => {
        console.error('Failed to reset client passcode with id: ' + cpId + '. ' + error)
        return error
      })
  }

  static resetClientPasscode(cpId) {
    var options = {
      credentials: 'same-origin',
      method: 'POST',
      headers: this.jsonHeaders(),
    }
    var url = '/clients/' + cpId + '/reset_client_passcode'
    var request = new Request(url, options)
    return fetch(request)
      .then(response => {
        return response.json().then(jsonData => jsonData)
      })
      .catch(error => {
        console.error('Failed to reset client passcode with id: ' + cpId + '. ' + error)
        return error
      })
  }
}

export { ClientProfileAPI }
