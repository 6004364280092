import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DropTarget, DropTargetMonitor, DropTargetConnector, DropTargetCollector, ConnectDropTarget } from 'react-dnd'

const ItemTypes = {
  SECTION_TEMPLATE_VARIABLE: 'sectionTemplateVariable',
}

const slotTarget = {
  drop(props) {
    return {
      sectionTemplateVariableSlotIndex: props.variables.indexOf(props.sectionTemplateVariable),
    }
  },
}
const collect = (connect: DropTargetConnector, monitor: DropTargetMonitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: !!monitor.isOver(),
  }
}

@DropTarget(ItemTypes.SECTION_TEMPLATE_VARIABLE, slotTarget, collect)
export default class SectionTemplateVariableSlot extends Component {
  static propTypes = {
    connectDropTarget: PropTypes.func,
    isOver: PropTypes.bool,
    children: PropTypes.object.isRequired,
    findSectionTemplateVariable: PropTypes.func.isRequired,
    sectionTemplateVariable: PropTypes.object.isRequired,
    variables: PropTypes.array.isRequired,
  }

  renderOverlay(color) {
    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 42,
          borderRadius: 3,
          height: '100%',
          width: '97%',
          zIndex: 1,
          opacity: 0.5,
          backgroundColor: color,
        }}
      />
    )
  }

  render() {
    const { children, connectDropTarget, isOver } = this.props
    return (
      connectDropTarget &&
      connectDropTarget(
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          {children}
          {isOver && this.renderOverlay('#bdbdbd')}
        </div>,
      )
    )
  }
}
