import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Paper } from '@material-ui/core'
import MUIDataTable from 'mui-datatables'
import _ from 'lodash'

export default class MaterialTable extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    columnsHeaders: PropTypes.array.isRequired,
    handleRowClick: PropTypes.func,
    loading: PropTypes.bool,
    options: PropTypes.object,
    pathnameTuple: PropTypes.array,
    rowsData: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      page: this.props.initialPage || 0,
      path: '',
      rowsPerPage: this.props.initialRowsPerPage || 25,
    }
  }

  page = () => {
    return this.getURLSearchParamByName('page') || this.props.page || this.state.page
  }

  rowsPerPage = () => {
    return this.getURLSearchParamByName('rowsPerPage') || this.props.rowsPerPage || this.state.rowsPerPage
  }

  getURLSearchParamByName = name => {
    let searchParams = new URLSearchParams(document.location.search)
    let value = searchParams.get(name)
    switch (name) {
      case 'page':
        return Number(value) || 0
        break
      case 'rowsPerPage':
        return Number(value) || 25
        break
      case 'search':
        return value || ''
        break
      default:
        break
    }
    return null
  }

  onCellClick = (colData, cellMeta) => {
    const { handleRowClick, pathnameTuple, rowsData } = this.props
    if (colData == null || colData.type == null || !colData.type === 'a') {
      handleRowClick(rowsData[cellMeta.dataIndex].id, event.metaKey, pathnameTuple)
    }
  }

  onChangeUpdateParams = (name, value) => {
    if (history.pushState) {
      let sp = new URLSearchParams(document.location.search)
      let singularParams = ['sortColumn']
      let params
      if (_.isEmpty(value && value.toString()) && !_.isEmpty(sp.get(name))) {
        sp.delete(name)
      } else if (!_.isEmpty(value && value.toString())) {
        // remove any params that should only have one presence in the search params
        //   before adding it as the new param
        if (singularParams.some(k => name.includes(k))) {
          for (const [key, value] of sp.entries()) {
            singularParams.map(el => {
              if (key.includes(el)) {
                sp.delete(key)
              }
            })
          }
        }
        sp.set(name, value)
      }

      if ((name === 'reset' && value === 'all') || _.isEmpty(sp.toString())) {
        params = ''
      } else {
        params = `?${sp.toString()}`
      }
      let newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${params}`
      window.history.pushState({ path: newUrl.toString() }, '', newUrl.toString())
    }
  }

  onFilterChange = (changedColumn, filterList, type) => {
    const { columnsHeaders } = this.props
    if (type === 'reset') {
      this.onChangeUpdateParams('reset', 'all')
      return
    }
    let columnIndex = columnsHeaders.findIndex(ob => ob.name === changedColumn)
    this.onChangeUpdateParams(changedColumn, filterList[columnIndex])
  }

  onColumnSortChange = (changedColumn, direction) => {
    let directionAbbrv = false
    if (direction === 'ascending') {
      directionAbbrv = 'asc'
    } else if (direction === 'descending') {
      directionAbbrv = 'desc'
    }
    this.onChangeUpdateParams(`sortColumn,${changedColumn}`, `${changedColumn},${directionAbbrv}`)
  }

  onColumnViewChange = (changedColumn, action) => {
    if (action === 'remove') {
      this.onChangeUpdateParams(`displayColumn,${changedColumn}`, `${changedColumn},${action}`)
    } else {
      this.onChangeUpdateParams(`displayColumn,${changedColumn}`, ``)
    }
  }

  render() {
    const { classes, columnsHeaders, handleRowClick, loading, options, rowsData, title } = this.props

    let sharedOptions = {
      filter: true,
      filterType: 'dropdown',
      onCellClick: this.onCellClick,
      onChangeRowsPerPage: numberOfRows => this.onChangeUpdateParams('rowsPerPage', numberOfRows),
      onChangePage: page => this.onChangeUpdateParams('page', page),
      onColumnSortChange: this.onColumnSortChange,
      onColumnViewChange: this.onColumnViewChange,
      onFilterChange: this.onFilterChange,
      onSearchChange: search => this.onChangeUpdateParams('search', search),
      page: this.page(),
      responsive: 'stacked',
      rowsPerPage: this.rowsPerPage(),
      rowsPerPageOptions: [10, 25, 50, 100],
      searchOpen: true,
      searchText: this.getURLSearchParamByName('search'),
      selectableRows: 'none',
    }

    let combinedOptions = _.assignIn({}, sharedOptions, options)

    return (
      <div>
        <Paper>
          <MUIDataTable title={title} data={rowsData} columns={columnsHeaders} options={combinedOptions} />
        </Paper>
      </div>
    )
  }
}
