import React from 'react'
import PropTypes from 'prop-types'
import { SectionTemplateHelper } from '../helpers/section_template_helper.js.jsx'
import MenuItem from '@material-ui/core/MenuItem'

export default class SectionTemplateMenu extends React.Component {
  static propTypes = {
    addSection: PropTypes.func.isRequired,
    divisionNames: PropTypes.array.isRequired,
    handleClose: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.sectionTemplateHelper = new SectionTemplateHelper()
    this.state = { templates: [] }
  }

  componentDidMount() {
    this.sectionTemplateHelper.activeTemplates({ division_names: this.props.divisionNames }).then(templates => {
      this.setState({ templates: templates })
    })
  }

  render() {
    const { templates } = this.state
    const { addSection, handleClose } = this.props
    if (!templates) {
      return null
    }
    // Article Metadata should be an active but unselectable section so we don't have duplicates
    // It's created automatically during new Article creation.
    return templates
      .filter(template => template.version_name !== 'Article Metadata')
      .map(template => {
        return (
          <MenuItem
            key={template.id}
            onClick={template => {
              addSection(template.currentTarget.innerText)
              handleClose()
            }}>
            {template.version_name}
          </MenuItem>
        )
      })
  }
}
