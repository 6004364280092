import { SubjectAPI } from '../apis/subject_api.js.jsx'
import { BatchFunctionCaller } from '../utils.js.jsx'

class SubjectHelper {
  constructor(updateCallback) {
    this.updateQueue = new BatchFunctionCaller(this.bulkUpdateSubjects, 2000, updateCallback)
  }

  bulkUpdateSubjects(subjectDictionary) {
    let promiseArray = []
    for (let subjectId in subjectDictionary) {
      SubjectAPI.patch(subjectId, subjectDictionary[subjectId])
    }
    return Promise.all(promiseArray)
  }

  queueUpdate(subjectId, key, newValue) {
    let queueObject = this.updateQueue.getQueueItem(subjectId) || { id: subjectId }
    queueObject[key] = newValue
    this.updateQueue.queue(subjectId, queueObject)
  }

  index(params) {
    return SubjectAPI.index(params || {})
  }

  create(params) {
    return SubjectAPI.create(params)
  }

  blank() {
    return {
      campaign_id: 0,
      subject: '',
      chosen: false,
    }
  }

  delete(emailId) {
    return SubjectAPI.delete(emailId)
  }
}

export { SubjectHelper }
