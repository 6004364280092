import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  NativeSelect,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import CampaignPreview from './campaign_preview.js.jsx'
import { DateUtils, EmailValidator } from '../utils.js.jsx'
import CampaignTestSendButton from './campaign_builder_review_test_send_button.js.jsx'
import _ from 'lodash'

export default class CampaignBuilderReview extends Component {
  static propTypes = {
    availableEmails: PropTypes.object.isRequired,
    campaign: PropTypes.object.isRequired,
    campaignHelper: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    editable: PropTypes.bool.isRequired,
    emailListHelper: PropTypes.object.isRequired,
    emails: PropTypes.array.isRequired,
    emailEnvelope: PropTypes.object.isRequired,
    markAsReviewed: PropTypes.func.isRequired,
    previewDirty: PropTypes.bool.isRequired,
    previewHTMLType: PropTypes.string.isRequired,
    previewUpdated: PropTypes.func.isRequired,
    propertyPresentInCampaign: PropTypes.func.isRequired,
    reviewDirty: PropTypes.bool,
    segmentHelper: PropTypes.object.isRequired,
    sectionsList: PropTypes.array,
    selectedSidebarSection: PropTypes.number,
    selectedIndex: PropTypes.number,
    selectEmail: PropTypes.func.isRequired,
    selectedEmail: PropTypes.object,
    show: PropTypes.bool.isRequired,
    testSendEmails: PropTypes.object.isRequired,
    updateReviewDirty: PropTypes.func.isRequired,
    updateTestSendEmails: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      estimatedEmailSizes: [],
      newTestEmail: '',
      testSegment: {},
    }
  }

  componentDidMount = () => {
    this.setEstimates()
  }

  setEstimates = () => {
    const { campaign, campaignHelper } = this.props

    campaignHelper
      .getEstimatedEmailSizes(campaign.id)
      .then(estimatedEmailSizes =>
        this.setState({ estimatedEmailSizes: (Array.isArray(estimatedEmailSizes) && estimatedEmailSizes) || [] }),
      )
      .catch(error => console.error(error))
  }

  componentDidUpdate(prevProps) {
    const { selectedIndex } = this.props
    if (prevProps.selectedIndex !== 3 && selectedIndex === 3) {
      this.setEstimates()
    }
  }

  handleUpdateTestSendEmails = event => {
    const { newTestEmail } = this.state
    this.setState({ newTestEmail: event.target.value })
    if (newTestEmail.includes('@') && newTestEmail[newTestEmail.length - 1] == ',') {
      this.checkNewTestEmailForEmails()
    }
  }

  checkNewTestEmailForEmails = () => {
    const { newTestEmail } = this.state
    const { updateTestSendEmails, testSendEmails } = this.props

    let possibleEmails = newTestEmail.trim().split(',')
    let newTestEmails = []
    let newEmailValidator, trimmedNewTestEmail
    possibleEmails.map(possibleEmail => {
      trimmedNewTestEmail = possibleEmail.trim()
      newEmailValidator = new EmailValidator(trimmedNewTestEmail)
      if (newEmailValidator.valid()) {
        newTestEmails.push(trimmedNewTestEmail)
      }
    })
    newTestEmails.map(newTestEmail => {
      if (!Object.keys(testSendEmails).includes(newTestEmail)) {
        updateTestSendEmails(newTestEmail)
      }
    })
  }

  setTestSegment = event => {
    this.setState({ testSegment: this.props.segmentHelper.segmentForId(event.target.value) })
  }

  renderEstimatedEmailSegmentSizes = () => {
    const { campaign, campaignHelper } = this.props
    const { estimatedEmailSizes } = this.state

    return (
      <Fragment>
        <Typography variant="h6">Estimated Email Segment Sizes</Typography>
        <hr />
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" data-field="name">
                <Typography>Email Segment Name</Typography>
              </TableCell>
              <TableCell align="center" data-field="estimated_size">
                <Typography>Estimated File Size</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {estimatedEmailSizes.map(estimate => {
              return (
                <TableRow key={`estimated-email-size-${estimate.name}`}>
                  <TableCell align="center">
                    <Typography>{estimate.name}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>{estimate.estimated_size}</Typography>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Fragment>
    )
  }

  renderSegments = () => {
    const { emails, segmentHelper } = this.props
    const emailSegments = segmentHelper.enabledSegments.filter(segment => {
      return emails.find(email => email.segment_id == segment.id.toString())
    })
    return emailSegments.map(segment => (
      <div className="review-segment" key={`review-segment-${segment.id}`}>
        <Typography key={`review-segment-typography-${segment.id}`}>{`${segment.range_start} - ${
          segment.range_end
        }: ${segment.friendly_name || segment.name}`}</Typography>
      </div>
    ))
  }

  renderSubjects = () => {
    const { subjects } = this.props
    return subjects.map(subject => {
      return subject.subject
    })
  }

  renderTestSendEmails = () => {
    const { campaign, classes, emails, segmentHelper, testSendEmails, updateTestSendEmails } = this.props
    const { newTestEmail, testSegment } = this.state
    let testSegments = emails.map(email => segmentHelper.segmentForId(email.segment_id))

    return (
      <div className="review-test-send" key={`review-testSend-${name[0]}`}>
        {Object.entries(testSendEmails).map(name => {
          return (
            <FormControlLabel
              key={`form-control-${name[0]}`}
              control={
                <Checkbox
                  key={`checkbox-${name[0]}`}
                  checked={testSendEmails[name[0]]}
                  onChange={() => {
                    updateTestSendEmails(name[0])
                  }}
                  value={name[0]}
                  name="checkbox"
                  classes={{
                    root: classes.root,
                    checked: classes.checked,
                  }}
                />
              }
              label={name[0]}
            />
          )
        })}
        <FormControl className={classes.formControl}>
          <TextField
            className={classes.textField}
            placeholder="Add more emails separated by commas"
            label="Test Send Emails"
            margin="normal"
            name="text"
            value={newTestEmail}
            onChange={event => {
              this.handleUpdateTestSendEmails(event, newTestEmail)
            }}
          />
        </FormControl>
        <FormControl id="select-test-segment">
          <InputLabel htmlFor={`select-dropdown-test-segment`}>Test Segment</InputLabel>
          <NativeSelect
            className="test-segment"
            name="testSegment"
            onChange={this.setTestSegment}
            value={testSegment.friendly_name || testSegment.name}
            input={
              <Input
                name="testSegment"
                value={testSegment.friendly_name || testSegment.name}
                id={`select-dropdown-test-segment`}
              />
            }>
            <option value={testSegment.friendly_name || testSegment.name} key={`option-value-${testSegment.id}`}>
              {testSegment.friendly_name || testSegment.name}
            </option>
            {testSegments.map(segment => (
              <option value={segment.id} key={segment.id}>
                {segment.friendly_name || segment.name}
              </option>
            ))}
          </NativeSelect>
        </FormControl>
        <FormControl id="send-test-segments">
          <CampaignTestSendButton
            campaignId={campaign.id}
            disabled={!testSegment.id}
            displayText="Send Test Segment"
            emailIds={testSegment.id ? [emails.find(email => email.segment_id == testSegment.id).id] : null}
            testSegment={testSegment}
            testSendEmails={testSendEmails}
          />
          <CampaignTestSendButton
            campaignId={campaign.id}
            disabled={false}
            displayText="Send All Test Segments"
            emailIds={emails.map(email => email.id)}
            testSegment={testSegment}
            testSendEmails={testSendEmails}
          />
        </FormControl>
      </div>
    )
  }

  render() {
    const {
      availableEmails,
      campaign,
      classes,
      editable,
      emailListHelper,
      emails,
      emailEnvelope,
      markAsReviewed,
      previewDirty,
      previewUpdated,
      propertyPresentInCampaign,
      segmentHelper,
      selectedEmail,
      selectedIndex,
      selectEmail,
      show,
      subjects,
      updateReviewDirty,
    } = this.props
    if ([show, selectedEmail].some(el => el == null) || selectedIndex != 4) {
      return null
    }

    return (
      <div id="campaign-builder-review">
        <div id="review-editor">
          <div id="review-header">
            <Typography>
              Name:&nbsp;
              <span style={{ fontWeight: 400 }}>{campaign.name}</span>
            </Typography>
            <Typography>
              Email Subject Line:&nbsp;
              <span style={{ fontWeight: 400 }}>{this.renderSubjects()}</span>
            </Typography>
            <Typography>
              Email List:&nbsp;
              <span style={{ fontWeight: 400 }}>{emailListHelper.nameForId(campaign.email_list_id)}</span>
            </Typography>
            <Typography>
              Date:&nbsp;
              <span style={{ fontWeight: 400 }}>{DateUtils.momentDayOfWeekMonthNumberYear(campaign.date)}</span>
            </Typography>
          </div>
          <hr />
          {this.renderEstimatedEmailSegmentSizes()}
        </div>

        <CampaignPreview
          availableEmails={availableEmails}
          classes={classes}
          editable={editable}
          emails={emails}
          previewDirty={previewDirty}
          previewHTMLType="email"
          previewUpdated={previewUpdated}
          selectedEmail={selectedEmail}
          selectEmail={selectEmail}
          updateReviewDirty={updateReviewDirty}
        />
      </div>
    )
  }
}
