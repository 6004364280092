import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  DragSource,
  ConnectDragSource,
  ConnectDragPreview,
  DragSourceConnector,
  DragSourceMonitor,
  DragSourceCollector,
} from 'react-dnd'
import { SectionHelper } from '../helpers/section_helper.js.jsx'
import { CampaignHelper } from '../helpers/campaign_helper.js.jsx'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import { Accordion, AccordionDetails, AccordionSummary, TextField, Typography } from '@material-ui/core'
import { StringUtils } from '../utils.js.jsx'

const ItemTypes = {
  SECTION: 'section',
}

const sectionSource = {
  beginDrag(props) {
    return {
      campaignId: props.campaignId,
      originalIndex: props.findSection(props.section.id).index,
      section: props.section,
      sectionsList: props.sectionsList,
      editable: props.editable,
      updateSectionOrderings: props.updateSectionOrderings,
    }
  },

  endDrag(props, monitor: DragSourceMonitor) {
    const item = monitor.getItem()
    const dropResult = monitor.getDropResult()
    const sectionHelper = new SectionHelper()
    const campaignHelper = new CampaignHelper()

    if (!props.editable) {
      console.log('Email is not editable')
      return
    }
    if (dropResult == null) {
      console.log('Drop result not available.')
      return
    }
    if (dropResult) {
      let startTargetIndex = item.originalIndex
      let dropTargetIndex = dropResult.sectionSlotIndex
      let updatedSectionsList
      const rotatingSections = item.sectionsList.slice(
        Math.min(startTargetIndex, dropTargetIndex),
        Math.max(startTargetIndex, dropTargetIndex) + 1,
      )
      const rotateDirection = startTargetIndex < dropTargetIndex ? 'down' : 'up'
      if (rotateDirection == 'up') {
        rotatingSections.unshift(rotatingSections.pop())
        item.sectionsList.splice(dropTargetIndex, rotatingSections.length, ...rotatingSections)
      } else {
        rotatingSections.push(rotatingSections.shift())
        item.sectionsList.splice(startTargetIndex, rotatingSections.length, ...rotatingSections)
      }
      updatedSectionsList = item.sectionsList.slice()
      props.updateSectionOrderings(updatedSectionsList)
    }
  },
}

const collect = (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
  connectDragSource: connect.dragSource(),
  getItem: monitor.getItem(),
  getDropResult: monitor.getDropResult(),
  isDragging: monitor.isDragging(),
})

@DragSource(ItemTypes.SECTION, sectionSource, collect)
export default class Section extends Component {
  static propTypes = {
    availableEmails: PropTypes.object.isRequired,
    campaignId: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    connectDragSource: PropTypes.func,
    editable: PropTypes.bool.isRequired,
    findSection: PropTypes.func.isRequired,
    getDropResult: PropTypes.bool,
    getItem: PropTypes.func,
    isDragging: PropTypes.bool,
    isSelected: PropTypes.bool.isRequired,
    section: PropTypes.object.isRequired,
    sectionsList: PropTypes.array.isRequired,
    updateSectionOrderings: PropTypes.func.isRequired,
  }

  segmentBoxes = () => {
    const { availableEmails, section } = this.props
    let segmentBoxes = []
    for (let emailId in availableEmails) {
      let enabled = section.emails_sections.some(es => es.email_id == emailId)
      let segmentName = availableEmails[emailId]
      segmentBoxes.push({ name: segmentName, enabled: enabled })
    }
    segmentBoxes.sort((a, b) => a.name - b.name)
    return segmentBoxes
  }

  renderSegmentBoxes = () => {
    const { classes, section } = this.props
    let boxes = []
    this.segmentBoxes().map(box => {
      let enabledOrDisabledClass = box['enabled'] ? classes.enabledSegmentBox : classes.disabledSegmentBox
      boxes.push(
        <div key={`seg-box-${section.id}-${box.name}`} className={enabledOrDisabledClass}>
          {box.name}
        </div>,
      )
    })
    return <div className={`${classes.segmentBoxes} segment-boxes-div`}>{boxes}</div>
  }

  render() {
    const { classes, connectDragSource, isDragging, isSelected, section } = this.props

    return (
      connectDragSource &&
      connectDragSource(
        <div className={classes.dragging} style={{ opacity: isDragging ? 0.4 : 1 }}>
          <Accordion
            expanded={false}
            onChange={null}
            style={{ backgroundColor: StringUtils.strToRGBHexCode(section.section_type) }}
            className={isSelected ? `${classes.h3} ${classes.highlightSection}` : classes.h3}>
            <AccordionSummary className="section-name-display">
              <TextField
                key={`text-field-key-${section.id}`}
                label={section.nickname && section.section_type}
                className="text-field-section-name"
                fullWidth
                placeholder={section.section_type}
                margin="none"
                InputProps={{ disableUnderline: true, readOnly: true }}
                name={section.section_type}
                value={section.nickname || section.section_type}
              />
              {this.renderSegmentBoxes()}
              <DragHandleIcon className={`${classes.dragHandle} segment-boxes-div`} />
            </AccordionSummary>
          </Accordion>
        </div>,
      )
    )
  }
}
