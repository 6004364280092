import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import ArticleSection from './article_section.js.jsx'
import SectionSlot from './section_slot.js.jsx'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import SectionTemplateMenu from './section_template_menu.js.jsx'

@DragDropContext(HTML5Backend)
export default class ArticleSectionBoard extends Component {
  static propTypes = {
    addSection: PropTypes.func.isRequired,
    articleId: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    editable: PropTypes.bool.isRequired,
    sectionsList: PropTypes.array.isRequired,
    selectSidebarSection: PropTypes.func.isRequired,
    selectedSidebarSection: PropTypes.number,
    updateSectionOrderings: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      anchorEl: null,
    }
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  findSection = id => {
    const { articlesSections, sectionsList } = this.props
    const articlesSectionIndex = articlesSections.findIndex(s => s.section_id === id)
    const section = sectionsList.find(s => s.id == id)
    return {
      section,
      index: articlesSectionIndex,
    }
  }

  renderSection = section => {
    const {
      articleId,
      classes,
      editable,
      sectionsList,
      selectedSidebarSection,
      sortSections,
      updateSectionOrderings,
    } = this.props
    let isSelected = section.id == selectedSidebarSection
    return (
      <ArticleSection
        key={section.id}
        classes={classes}
        articleId={articleId}
        editable={editable}
        findSection={this.findSection}
        isSelected={isSelected}
        section={section}
        sectionNickname={section.nickname}
        sectionsList={sectionsList}
        updateSectionOrderings={updateSectionOrderings}
      />
    )
  }

  renderSectionSlot = sectionId => {
    const { classes, sectionsList, selectSidebarSection } = this.props
    let section = sectionsList.find(section => section.id == sectionId)
    if (!section) {
      return null
    }
    return (
      <div
        key={`section-slot-${sectionId}`}
        onClick={() => {
          selectSidebarSection(sectionId)
        }}>
        <SectionSlot findSection={this.findSection} section={section} sectionsList={sectionsList}>
          {this.renderSection(section)}
        </SectionSlot>
      </div>
    )
  }

  checkForEmptySectionsListCSSClass = () => {
    const { classes, sectionsList } = this.props
    return sectionsList.length > 0 ? '' : classes.addSectionButtonContain
  }

  renderAddSectionButton = () => {
    const { classes, addSection, editable, sectionsList, setSectionType } = this.props
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)
    return (
      // The empty + Add Section button requires some special SCSS to render correctly in size
      <div className={this.checkForEmptySectionsListCSSClass()}>
        <Button
          disabled={!editable}
          key="add-section"
          children={!editable ? 'Not Editable' : '+ Add Section'}
          variant="outlined"
          className={classes.addSectionButton}
          aria-owns={open ? 'menu-appbar' : null}
          aria-haspopup="true"
          onClick={this.handleMenu}
        />
        <Menu
          className={classes.menu}
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={this.handleClose}>
          <SectionTemplateMenu addSection={addSection} handleClose={this.handleClose} divisionNames={['article']} />
        </Menu>
      </div>
    )
  }

  render() {
    const { classes, articlesSections, sectionsList } = this.props
    return (
      <div className={this.checkForEmptySectionsListCSSClass()}>
        {articlesSections.length > 0 &&
          sectionsList.length > 0 &&
          articlesSections.map(artSec => this.renderSectionSlot(artSec.section_id))}
        {this.renderAddSectionButton()}
      </div>
    )
  }
}
