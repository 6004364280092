import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import BriefHelper from '../brief_helper.js.jsx'

const SectionFormWordPressUploader = props => (
  <FormControl className={props.classes.formControl}>
    <BriefHelper
      campaign={props.campaign}
      disabled={!props.editable}
      classes={props.classes}
      section={props.section}
      sectionHelper={props.sectionHelper}
      handleSectionContent={props.handleSectionContent}
      templateVariable={props.templateVariable}
    />
  </FormControl>
)

export default SectionFormWordPressUploader

SectionFormWordPressUploader.propTypes = {
  campaign: PropTypes.object,
  classes: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  handleSectionContent: PropTypes.func.isRequired,
  section: PropTypes.object.isRequired,
  sectionHelper: PropTypes.object.isRequired,
  templateVariable: PropTypes.object.isRequired,
}
