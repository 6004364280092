import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { SectionHelper } from '../helpers/section_helper.js.jsx'
import ReactIframeResizer from 'react-iframe-resizer-super'
import FontAwesome from 'react-fontawesome'

export default class PageBuilderPreview extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    previewDirty: PropTypes.bool.isRequired,
    previewUpdated: PropTypes.func.isRequired,
    sectionUpdated: PropTypes.object,
    sectionId: PropTypes.number,
  }

  constructor(props) {
    super(props)
    this.state = {
      iframeKey: 0,
      previewHtmlUrl: '',
    }

    this.sectionHelper = new SectionHelper()
    // Silence a warning that occurs on every update of the iframe when switching previewHtmlUrl
    console.warn = warning => (warning == '[iFrameSizer][Host page: lp-iframe]' ? null : warning)
  }

  componentDidMount() {
    const { previewUpdated, sectionId } = this.props
    let previewHtmlUrl = `/sections/${sectionId}/preview_html.html`
    this.setState({ previewHtmlUrl, iframeKey: this.state.iframeKey + 1 }, previewUpdated)
  }

  componentDidUpdate(prevProps) {
    const { previewDirty, previewUpdated, sectionId } = this.props
    if (!(prevProps.previewDirty == false && previewDirty)) {
      return null
    }
    let previewHtmlUrl = `/sections/${sectionId}/preview_html.html`
    this.setState({ previewHtmlUrl, iframeKey: this.state.iframeKey + 1 }, previewUpdated)
  }

  render() {
    const { iframeKey, previewHtmlUrl } = this.state

    return (
      <div id="page-builder-preview">
        <LivePreview key={iframeKey} previewHtmlUrl={previewHtmlUrl} />
      </div>
    )
  }
}

class LivePreview extends Component {
  static propTypes = {
    previewHtmlUrl: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
    this.sectionHelper = new SectionHelper()

    this.state = {
      expanded: true,
    }
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.sectionHelper.updateQueue.flush)
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.sectionHelper.updateQueue.flush)
  }

  renderIframe = () => {
    const { emails, previewHtmlUrl } = this.props
    const iframeResizerOptions = {
      log: false,
      id: 'lp-iframe',
      scrolling: false,
      autoResize: true,
      frameBorder: 0,
      minHeight: 600,
      checkOrigin: false,
      // Might be needed for automated front-end tests.
      // checkOrigin: false,
    }
    return <ReactIframeResizer iframeResizerOptions={iframeResizerOptions} src={previewHtmlUrl} />
  }

  render() {
    const { iframeKey } = this.props
    let loadingIcon = <FontAwesome name="spinner" spin size="5x" style={{ color: 'gray' }} />

    return (
      <div id="contain-lp">
        <div key={iframeKey} id="contain-lp-iframe">
          {this.renderIframe()}
        </div>
      </div>
    )
  }
}
