import { createMuiTheme } from '@material-ui/core/styles'
import green from '@material-ui/core/colors/green'

export const campaignBuilderTheme = createMuiTheme({
  overrides: {
    MuiDivider: {
      root: {
        backgroundColor: '#eee',
        height: '0px',
      },
    },
    MuiList: {
      root: {
        margin: '10px',
        padding: '0px',
        position: 'relative',
        listStyle: 'none',
        fontSize: '18px',
        fontWeight: 500,
        fontFamily: "'Source Code Pro', monospace",
      },
      padding: {
        padding: '0px',
      },
    },
    MuiPaper: {
      root: {
        height: '100%',
      },
    },
    MuiTypography: {
      body2: {
        position: 'relative',
        fontSize: '18px',
        fontWeight: 600,
        fontFamily: "'Lato', sans-serif",
        width: '-webkit-fill-available',
        float: 'left',
        display: 'block',
      },
      subtitle1: {
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: "'Lato', sans-serif",
        position: 'relative',
        left: '7px',
        bottom: '4px',
        display: 'block',
      },
      body1: {
        padding: '5px',
        fontSize: '18px',
        display: 'inline-block',
        fontFamily: "'Lato', sans-serif",
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: 0,
        width: 240,
      },
      expandIcon: {
        right: '7px',
      },
    },
    MuiAccordionDetails: {
      root: {
        position: 'relative',
        display: 'flex',
        right: '7px',
        padding: '0px 24px',
      },
    },
    MuiFormControl: {
      root: {
        fontSize: '14px',
        margin: 'none',
        marginLeft: '0px',
        display: 'flex',
        verticalAlign: 'baseline',
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: '14px',
        margin: '0px 25px 0px 0px',
        position: 'relative',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '14px',
        margin: '0px 25px 0px 0px',
        position: 'relative',
      },
    },
    MuiCheckbox: {
      root: {
        '&$checked': {
          color: '#ff5a41',
          '&$checked': {
            color: '##ff5a41',
          },
        },
      },
    },
    MuiList: {
      padding: '0px',
    },
    MuiListItem: {
      root: {
        backgroundColor: 'none',
        '&$selected': {
          backgroundColor: 'none',
        },
        justifyContent: 'space-between',
      },
    },
    MuiListItemIcon: {
      root: {
        color: green[500],
        marginRight: '3px',
        minWidth: 'auto',
      },
    },
    MuiDialog: {
      paper: {
        height: 'auto',
      },
      paperWidthMd: {
        width: '400px',
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'space-evenly',
        padding: '15px',
      },
    },
  },
})
export const styles = campaignBuilderTheme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  drawerPaper: {
    position: 'fixed',
    width: 275,
    overflowX: 'hidden',
    backgroundColor: '#eee',
    boxShadow: '3px 0 5px -5px #333',
  },
  toolbar: {
    minHeight: '85px',
  },
  h4: {
    backgroundColor: '#eee',
    boxShadow: 'none',
    padding: 15,
    borderRadius: 3,
  },
  h3: {
    margin: '4px',
    position: 'relative',
    right: '17px',
    width: '219px',
    height: '40px',
  },
  heading: {},
  headingOpen: {
    color: '#2b9af3',
  },
  dragHandle: {
    right: '32px',
    position: 'absolute',
    bottom: '20px',
    fontSize: '15px',
    color: '#717171',
  },
  addSectionButton: {
    width: '97.3%',
    marginTop: '5px',
    right: '14px',
    border: '2px solid #ccc',
    fontFamily: '"Source Code Pro", monospace',
    textTransform: 'none',
    color: '#aaa',
    fontSize: '16px',
    paddingLeft: '0px',
    marginBottom: -'20px',
  },
  hubSpotMediaButton: {
    backgroundColor: '#ff7a59',
    color: '#fff',
    fontSize: '12px',
    fontWeight: '600',
    width: '100%',
    justifyContent: 'space-between',
    margin: '0.75rem 0rem',
    padding: '0.75rem 1rem 0.75rem 0.75rem',
  },
  addSectionButtonContain: {
    width: '116%',
    position: 'relative',
  },
  highlightSection: {
    border: '2px solid #00000030',
    position: 'relative',
    left: '-19px',
    margin: '15px 3px',
  },
  enabledSegmentBox: {
    color: '#2b9af3',
    fontFamily: '"Source Code Pro", monospace',
    fontSize: '12px',
    fontWeight: '600',
    padding: '1px',
  },
  disabledSegmentBox: {
    color: '#bbb',
    fontFamily: '"Source Code Pro", monospace',
    fontSize: '12px',
    fontWeight: '600',
    padding: '1px',
  },
  segmentBoxes: {
    width: '110px',
    position: 'absolute',
    right: '-22px',
    display: 'flex',
    top: '10px',
  },
  dragging: {
    fontSize: 16,
    fontWeight: 'bold',
    cursor: 'move',
    width: '100%',
    height: '100%',
    position: 'relative',
    whiteSpace: 'nowrap',
  },
  preview: {},
  sectionName: {
    top: '60px',
    left: '60px',
  },
  segmentToggleBox: {
    fontSize: '23px',
    textTransform: 'uppercase',
    color: '#000000',
    backgroundColor: '#ffffff',
  },
  enabledSegmentToggleBox: {
    color: '#2b9af3',
  },
  formControl: {
    margin: campaignBuilderTheme.spacing(1),
    minWidth: 120,
  },
  formControlRTE: {
    font: 'inherit',
    color: 'currentColor',
    border: '0',
    margin: '0',
    padding: '6px 0 7px',
    display: 'block',
    minWidth: '0',
    flexGrow: '1',
    boxSizing: 'content-box',
    background: 'none',
    verticalAlign: 'middle',
    webkitTapHighlightColor: 'transparent',
  },
  button: {
    margin: campaignBuilderTheme.spacing(1),
  },
  headerTitle: {
    width: '300px',
  },
  padding: {
    padding: '0px',
  },
})
