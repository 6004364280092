import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'
import { styles } from '../styles/drip_campaign_builder_styles.js.jsx'

export default class DripCampaignBuilderSidebar extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    composed: PropTypes.bool.isRequired,
    configured: PropTypes.bool.isRequired,
    deliverable: PropTypes.bool.isRequired,
    editable: PropTypes.bool.isRequired,
    handleListItemClick: PropTypes.func.isRequired,
    selectedIndex: PropTypes.number,
    selectedSidebarSection: PropTypes.number,
  }

  constructor(props) {
    super(props)
  }

  render() {
    const { classes, composed, configured, deliverable, editable, handleListItemClick, selectedIndex } = this.props

    return (
      <div id="drip-campaign-sidebar">
        <Drawer
          elevation={16}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}>
          <div className={classes.toolbar} />
          <List>
            <ListItem
              button
              className={classes.h4}
              selected={selectedIndex === 0}
              onClick={() => handleListItemClick(0)}>
              <Typography className={selectedIndex === 0 ? classes.headingOpen : classes.heading}>Configure</Typography>
              {configured && (
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
              )}
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem
              button
              className={classes.h4}
              selected={selectedIndex === 1}
              onClick={() => handleListItemClick(1)}>
              <Typography className={selectedIndex === 1 ? classes.headingOpen : classes.heading}>Compose</Typography>
              {composed && (
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
              )}
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem
              button
              className={classes.h4}
              selected={selectedIndex === 2}
              onClick={() => handleListItemClick(2)}>
              <Typography className={selectedIndex === 2 ? classes.headingOpen : classes.heading}>
                Test/Activate
              </Typography>
              {deliverable && (
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
              )}
            </ListItem>
          </List>
        </Drawer>
      </div>
    )
  }
}

DripCampaignBuilderSidebar = withStyles(styles)(DripCampaignBuilderSidebar)
