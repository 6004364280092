import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DateUtils, ClassName } from './utils.js.jsx'
import { SectionHelper } from './helpers/section_helper.js.jsx'
import { AdHelper } from './helpers/ad_helper.js.jsx'
import FormHelper from './helpers/form_helper.js.jsx'
import AdReport from './components/ad_report.js.jsx'
import AdApprovalPopup from './components/ad_approval_popup.js.jsx'
import SectionTemplatePicker from './components/section_template_picker.js.jsx'
import Moment from 'moment'
import ToggleBoxes from './components/toggle_boxes.js.jsx'
import Paper from '@material-ui/core/Paper'
import _ from 'lodash'

class AdCard extends Component {
  static propTypes = {
    ad: PropTypes.object.isRequired,
    adminMode: PropTypes.bool.isRequired,
    cp: PropTypes.object.isRequired,
    editable: PropTypes.bool.isRequired,
    reloadAd: PropTypes.func.isRequired,
    segmentHelper: PropTypes.object.isRequired,
    updateAd: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.adHelper = new AdHelper()
    this.sectionHelper = new SectionHelper()
    let expanded = true
    let adPast = DateUtils.hasPastDate(this.props.ad.send_date)
    if (adPast) {
      expanded = false
    }
    this.state = {
      adPast: adPast,
      duplicating: false,
      expand: expanded,
      sections: [],
      templateVariables: {},
      newSectionType: 'Native Advertorial',
      previewAd: false,
      report: { ready: false },
      displayPopup: false,
    }

    this.EDITGRAY = '#D4D4D4'
    this.WHITE = '#FFFFFF'
  }

  componentDidMount = () => {
    this.loadSections()
    if (this.state.adPast) {
      this.adHelper.getAdReport(this.props.ad.id, this.props.cp.id).then(report => {
        if (report.ready) {
          this.setState({ report: report })
        }
      })
    }
    window.addEventListener('beforeunload', this.sectionHelper.updateQueue.flush)
  }

  componentWillUnmount = () => {
    window.removeEventListener('beforeunload', this.sectionHelper.updateQueue.flush)
  }

  loadSections = () => {
    this.adHelper.sections(this.props.ad).then(sections => {
      this.setState({ sections: sections }, () => {
        if (this.props.adminMode) {
          this.loadTemplateVariables(sections.map(s => s.id))
        } else {
          this.toggleAdPreview()
        }
      })
    })
  }

  loadTemplateVariables = sectionIds => {
    this.sectionHelper.templateVariablesForSections(sectionIds).then(newTemplateVariables => {
      this.setState(prevState => {
        for (let sectionId in newTemplateVariables) {
          prevState.templateVariables[sectionId] = newTemplateVariables[sectionId]
        }
        return { templateVariables: prevState.templateVariables }
      })
    })
  }

  editable = () => this.props.editable && !this.state.adPast

  updateAdInfo = event => {
    if (this.editable()) {
      let key = event.target.name
      let value = event.target.value
      this.props.updateAd(this.props.ad.id, key, value)
    }
  }

  renderCustomUrlSection = () => {
    if (this.editable()) {
      return (
        <input
          className="tinput"
          type="text"
          name="custom_url"
          placeholder="UTM recommended"
          value={this.props.ad.ad_data.custom_url}
          onChange={this.updateAdInfo}
        />
      )
    } else {
      let customUrl
      if (this.props.ad.ad_data.custom_url == '') {
        customUrl = ''
      } else {
        customUrl = this.props.ad.ad_data.custom_url
      }
      return <p className="not-editable">{customUrl}</p>
    }
  }

  renderAdProductToPromoteSection = () => {
    if (this.editable()) {
      return (
        <input
          className="tinput"
          type="text"
          name="product_to_promote"
          value={this.props.ad.ad_data.product_to_promote}
          placeholder="Exactly as you want it to appear in the ads"
          onChange={this.updateAdInfo}
        />
      )
    } else {
      let text
      if (this.props.ad.ad_data.product_to_promote == '') {
        text = 'n/a'
      } else {
        text = this.props.ad.ad_data.product_to_promote
      }

      return <p className="not-editable">{text}</p>
    }
  }

  renderAdProductCostSection = () => {
    if (this.editable()) {
      return (
        <input
          className="tinput"
          type="text"
          name="product_cost"
          value={this.props.ad.ad_data.product_cost}
          placeholder="What's the cost of the product/service?"
          onChange={this.updateAdInfo}
        />
      )
    } else {
      let text
      if (this.props.ad.ad_data.product_cost == '') {
        text = 'n/a'
      } else {
        text = this.props.ad.ad_data.product_cost
      }

      return <p className="not-editable">{text}</p>
    }
  }

  renderAdPromoCodeSection = () => {
    if (this.editable()) {
      return (
        <input
          className="tinput"
          type="text"
          name="promo_code"
          value={this.props.ad.ad_data.promo_code}
          placeholder="Discount for our readers"
          onChange={this.updateAdInfo}
        />
      )
    } else {
      let text
      if (this.props.ad.ad_data.promo_code == '') {
        text = 'n/a'
      } else {
        text = this.props.ad.ad_data.promo_code
      }

      return <p className="not-editable">{text}</p>
    }
  }

  renderAdPromoDetailsSection = () => {
    if (this.editable()) {
      return (
        <input
          className="tinput"
          type="text"
          name="promo_details"
          value={this.props.ad.ad_data.promo_details}
          placeholder="Who is this for? What amount?"
          onChange={this.updateAdInfo}
        />
      )
    } else {
      let text
      if (this.props.ad.ad_data.promo_details == '') {
        text = 'n/a'
      } else {
        text = this.props.ad.ad_data.promo_details
      }

      return <p className="not-editable">{text}</p>
    }
  }

  renderSendToWritersButton = () => {
    if (this.editable() && this.props.ad.status == 'client_info' && !this.props.adminMode) {
      return (
        <div className="ad-section-buttons ad-section-button-space">
          <div className="ad-section-button" onClick={this.clientInfoCompleted}>
            Send To Writers
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  expandedInfo = () => {
    if (!this.state.expand) {
      return null
    }
    return (
      <div className="info">
        <div className="ad-row">
          <div>
            <label className="cp-main-label inline right-margin">Landing Page URL</label>
            <label className="inline">(required)*</label>
          </div>
          {this.renderCustomUrlSection()}
        </div>
        <div className="ad-row">
          <div className="promote">
            <label className="cp-main-label">
              Specific product to promote
              <label className="inline">(optional)</label>
            </label>
            {this.renderAdProductToPromoteSection()}
          </div>

          <div className="cost">
            <label className="cp-main-label">
              Product cost <label className="inline">(optional)</label>
            </label>
            {this.renderAdProductCostSection()}
          </div>
        </div>

        <div className="ad-row">
          <div className="code">
            <label className="cp-main-label">
              Promo code <label className="inline">(optional)</label>
            </label>
            {this.renderAdPromoCodeSection()}
          </div>

          <div className="details">
            <label className="cp-main-label">
              Promo details <label className="inline">(optional)</label>
            </label>
            {this.renderAdPromoDetailsSection()}
          </div>
          {this.renderSendToWritersButton()}
        </div>
      </div>
    )
  }

  displayPopup = () => {
    this.setState({ displayPopup: true })
  }

  hidePopup = () => {
    this.setState({ displayPopup: false })
  }

  renderAdSectionButtons = () => {
    let buttons = null
    if (!this.props.adminMode || this.state.previewAd) {
      if (!this.props.adminMode && this.props.ad.status != 'approved') {
        buttons = (
          <div className="ad-section-buttons ad-section-button-space">
            <div className="ad-section-button" onClick={this.displayPopup}>
              Approve
            </div>
            <div className="ad-section-button" onClick={this.linkToGdocAndReturnForEdits}>
              Request Edits
            </div>
          </div>
        )
      } else if (!this.props.adminMode) {
        buttons = (
          <div className="ad-section-buttons">
            <div className="ad-section-button" onClick={this.linkToGdoc}>
              Request Edits
            </div>
          </div>
        )
      }
    }
    return buttons
  }

  renderAdSectionsPreview = () => {
    return (
      <div>
        <div>Ad Copy</div>
        <div dangerouslySetInnerHTML={{ __html: this.state.sectionPreviewHTML }} />
        {this.renderAdSectionButtons()}
      </div>
    )
  }

  updateSectionContent = (sectionId, contentUpdates) => {
    this.setState(prevState => {
      // Queue update to backend and destructively update prevState.sections with the content updates
      this.sectionHelper.queueSectionContentUpdate(sectionId, prevState.sections, contentUpdates)
      return { sections: prevState.sections }
    })
  }

  handleSectionContent = (section, templateVariable, value) => {
    this.updateSectionContent(section.id, { [templateVariable.name]: value })
  }

  handleUploadImage = (name, sectionId, templateVariableId) => event => {
    let file = event.target.files[0]
    if (file) {
      this.uploadImage(sectionId, { srcFile: file, urlField: event.target.name })
    }
  }

  uploadImage = (sectionId, imgData) => {
    this.sectionHelper
      .uploadImage(sectionId, imgData)
      .then(sectionJson => {
        this.setState(prevState => {
          let section = prevState.sections.find(s => s.id == sectionId)
          section.content[imgData.urlField] = sectionJson.content[imgData.urlField]
          return { sections: prevState.sections }
        })
      })
      .catch(function(error) {
        console.log('Error uploading image to section. ERROR MESSAGE: ' + error)
      })
  }

  addedFunctions = () => {
    return {
      handleUploadImage: this.handleUploadImage,
      handleSectionContent: this.handleSectionContent,
    }
  }

  renderAdSectionsForms = () => {
    const { sections, adPast, templateVariables } = this.state
    let sectionButtons
    return sections.map(section => {
      if (!adPast) {
        sectionButtons = (
          <div className={`ad-section-control-buttons${this.editable() ? '' : '-disabled'}`}>
            <div className="ad-section-control-button" onClick={this.moveSectionUp(section.id)}>
              <i className="fa fa-arrow-up" aria-hidden="true" />
            </div>
            <div className="ad-section-control-button" onClick={this.moveSectionDown(section.id)}>
              <i className="fa fa-arrow-down" aria-hidden="true" />
            </div>
            <div className="ad-section-control-button" onClick={this.deleteSection(section.id)}>
              <i className="fa fa-window-close" aria-hidden="true" />
            </div>
          </div>
        )
      } else {
        sectionButtons = null
      }
      return (
        <Paper key={section.id} className="ad-card-section">
          <div className="ad-section-name">{section.section_type}</div>
          {sectionButtons}
          {this.renderTemplateVariables(section)}
        </Paper>
      )
    })
  }

  renderTemplateVariables = section => {
    const { adPast, templateVariables } = this.state
    let sectionTemplateVariables = templateVariables[section.id]

    if (sectionTemplateVariables == null || section == null) {
      return
    }

    // sort section template variables to fix a production only bug where article editor sections were
    // appearing with reversed section template variable ordering
    sectionTemplateVariables.sort((a, b) => a.ordering - b.ordering)
    return sectionTemplateVariables.map(templateVariable => {
      let templateVariableFromState = this.state[templateVariable.id]
      return FormHelper.renderVariableByType(
        section,
        templateVariable,
        this.editable(),
        {}, // classes placeholder object
        this.addedFunctions,
        this.sectionHelper,
      )
    })
  }

  renderAdSections = () => {
    let adSectionHtml
    if (!this.props.adminMode || this.state.previewAd) {
      adSectionHtml = this.renderAdSectionsPreview()
    } else {
      adSectionHtml = this.renderAdSectionsForms()
    }

    return <div className="ad-section-wrapper">{adSectionHtml}</div>
  }

  setNewSectionType = event => {
    this.setState({ newSectionType: event.target.value })
  }

  toggleAdPreview = () => {
    const { previewAd, sections } = this.state
    if (previewAd) {
      this.setState({ previewAd: false })
    } else {
      this.setState({ sectionPreviewHTML: '<p>loading...</p>' })
      this.sectionHelper.updateQueue.flush().then(() => {
        this.sectionHelper.getMultiplePreviewHTML(sections.map(s => s.id)).then(htmlString => {
          this.setState({ sectionPreviewHTML: htmlString })
        })
      })
      this.setState({ previewAd: true })
    }
  }

  // Manipulating AdsSections
  // This method updates ads_sections orderings on the backend to
  // match the current order of the sections array in state
  updateAdSectionOrderings = () => {
    this.adHelper.updateAdSectionOrderings(this.props.ad, this.state.sections)
  }

  moveSectionUp = sectionId => () => {
    if (this.editable()) {
      this.setState(prevState => {
        let sectionIndex = prevState.sections.findIndex(s => s.id == sectionId)
        let otherSectionIndex = sectionIndex - 1
        if (otherSectionIndex < 0) {
          return null // User attempted to move the top section up
        }
        let section = prevState.sections[sectionIndex]
        prevState.sections[sectionIndex] = prevState.sections[otherSectionIndex]
        prevState.sections[otherSectionIndex] = section
        return { sections: prevState.sections }
      }, this.updateAdSectionOrderings)
    }
  }

  moveSectionDown = sectionId => () => {
    if (this.editable()) {
      this.setState(prevState => {
        let sectionIndex = prevState.sections.findIndex(s => s.id == sectionId)
        let otherSectionIndex = sectionIndex + 1
        if (otherSectionIndex >= prevState.sections.length) {
          return null // User attempted to move the bottom section down
        }
        let section = prevState.sections[sectionIndex]
        prevState.sections[sectionIndex] = prevState.sections[otherSectionIndex]
        prevState.sections[otherSectionIndex] = section
        return { sections: prevState.sections }
      }, this.updateAdSectionOrderings)
    }
  }

  deleteSection = sectionId => () => {
    if (this.editable()) {
      this.adHelper.deleteSection(this.props.ad, sectionId)
      this.setState(prevState => {
        prevState.sections.splice(prevState.sections.findIndex(s => s.id == sectionId), 1)
        return { sections: prevState.sections }
      }, this.updateAdSectionOrderings)
    }
  }

  addSection = () => {
    const { sections, newSectionType } = this.state
    let ordering = sections.length
    this.adHelper.addSection(this.props.ad, newSectionType, ordering).then(adAndSection => {
      this.setState(prevState => {
        prevState.sections.push(adAndSection.section)
        return { sections: prevState.sections }
      })
      this.loadTemplateVariables([adAndSection.section.id])
    })
  }

  renderSectionPicker = () => {
    const { previewAd, adPast, newSectionType } = this.state
    if (previewAd || adPast) {
      return null
    }
    return (
      <SectionTemplatePicker
        addSection={this.addSection}
        editable={this.editable()}
        setSectionType={this.setNewSectionType}
        newSectionType={newSectionType}
      />
    )
  }

  previewText = () => {
    if (this.state.previewAd) {
      return 'Edit'
    } else {
      return 'Preview'
    }
  }

  sectionDisplay = () => {
    if (!this.state.expand) {
      return null
    }
    if (this.props.adminMode) {
      let markComplete
      if (this.props.ad.status != 'approved' && !this.state.adPast) {
        markComplete = (
          <div
            className={`ad-section-button ${this.isPastInitialDraft() ? 'past-initial-draft' : ''}`}
            onClick={this.draftCompleted}>
            {this.isPastInitialDraft() ? 'Marked Complete' : 'Mark Complete'}
          </div>
        )
      }
      return (
        <div className="ad-section-contain">
          <div className="ad-section-header">Draft</div>
          <div className="ad-section-content">
            {this.renderAdSections()}
            <label className="sublabel">Google doc link:</label>
            <input
              className={ClassName.generate({ tinput: true, highlighted: this.state.gdocHighlight })}
              disabled={!this.editable()}
              name="gdoc"
              onChange={this.updateAdInfo}
              placeholder="MAKE SURE THE SHARE SETTINGS ARE CORRECT"
              type="text"
              value={this.props.ad.gdoc}
            />
          </div>
          {this.renderSectionPicker()}
          <div className="ad-section-buttons">
            <div className="ad-section-button" onClick={this.toggleAdPreview}>
              {this.previewText()}
            </div>
            {markComplete}
          </div>
        </div>
      )
    } else {
      if (this.isPastInitialDraft()) {
        return this.renderAdSections()
      } else {
        return null
      }
    }
  }

  isPastInitialDraft = () => {
    return ['pending_review', 'edit_draft', 'approved'].indexOf(this.props.ad.status) >= 0
  }

  linkToGdocAndReturnForEdits = () => {
    this.props.updateAd(this.props.ad.id, 'status', 'edit_draft')
    window.open(this.props.ad.gdoc)
  }

  linkToGdoc = () => {
    window.open(this.props.ad.gdoc)
  }

  // Checks if the ad has all the required fields
  adValid = () => {
    let fields = [this.props.ad.gdoc]
    return fields.every(f => !_.isEmpty(f))
  }

  // Highlights fields in the ad card if they are empty
  highlightInvalidFields = () => {
    let nothingHighlighted = {
      gdocHighlight: false,
    }
    let fieldsHighlighted = {}
    if (_.isEmpty(this.props.ad.gdoc)) {
      fieldsHighlighted.gdocHighlight = true
    }
    this.setState(fieldsHighlighted)
    setTimeout(() => this.setState(nothingHighlighted), 2000)
  }

  draftCompleted = () => {
    if (this.adValid() && this.editable()) {
      this.props.updateAd(this.props.ad.id, 'status', 'pending_review')
    } else {
      this.highlightInvalidFields()
    }
  }

  clientInfoCompleted = () => {
    this.props.updateAd(this.props.ad.id, 'status', 'write_draft')
  }

  toggleExpand = () => {
    this.setState({ expand: !this.state.expand })
  }

  prettyStatus = () => {
    switch (this.props.ad.status) {
      case 'client_info':
        return 'waiting for info...'
      case 'write_draft':
        return 'sent to writers for drafting...'
      case 'pending_review':
        return 'pending review'
      case 'edit_draft':
        return 'returned for edits'
      case 'approved':
        return 'approved!'
      default:
        return this.props.ad.status
    }
  }

  segmentBoxes = () => {
    let boxes = []
    this.props.ad.ad_segments.map(adSegment => {
      let name = this.props.segmentHelper.nameForId(adSegment.segment_id)
      boxes.push({ name: name, enabled: true })
    })
    return boxes
  }

  renderSegmentToggleBoxes = () => {
    return (
      <div className="segment">
        <ToggleBoxes
          editable={false}
          boxes={this.segmentBoxes()}
          toggleFunc={() => {}}
          menuOptions={[]}
          menuFunc={() => {}}
        />
      </div>
    )
  }

  adReportInfo = () => {
    // temporarily disabled until stats are kosher again
    return null
    if (!this.state.report.ready) {
      return null
    }
    return <AdReport report={this.state.report} />
  }

  adCardContainStyle = () => {
    let style = {}
    if (this.editable()) {
      style.background = this.EDITGRAY
    } else {
      style.background = this.WHITE
    }
    return style
  }

  renderReportLastUpdated = () => {
    if (!this.state.report.ready) {
      return null
    }
    let ranAtDateAndTime = new Moment(this.state.report.ran_at).format('YYYY-MM-DD h:mm a')
    return (
      <div className="report-last-updated">
        <label className="report-last-updated-label">Last updated: {ranAtDateAndTime}</label>
      </div>
    )
  }

  renderSalesforceButton = () => {
    if (this.props.adminMode && this.props.ad.line_item_id) {
      return (
        <div className="salesforce-button">
          <a href={`https://thehustle.my.salesforce.com/${this.props.ad.line_item_id}`}>Salesforce</a>
        </div>
      )
    }
  }

  // Return the ad's ad_type. If it has no line item (therefore no
  // ad_type) return Ad if a client is viewing, otherwise NO LINE ITEM
  renderAdTypeString = () => {
    if (this.props.ad.ad_type) {
      return this.props.ad.ad_type
    }
    if (this.props.adminMode) {
      return 'NO LINE ITEM'
    }
    return 'Ad'
  }

  render = () => {
    const { ad, reloadAd, editable, adminMode } = this.props
    const { displayPopup, expand } = this.state
    return (
      <div className="ad-card-contain" style={this.adCardContainStyle()}>
        <AdApprovalPopup
          ad={ad}
          display={displayPopup}
          cancel={() => this.setState({ displayPopup: false })}
          reloadAd={reloadAd}
        />
        <div className="header">
          <div className="date">
            {adminMode && <AdId adId={ad.id} />}
            <AdDate isoDate={ad.send_date} editable={this.editable()} adminMode={adminMode} />
          </div>
          <div className="details">
            <div className="type">{this.renderAdTypeString()}</div>
            {this.renderSegmentToggleBoxes()}
            <div className="status">{this.prettyStatus()}</div>
            <div className="expand-button">
              <i className={'fa fa-chevron-' + (expand ? 'down' : 'right')} onClick={this.toggleExpand} />
            </div>
          </div>
        </div>
        <div className="body">
          <div className="middle">
            {this.adReportInfo()}
            {this.expandedInfo()}
            {this.sectionDisplay()}
          </div>
          <div className="footer">
            {this.renderSalesforceButton()}
            {this.renderReportLastUpdated()}
          </div>
        </div>
      </div>
    )
  }
}

const AdId = ({ adId }) => (
  <div className="ad-id-container">
    <div className="ad-id-header">Ad ID</div>
    <div className="ad-id">{adId}</div>
  </div>
)

// props must contain isoDate
class AdDate extends Component {
  static propTypes = {
    adminMode: PropTypes.bool.isRequired,
    editable: PropTypes.bool.isRequired,
    isoDate: PropTypes.string.isRequired,
  }

  dateObj = () => {
    let date = new Date(this.props.isoDate)
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000)
  }

  // returns something like "Monday"
  day = () => {
    return DateUtils.getDow(this.dateObj())
  }

  // returns something like "12/3/2018"
  monthYearDate = () => {
    if (isNaN(this.dateObj().getTime())) {
      return 'No Date Selected'
    }
    return `${(this.dateObj().getMonth() + 1).toString()}/${this.dateObj()
      .getDate()
      .toString()}/${this.dateObj()
      .getFullYear()
      .toString()}`
  }

  render() {
    return (
      <div className="date">
        <div className="dow">{this.day()}</div>
        <div className="date-numbers">{this.monthYearDate()}</div>
      </div>
    )
  }
}

export { AdCard }
