import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import NativeSelect from '@material-ui/core/NativeSelect'
import TextField from '@material-ui/core/TextField'
import { DateUtils } from '../utils.js.jsx'
import CampaignBuilderSendToSailthruButton from './campaign_builder_send_to_sailthru_button.js.jsx'
import Moment from 'moment'

export default class CampaignBuilderSendToSailthruDialog extends Component {
  static propTypes = {
    aBTestCampaignHelper: PropTypes.object.isRequired,
    campaign: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    deliverable: PropTypes.bool.isRequired,
    editable: PropTypes.bool.isRequired,
    handleCloseSendToSailthruDialog: PropTypes.func.isRequired,
    handleShowSendToSailthruDialog: PropTypes.func.isRequired,
    refreshEmails: PropTypes.func.isRequired,
    propertyPresentInCampaign: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      scheduledSailThruDate: '2023-01-01',
      scheduledSailThruHour: '08',
      scheduledSailThruMinute: '30',
    }
  }

  updateCampaignSailThruScheduledTime = e => {
    this.setState({
      [e.currentTarget.name]: e.currentTarget.value,
    })
  }

  renderDialogContent = () => {
    const { campaign, classes, handleCloseSendToSailthruDialog, showSendToSailthruDialog } = this.props

    const { scheduledSailThruDate, scheduledSailThruHour, scheduledSailThruMinute } = this.state

    let hourOptions = []

    for (var i = 0; i < 24; i++) {
      hourOptions.push(
        <option key={`hour-option-${i}`} value={('0' + i).slice(-2)}>
          {i}
        </option>,
      )
    }

    return (
      <div>
        <DialogContentText id="scheduled-dialog-description">
          Schedule Campaign and Send it to Sailthru
          <br />
          NOTE: This dialog only affects Sailthru.
          <br />
        </DialogContentText>
        <FormControl className={classes.formControl} id="deliver-date">
          <TextField
            required
            fullWidth
            id="date"
            label="Scheduled Sailthru Date"
            type="date"
            name="scheduledSailThruDate"
            InputLabelProps={{ shrink: true }}
            value={scheduledSailThruDate}
            onChange={this.updateCampaignSailThruScheduledTime}
          />
        </FormControl>
        <FormControl id="select-hour" className={classes.formControl}>
          <InputLabel htmlFor={`select-dropdown-deliver-hour`}>Hour</InputLabel>
          <NativeSelect
            className="select-hour"
            name="scheduledSailThruHour"
            value={scheduledSailThruHour}
            onChange={this.updateCampaignSailThruScheduledTime}
            input={<Input key={`input-deliver-hour`} name="scheduledHour" id={`select-dropdown-deliver-hour`} />}>
            {hourOptions}
          </NativeSelect>
        </FormControl>
        <FormControl id="select-minute" key={`form-control-deliver-minute`} className={classes.formControl}>
          <InputLabel key={`input-label-deliver-minute`} htmlFor={`select-dropdown-deliver-minute`}>
            Minute
          </InputLabel>
          <NativeSelect
            className="select-minute"
            name="scheduledSailThruMinute"
            value={scheduledSailThruMinute}
            onChange={this.updateCampaignSailThruScheduledTime}
            placeholder="Minute"
            input={<Input key={`input-deliver-minute`} name="scheduledMinute" id={`select-dropdown-deliver-minute`} />}>
            <option value="00">00</option>
            <option value="15">15</option>
            <option value="30">30</option>
            <option value="45">45</option>
          </NativeSelect>
        </FormControl>
      </div>
    )
  }

  renderSendButton = () => {
    const {
      aBTestCampaignHelper,
      campaign,
      chooseSchedule,
      deliverable,
      editable,
      handleCloseSendToSailthruDialog,
      refreshEmails,
      propertyPresentInCampaign,
      schedulingCampaign,
      showSendToSailthruDialog,
    } = this.props

    const { scheduledSailThruHour, scheduledSailThruMinute, scheduledSailThruDate } = this.state

    if (!deliverable) {
      return null
    }
    return (
      <CampaignBuilderSendToSailthruButton
        aBTestCampaignHelper={aBTestCampaignHelper}
        scheduling={chooseSchedule}
        campaign={campaign}
        deliverable={deliverable}
        editable={editable}
        handleCloseSendToSailthruDialog={handleCloseSendToSailthruDialog}
        refreshEmails={refreshEmails}
        propertyPresentInCampaign={propertyPresentInCampaign}
        showSendToSailthruDialog={showSendToSailthruDialog}
        scheduledSailThruHour={scheduledSailThruHour}
        scheduledSailThruMinute={scheduledSailThruMinute}
        scheduledSailThruDate={scheduledSailThruDate}
        schedulingCampaign={schedulingCampaign}
      />
    )
  }

  render() {
    const { handleCloseSendToSailthruDialog, showSendToSailthruDialog } = this.props

    return (
      <div>
        <Dialog
          id="deliver-dialog"
          maxWidth="md"
          open={showSendToSailthruDialog}
          onClose={handleCloseSendToSailthruDialog}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Send to Sailthru</DialogTitle>
          <DialogContent>{this.renderDialogContent()}</DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSendToSailthruDialog} color="primary">
              Cancel
            </Button>
            {this.renderSendButton()}
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
