import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputLabel,
  NativeSelect,
  Paper,
  TextField,
} from '@material-ui/core'
import { StringUtils } from '../utils.js.jsx'
import Moment from 'moment'
import _ from 'lodash'

export default class DripCampaignBuilderCompose extends Component {
  static propTypes = {
    dripCampaign: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    editable: PropTypes.bool.isRequired,
    pageHelper: PropTypes.object.isRequired,
    pages: PropTypes.array.isRequired,
    show: PropTypes.bool.isRequired,
    updateDripCampaign: PropTypes.func.isRequired,
    updateEmailEnvelope: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {}

    this.emailUnsubscribeGroups = [{ id: 2425, name: 'MARKETING_UNSUBSCRIBE_GROUP_ID' }]
  }

  getPageSelectionOptions = () => {
    const { pages } = this.props
    return pages.map(page => this.formatPageToString(page))
  }

  formatPageToString = page => {
    return `${page.id} ${page.name} ${StringUtils.truncate(page.description, 30)}`
  }

  formatUnsubscribeGroupToString = unsubGroup => {
    return `${unsubGroup.id} - ${unsubGroup.name}`
  }

  checkAndUpdateEmailEnvelope = event => {
    this.props.updateEmailEnvelope(event)
  }

  render() {
    const {
      dripCampaign,
      classes,
      editable,
      pageHelper,
      pages,
      show,
      updateDripCampaign,
      updateEmailEnvelope,
    } = this.props
    if (!show) {
      return null
    }
    if (!pages || _.isEmpty(pages)) {
      return <div>Please create a Page before trying to build a Drip Campaign</div>
    }

    let publishedPages = pages.filter(page => page.published === true)
    let emailEnvelope = _.get(dripCampaign, ['email_envelope']) || {}
    let currentPage = pages.find(page => page.id === emailEnvelope['page_id']) || pageHelper.blank('')
    let currentUnsubGroup =
      this.emailUnsubscribeGroups.find(unsubGroup => unsubGroup.id === emailEnvelope['unsubscribe_group']) || -1

    return (
      <div id="drip-campaign-compose-container">
        <FormControl className={`${classes.formControl} compose-form-field`}>
          <InputLabel>Page Selection by ID</InputLabel>
          <NativeSelect
            disabled={!this.props.editable}
            value={emailEnvelope['page_id'] || ''}
            onChange={updateEmailEnvelope}
            name="page_id"
            input={<Input key={`input-page-id`} name="page_id" id={`select-dropdown-page-id`} />}>
            <option key={`default-option-listing`} value={pages.find(page => page.id === emailEnvelope['page_id'])}>
              {this.formatPageToString(currentPage)}
            </option>
            {publishedPages.length > 0 &&
              publishedPages
                .filter(page => page.id !== currentPage.id)
                .map(page => (
                  <option key={`option-${page.id}`} value={page.id}>
                    {this.formatPageToString(page)}
                  </option>
                ))}
          </NativeSelect>
        </FormControl>
        <FormControl className={`${classes.formControl} compose-form-field`}>
          <TextField
            disabled={!this.props.editable}
            id="subject"
            label="Subject"
            type="text"
            onChange={updateEmailEnvelope}
            value={emailEnvelope['subject'] || ''}
            className={classes.textField}
            name="subject"
          />
        </FormControl>
        <FormControl className={`${classes.formControl} compose-form-field`}>
          <TextField
            disabled={!this.props.editable}
            id="from-email"
            label="from_email:"
            pattern="/.@thehustle.co$/"
            required
            type="email"
            onChange={this.checkAndUpdateEmailEnvelope}
            value={emailEnvelope['from_email'] || ''}
            className={classes.textField}
            name="from_email"
          />
        </FormControl>
        <FormControl className={`${classes.formControl} compose-form-field`}>
          <TextField
            disabled={!this.props.editable}
            id="from-name"
            label="From: [name]"
            type="text"
            onChange={updateEmailEnvelope}
            value={emailEnvelope['from_name'] || ''}
            className={classes.textField}
            name="from_name"
          />
        </FormControl>
        <FormControl className={`${classes.formControl} compose-form-field`}>
          <TextField
            disabled={!this.props.editable}
            id="reply-name"
            label="Reply: [name]"
            type="text"
            onChange={updateEmailEnvelope}
            value={emailEnvelope['reply_name'] || ''}
            className={classes.textField}
            name="reply_name"
          />
        </FormControl>
        <FormControl className={`${classes.formControl} compose-form-field`}>
          <TextField
            disabled={!this.props.editable}
            id="reply-to-email"
            label="reply_to_email:"
            type="email"
            onChange={updateEmailEnvelope}
            value={emailEnvelope['reply_to_email'] || ''}
            className={classes.textField}
            name="reply_to_email"
          />
        </FormControl>
        <FormControl className={`${classes.formControl} compose-form-field`}>
          <InputLabel>Email Unsubscribe Group</InputLabel>
          <NativeSelect
            id="email-unsub-group"
            disabled={!this.props.editable}
            value={emailEnvelope['unsubscribe_group'] || ''}
            onChange={updateEmailEnvelope}
            name="unsubscribe_group"
            input={
              <Input
                key={`input-unsubscribe-group`}
                name="unsubscribe_group"
                id={`select-dropdown-unsubscribe-group`}
              />
            }>
            <option key={`default-option-listing`} value={currentUnsubGroup.id}>
              {this.formatUnsubscribeGroupToString(currentUnsubGroup)}
            </option>
            {this.emailUnsubscribeGroups
              .filter(unsubGroup => unsubGroup.id != currentUnsubGroup.id)
              .map(unsubGroup => (
                <option key={`option-${unsubGroup.id}`} value={unsubGroup.id}>
                  {this.formatUnsubscribeGroupToString(unsubGroup)}
                </option>
              ))}
          </NativeSelect>
        </FormControl>
        <FormControl className={`${classes.formControl} compose-form-field`}>
          <InputLabel>IP Pool</InputLabel>
          <NativeSelect
            id="ip-pool"
            disabled={!this.props.editable}
            value={emailEnvelope['ip_pool'] || ''}
            onChange={updateEmailEnvelope}
            name="ip_pool"
            input={<Input key={`input-ip-pool`} name="ip-pool" id={`select-dropdown-ip-pool`} />}>
            <option key={`default-option-listing`} value={emailEnvelope.ip_pool}>
              {emailEnvelope.ip_pool}
            </option>
            <option value="daily">daily</option>
            <option value="marketing">marketing</option>
          </NativeSelect>
        </FormControl>
      </div>
    )
  }
}
