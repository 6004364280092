import React from 'react'
import PropTypes from 'prop-types'
import { AdHelper } from '../helpers/ad_helper.js.jsx'
import { EmailValidator } from '../utils.js.jsx'

export default class AdApprovalPopup extends React.Component {
  static propTypes = {
    ad: PropTypes.object.isRequired,
    display: PropTypes.bool.isRequired,
    cancel: PropTypes.func.isRequired,
    reloadAd: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      recipientEmail: '',
      recipientName: '',
      approvalStatus: 'unknown',
      errorOccurred: false,
    }
    this.adHelper = new AdHelper()
  }

  componentDidMount() {
    this.adHelper.approvalStatus(this.props.ad).then(response => {
      this.setState({ approvalStatus: response.approval_status })
      if (response.approval_status === 'approved') {
        this.props.reloadAd(this.props.ad.id, this.props.ad.client_profile_id)
      }
    })
  }

  updateRecipientData(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  validName() {
    return this.state.recipientName.length >= 2
  }

  validEmail() {
    return new EmailValidator(this.state.recipientEmail).valid()
  }

  highlightInvalidFields() {
    if (!this.validName()) {
      this.setState({ nameColor: '#FFBBBB' })
    }
    if (!this.validEmail()) {
      this.setState({ emailColor: '#FFBBBB' })
    }
  }

  approveAd(recipientEmail, recipientName) {
    this.adHelper
      .approveAd(this.props.ad, recipientEmail, recipientName)
      .then(response => {
        if (response.success) {
          window.location.href = response.contract_url
        } else {
          this.setState({ errorOccurred: true })
        }
      })
      .catch(error => {
        console.log(`Something went wrong: ${error}`)
        this.setState({ errorOccurred: true })
      })
  }

  checkAndCreateNewContract() {
    if (this.validName() && this.validEmail()) {
      this.approveAd(this.state.recipientEmail, this.state.recipientName)
    } else {
      this.highlightInvalidFields()
    }
  }

  loadExistingContract() {
    this.approveAd()
  }

  displayCss() {
    return { display: this.props.display ? 'block' : 'none' }
  }

  message() {
    if (this.state.errorOccurred) {
      return "I'm sorry, it looks like something went wrong. Could you verify your name and email are correct and try again?"
    } else {
      switch (this.state.approvalStatus) {
        case 'unknown':
          return 'Loading approval status...'
        case 'approved':
          return "Congratulations, you've already approved this ad!"
        case 'sent':
          return "It looks like you've already started the signature process. Would you like to continue or start over?"
        case 'begin':
          return "Oh, you're almost there! Please enter your name and email, and sign this form on Docusign to approve this ad unit."
        default:
          return "I'm sorry, something is wrong. Please try refreshing the page."
      }
    }
  }

  renderButtons() {
    let cancel = (
      <button className="white-button" onClick={this.props.cancel}>
        Close
      </button>
    )
    let existingContract = (
      <button className="orange-button" onClick={this.loadExistingContract.bind(this)}>
        Approve Existing Contract
      </button>
    )
    let newContract

    switch (this.state.approvalStatus) {
      case 'sent':
        newContract = (
          <button className="orange-button" onClick={this.checkAndCreateNewContract.bind(this)}>
            Approve a New Contract
          </button>
        )
        return (
          <div className="form-box">
            {cancel}
            {newContract}
            {existingContract}
          </div>
        )
      case 'begin':
        newContract = (
          <button className="orange-button" onClick={this.checkAndCreateNewContract.bind(this)}>
            Approve via Docusign
          </button>
        )
        return (
          <div className="form-box">
            {cancel}
            {newContract}
          </div>
        )
      case 'approved':
        return <div className="form-box">{cancel}</div>
      default:
        return null
    }
  }

  renderForm() {
    switch (this.state.approvalStatus) {
      case 'sent':
      case 'begin':
        return (
          <div>
            <div className="form-box">
              <input
                className="tinput"
                type="text"
                style={{ backgroundColor: this.state.nameColor }}
                placeholder="Full Name"
                name="recipientName"
                onChange={this.updateRecipientData.bind(this)}
              />
            </div>
            <div className="form-box">
              <input
                className="tinput"
                type="text"
                style={{ backgroundColor: this.state.emailColor }}
                placeholder="email@example.com"
                name="recipientEmail"
                onChange={this.updateRecipientData.bind(this)}
              />
            </div>
          </div>
        )
      default:
        return null
    }
  }

  render() {
    return (
      <div id="ad-approval-contain" style={this.displayCss()}>
        <div id="ad-approval-popup" style={this.displayCss()}>
          <p className="approve-text">{this.message()}</p>
          {this.renderForm()}
          {this.renderButtons()}
        </div>
      </div>
    )
  }
}
