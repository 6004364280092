import React from 'react'
import PageAPI from '../apis/page_api.js.jsx'
import PagesSectionAPI from '../apis/pages_section_api.js.jsx'
import { SectionHelper } from './section_helper.js.jsx'
import { DateUtils } from '../utils.js.jsx'
import Moment from 'moment'

export default class PageHelper {
  constructor(props) {
    this.updateQueue = []
    this.updateTimer = null

    this.pageAPI = new PageAPI()
    this.pagesSectionAPI = new PagesSectionAPI()
    this.sectionHelper = new SectionHelper()
  }

  flushUpdates = () => {
    if (this.updateTimer) {
      clearTimeout(this.updateTimer)
      this.updateTimer = null
    }
    let promises = this.updateQueue.map(simplePage => {
      return this.pageAPI.update(simplePage).catch(error => {
        console.error(`Failed to update page with id: ${simplePage.id}`)
      })
      this.updateQueue = []
      return Promise.all(promises)
    })
  }

  deletePage = pageId => {
    this.pageAPI.destroy({ id: pageId })
    window.location.href = '/pages'
  }

  find = id => {
    return new Promise((resolve, reject) => {
      return this.pageAPI
        .show({ id: id })
        .then(jsonData => {
          resolve(jsonData)
        })
        .catch(error => {
          console.error(`Failed to load page id: ${id}.`)
          reject(error)
        })
    })
  }

  blank = id => {
    return {
      id: id,
      name: '',
      date: '',
      description: '',
      slug: '',
      published: false,
    }
  }

  update = simplePage => {
    if (!this.updateTimer) {
      this.updateTimer = setTimeout(this.flushUpdates, 3000)
    }
    let index = this.updateQueue
      .map(function(queuedPage) {
        return queuedPage.id === simplePage.id
      })
      .indexOf(true)
    if (index >= 0) {
      this.updateQueue[index] = Object.assign(this.updateQueue[index], simplePage)
    } else {
      this.updateQueue.push(simplePage)
    }
  }

  pagesSections = pageId => {
    return this.pageAPI.pagesSections(pageId)
  }

  updateSlug = (pageId, newSlug) => {
    return this.pageAPI.update({ id: pageId, slug: newSlug })
  }

  index = () => {
    return this.pageAPI.index()
  }

  validateSlug = (prevSlug, newSlug) => {
    return newSlug && prevSlug != newSlug && newSlug.trim().length > 3
  }

  validToCreate = newPage => {
    if (newPage.name && newPage.name.trim().length > 0 && newPage.slug && newPage.slug.trim().length > 3) {
      return true
    } else {
      return false
    }
  }

  getEstimatedPageSize = pageId => {
    return this.pageAPI.getEstimatedPageSize(pageId)
  }

  createAndNavigateTo = newPage => {
    return new Promise((resolve, reject) => {
      this.pageAPI
        .create(newPage)
        .then(page => {
          window.location.href = `/pages/${page.id}/build`
          resolve(true)
        })
        .catch(error => {
          console.error('Could not create page. The slug used must be unique.')
          console.error(error)
          reject(error)
        })
    })
  }

  renderPageLink = pageSlug => {
    let envURLToPage
    switch (location.hostname) {
      case 'hustlecms-staging.herokuapp.com':
        envURLToPage = `https://the-hustle-trends-staging.herokuapp.com/pages/${pageSlug}`
        break
      case 'localhost':
        envURLToPage = `${location.hostname}:3002/pages/${pageSlug}`
        break
      default:
        envURLToPage = `https://trends.co/pages/${pageSlug}`
        break
    }

    return (
      <a className="page-link" key={`link-${pageSlug}`} href={envURLToPage} target="_blank" rel="noopener noreferrer">
        {pageSlug}
      </a>
    )
  }
}
