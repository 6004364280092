import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Select from '@material-ui/core/Select'

export default class CreateSectionTemplateFormDialog extends Component {
  static propTypes = {
    createNewSectionTemplate: PropTypes.func.isRequired,
    dialogTitle: PropTypes.string.isRequired,
    handleUpdateNewVersionName: PropTypes.func.isRequired,
    newVersionName: PropTypes.string.isRequired,
    openFormDialog: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  renderCreateButton = () => {
    const { createNewSectionTemplate, newVersionName } = this.props
    let newVersionNameCheck = newVersionName.trim()
    if (newVersionNameCheck.length < 1) {
      return null
    }
    return (
      <Button
        onClick={event => {
          createNewSectionTemplate(), this.handleClose()
        }}
        color="primary">
        Create
      </Button>
    )
  }

  render() {
    const { open } = this.state
    const {
      dialogTitle,
      handleUpdateNewVersionName,
      openFormDialog,
      newDivision,
      newVersionName,
      handleUpdateNewDivision,
    } = this.props

    return (
      <div>
        <Button style={{ textTransform: 'none' }} onClick={this.handleClickOpen}>
          {openFormDialog}
        </Button>
        <Dialog open={open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              fullWidth
              required
              id="version_name"
              label="Name"
              name="version_name"
              onChange={handleUpdateNewVersionName}
              InputLabelProps={{ shrink: true }}
              value={newVersionName}
            />
            <Select
              key={`select-division-type`}
              value={newDivision}
              onChange={handleUpdateNewDivision}
              name="division"
              label="Division"
              required
              native
              fullWidth>
              {this.props.divisions.map(division => {
                return (
                  <option key={`division-option-${division.id}`} value={division.id}>
                    {division.name}
                  </option>
                )
              })}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            {this.renderCreateButton()}
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
