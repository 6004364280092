import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

export default class SegmentNicknameFormDialog extends Component {
  static propTypes = {
    dialogTitle: PropTypes.string.isRequired,
    handleRenameNickname: PropTypes.func.isRequired,
    openFormDialog: PropTypes.string.isRequired,
    segment: PropTypes.object.isRequired,
    segmentHelper: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      open: false,
      nickname: '',
    }
  }

  setNickname = () => {
    this.setState({ nickname: this.props.segment.friendly_name })
  }

  handleClickOpen = () => {
    this.setState({ open: true }, this.setNickname)
  }

  handleNicknameChange = (event, segmentId) => {
    this.setState({ nickname: event.target.value })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  onKeyPress = (event, segmentId) => {
    if (event.key == 'Enter') {
      this.props.handleRenameNickname(event, this.state.nickname, segmentId)
      this.handleClose()
    }
  }

  render() {
    const { nickname, open } = this.state
    const { dialogText, dialogTitle, handleRenameNickname, openFormDialog, segment } = this.props

    return (
      <div>
        <Button style={{ textTransform: 'none' }} onClick={this.handleClickOpen}>
          {openFormDialog}
        </Button>
        <Dialog open={open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{dialogText}</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Segment Nickname"
              type="name"
              fullWidth
              onChange={this.handleNicknameChange}
              onKeyPress={event => {
                this.onKeyPress(event, segment.id)
              }}
              value={this.state.nickname}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={event => {
                handleRenameNickname(event, nickname, segment.id), this.handleClose()
              }}
              color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
