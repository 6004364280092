import EmailEnvelopeAPI from '../apis/email_envelope_api.js.jsx'
import { EmailValidator } from '../utils.js.jsx'
import _ from 'lodash'

export default class EmailEnvelopeHelper {
  constructor() {
    this.updateQueue = []
    this.updateTimer = null

    this.flushUpdates = this.flushUpdates.bind(this)
    this.emailEnvelopeAPI = new EmailEnvelopeAPI()
  }

  flushUpdates = () => {
    if (this.updateTimer) {
      clearTimeout(this.updateTimer)
      this.updateTimer = null
    }
    let promises = this.updateQueue.map(simpleEmailEnvelope => {
      return this.emailEnvelopeAPI.update(simpleEmailEnvelope).catch(error => {
        console.error(`Failed to update drip campaign with id: ${simpleEmailEnvelope.id}`)
      })
    })
    this.updateQueue = []
    return Promise.all(promises)
  }

  // clean and conform any non-approved domains in the event.target.value before updates are made
  checkAndUpdateEmailDomain = event => {
    let regex = new RegExp(/.@thehustle.co$/)
    if (event.target.name === 'from_email' && !event.target.value.match(regex)) {
      let renamedToTheHustleDotCo
      if (event.target.value.includes('@')) {
        renamedToTheHustleDotCo = `${event.target.value.split('@')[0]}@thehustle.co`
      } else {
        renamedToTheHustleDotCo = `${event.target.value}@thehustle.co`
      }
      event.target.value = renamedToTheHustleDotCo
    }
    return event
  }

  update = simpleEmailEnvelope => {
    if (!this.updateTimer) {
      this.updateTimer = setTimeout(this.flushUpdates, 3000)
    }
    let index = this.updateQueue
      .map(queuedDripCampaign => {
        return queuedDripCampaign.id === simpleEmailEnvelope.id
      })
      .indexOf(true)
    if (index >= 0) {
      this.updateQueue[index] = Object.assign(this.updateQueue[index], simpleEmailEnvelope)
    } else {
      this.updateQueue.push(simpleEmailEnvelope)
    }
  }

  show = params => {
    return new Promise((resolve, reject) => {
      return this.emailEnvelopeAPI
        .show({ id: params.id })
        .then(jsonData => {
          this.setBlankProperties(jsonData)
          resolve(jsonData)
        })
        .catch(error => {
          console.error(`Failed to load email template: ${params}.`)
          reject(error)
        })
    })
  }

  setBlankProperties = jsonData => {
    if (jsonData.template_name == null) {
      jsonData.template_name = ''
    }
    return jsonData
  }

  isComposed = emailEnvelope => {
    if (
      !_.isEmpty(emailEnvelope.from_name) &&
      !_.isEmpty(emailEnvelope.reply_name) &&
      !_.isEmpty(emailEnvelope.from_email) &&
      new EmailValidator(emailEnvelope.from_email).valid() &&
      !_.isEmpty(emailEnvelope.reply_to_email) &&
      new EmailValidator(emailEnvelope.reply_to_email).valid() &&
      !_.isEmpty(emailEnvelope.unsubscribe_group.toString())
    ) {
      return true
    }
    return false
  }
}
