import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SectionHelper } from '../helpers/section_helper.js.jsx'
import { DisablingButton } from './disabling_button.js.jsx'
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import LinkIcon from '@material-ui/icons/Link'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import NativeSelect from '@material-ui/core/NativeSelect'
import TextField from '@material-ui/core/TextField'
import Moment from 'moment'
import _ from 'lodash'

export default class BriefHelper extends Component {
  static propTypes = {
    campaign: PropTypes.object,
    classes: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    section: PropTypes.object.isRequired,
    sectionHelper: PropTypes.object.isRequired,
    templateVariable: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
  }

  renderWordPressSections = brief => {
    const { disabled } = this.props
    if (brief.content && brief.content['wpid_new_2020_Q2_site'] == null && brief.wpid == null) {
      return <FormControl className="form-control-wp-sections-na">Not Yet Posted to Wordpress</FormControl>
    }
    return (
      <FormControl className="form-control-wp-sections">
        <Button
          disabled={disabled}
          color="default"
          variant="contained"
          href={`https://thehustle.co?p=${(brief.content && brief.content['wpid_new_2020_Q2_site']) ||
            brief.content.wpid}`}
          style={{ width: '293px', margin: '30px 0px 0px 0px' }}>
          View WordPress post
        </Button>
        <Button
          disabled={disabled}
          color="default"
          variant="contained"
          style={{ width: '293px', margin: '30px 0px 0px 0px' }}
          href={`https://thehustle.co/wp-admin/post.php?action=edit&post=${(brief.content &&
            brief.content['wpid_new_2020_Q2_site']) ||
            brief.content.wpid}`}>
          Edit WordPress post
        </Button>
      </FormControl>
    )
  }

  wpImageView = brief => {
    let imgUrl = (brief.content && brief.content['wpImageUrl_new_2020_Q2_site']) || brief.content['wpImageUrl']
    if (imgUrl == null || imgUrl == '') {
      return (
        <FormControl className="wp-img-view">
          <label>No image uploaded yet</label>
        </FormControl>
      )
    } else {
      return (
        <FormControl className="wp-img-view">
          <img src={imgUrl} style={{ width: '300px' }} />
        </FormControl>
      )
    }
  }

  uploadWpImage = section => {
    const { handleSectionContent, sectionHelper, templateVariable } = this.props
    return event => {
      let filename = `${section.section_type}_${Moment().toISOString()}`
      if (filename != '') {
        filename = filename + '.jpg'
        sectionHelper.createWpImage(section.id, event.target.files[0], filename).then(section => {
          handleSectionContent(
            section,
            { name: 'wpImageId_new_2020_Q2_site' },
            _.get(section, 'content.wpImageId_new_2020_Q2_site'),
          )
          handleSectionContent(
            section,
            { name: 'wpImageUrl_new_2020_Q2_site' },
            _.get(section, 'content.wpImageUrl_new_2020_Q2_site'),
          )
        })
      }
    }
  }

  render() {
    let { campaign, classes, disabled, handleSectionContent, section, sectionHelper, templateVariable } = this.props
    let invalidToCreate =
      _.isEmpty(section.content['wpPermalink'] && section.content['wpPermalink'].trim()) ||
      _.isEmpty(section.content['wpAuthor'] && section.content['wpAuthor'].trim()) ||
      (_.isEmpty(section.content['story'] && section.content['story'].trim().replace(/(<([^>]+)>)/gi, '')) &&
        _.isEmpty(
          section.content['Top Ad Copy'] && section.content['Top Ad Copy'].trim().replace(/(<([^>]+)>)/gi, ''),
        )) ||
      disabled
    let invalidToGenerateBriefLink =
      _.isEmpty(campaign.name) || _.isEmpty(campaign.date) || _.isEmpty(section.content['Headline'])
    return (
      <div id={`${section.id}-wp-form-control-container`}>
        <FormControl className="form-control-wp-generate-brieflink">
          <GenerateBriefLinkButton
            campaign={campaign}
            brief={section}
            className="wp-generate-brieflink"
            disabled={invalidToGenerateBriefLink}
            handleSectionContent={handleSectionContent}
            section={section}
            sectionHelper={sectionHelper}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            disabled={disabled}
            required
            label="Wordpress Title"
            placeholder="Spacex landed another rocket"
            className={classes.textField}
            fullWidth
            margin="normal"
            name="wpTitle"
            value={(section.content && section.content.wpTitle) || ''}
            onChange={event => handleSectionContent(section, { name: 'wpTitle' }, _.get(event, 'target.value'))}
          />
        </FormControl>
        <FormControl className="wp-permalink-form-control-left">
          <TextField
            disabled={disabled}
            required
            label="Wordpress Permalink"
            placeholder="spacex-landed-another-rocket"
            className={classes.textField}
            margin="normal"
            name="wpPermalink"
            fullWidth
            value={(section.content && section.content.wpPermalink) || ''}
            onChange={event => handleSectionContent(section, { name: 'wpPermalink' }, event.target.value.trim())}
          />
        </FormControl>
        <FormControl className="wp-excerpt-form-control">
          <TextField
            disabled={disabled}
            required
            label="Wordpress Excerpt"
            placeholder="This story is brought to you by gumdrops and rainbows."
            className={classes.textField}
            margin="normal"
            multiline
            fullWidth
            name="wpExcerpt"
            value={(section.content && section.content.wpExcerpt) || ''}
            onChange={event => handleSectionContent(section, { name: 'wpExcerpt' }, _.get(event, 'target.value'))}
          />
        </FormControl>
        <FormControl className="wp-author-form-control-narrow">
          <InputLabel htmlFor={`select-dropdown-${section.id}`}>Author</InputLabel>
          <NativeSelect
            disabled={disabled}
            value={(section.content && section.content.wpAuthor) || '41'}
            onChange={event => handleSectionContent(section, { name: 'wpAuthor' }, _.get(event, 'target.value'))}
            name="wpAuthor"
            input={<Input name="wpAuthor" id={`select-dropdown-${section.id}`} />}>
            <option value="40">Zachary Crockett</option>
            <option value="64">Conor Grant</option>
            <option value="72">Emily Kelley</option>
            <option value="78">Mark Dent</option>
            <option value="75">Brad Wolverton</option>
            <option value="84">Mia Sullivan</option>
            <option value="85">Caroline Dohack</option>
            <option value="86">Amanda Lewellyn</option>
            <option value="89">Steph Smith</option>
          </NativeSelect>
        </FormControl>
        <FormControl className="wp-category-form-control-narrow">
          {
            // the right way to do this would be to map posts older that Q2_2020 to the old site category ids, but
            //   I'm skipping that work in the interest of the deadline to get the new site up. -- SG 4/2/2020
          }
          <InputLabel htmlFor={`select-dropdown-${section.id}`}>Category</InputLabel>
          <NativeSelect
            disabled={disabled}
            value={(section.content && section.content.wpCategory) || '52'}
            onChange={event => handleSectionContent(section, { name: 'wpCategory' }, _.get(event, 'target.value'))}
            name="wpCategory"
            input={<Input name="wpCategory" id={`select-dropdown-${section.id}`} />}>
            <option value="52">Brief</option>
            <option value="10">Blog</option>
            <option value="11">Branded Content</option>
            <option value="56">Daily (Email)</option>
            <option value="7">Episodes</option>
            <option value="31">Hustle Con</option>
            <option value="38">Investments</option>
            <option value="59">Jobs</option>
            <option value="53">News</option>
            <option value="58">Partners</option>
            <option value="28">Review</option>
            <option value="6">Stories</option>
            <option value="57">Sunday</option>
            <option value="60">Trends</option>
            <option value="1">Uncategorized</option>
          </NativeSelect>
        </FormControl>
        <FormControl className="form-control-wp-img">
          <Button className={classes.button} disabled={disabled} color="default" variant="contained">
            <span style={{ position: 'absolute' }}>Upload Image</span>
            <Input onChange={this.uploadWpImage(section)} type="file" style={{ opacity: '0', width: '175px' }} />
            <CloudUploadIcon className={classes.rightIcon} />
          </Button>
        </FormControl>
        <FormControl className="form-control-wp-img-filename">
          <TextField
            className="form-control-wp-img-filename"
            type="text"
            disabled
            name="imageFileUrl"
            margin="normal"
            placeholder="Featured Image"
            value={
              (section.content && section.content['wpImageUrl_new_2020_Q2_site']) || section.content['wpImageUrl'] || ''
            }
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">
                  <LinkIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl className="form-control-wp-view-image">{this.wpImageView(section)}</FormControl>
        <FormControl className="form-control-wp-upsert">
          <UpsertWordPressButton
            brief={section}
            className="wp-upsert-button"
            disabled={invalidToCreate}
            handleSectionContent={handleSectionContent}
            section={section}
            sectionHelper={sectionHelper}
            templateVariable={templateVariable}
          />
        </FormControl>
        {this.renderWordPressSections(section)}
      </div>
    )
  }
}

class UpsertWordPressButton extends Component {
  static propTypes = {
    brief: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    handleSectionContent: PropTypes.func.isRequired,
    section: PropTypes.object.isRequired,
    sectionHelper: PropTypes.object.isRequired,
    templateVariable: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
  }

  upsertWordPress = () => {
    const { brief, handleSectionContent, section, sectionHelper, templateVariable } = this.props
    return new Promise((resolve, reject) => {
      this.props.sectionHelper.updateQueue
        .flush()
        .then(() => {
          sectionHelper.upsertWordPress(brief.id).then(briefJson => {
            handleSectionContent(section, { name: 'briefLink' }, briefJson.content.briefLink)
            handleSectionContent(section, { name: 'wpid_new_2020_Q2_site' }, briefJson.content['wpid_new_2020_Q2_site'])
            resolve()
          })
        })
        .catch(error => {
          console.error('failed to post to wp, boo', error)
          reject(error)
        })
    })
  }

  render() {
    const { disabled } = this.props
    return (
      <div>
        <DisablingButton
          disabled={disabled}
          buttonAction={this.upsertWordPress}
          buttonText={'Create/update WordPress draft'}
        />
      </div>
    )
  }
}

class GenerateBriefLinkButton extends Component {
  static propTypes = {
    campaign: PropTypes.object,
    brief: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    handleSectionContent: PropTypes.func.isRequired,
    section: PropTypes.object.isRequired,
    sectionHelper: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
  }

  generateBriefLink = () => {
    const { campaign, handleSectionContent, section, sectionHelper, templateVariable } = this.props
    let slugName = section.content['Headline'].toLowerCase().replace(/[^A-Za-z0-9]/g, '-')
    let generatedBriefLink = `https://thehustle.co/${slugName}?utm_campaign=${campaign.name.replace(
      /\s/g,
      '+',
    )}&amp;utm_content=${campaign.date}-${slugName}&amp;utm_medium=email&amp;utm_source=daily`
    return new Promise((resolve, reject) => {
      this.props.sectionHelper.updateQueue
        .flush()
        .then(() => {
          handleSectionContent(section, { name: 'briefLink' }, generatedBriefLink)
          resolve()
        })
        .catch(error => {
          console.error('failed to generate briefLink', error)
          reject(error)
        })
    })
  }

  render() {
    const { disabled } = this.props
    return (
      <div>
        <DisablingButton disabled={disabled} buttonAction={this.generateBriefLink} buttonText={'Generate briefLink'} />
      </div>
    )
  }
}
