import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DateUtils, ClassName } from '../utils.js.jsx'

export default class AdReport extends Component {
  static propTypes = {
    report: PropTypes.object,
  }

  constructor(props) {
    super(props)
  }

  format_number(number) {
    return number.toLocaleString('en', 'decimal')
  }

  format_percent(percent) {
    return (percent * 100).toFixed(2) + '%'
  }

  render() {
    return (
      <div className="report">
        <div className="report-item">
          <div className="report-number">{this.format_number(this.props.report.sends)}</div>
          <div className="report-label">SENDS</div>
        </div>
        <div className="report-item">
          <div className="report-number">{this.format_number(this.props.report.opens)}</div>
          <div className="report-label">OPENS</div>
        </div>
        <div className="report-item">
          <div className="report-number">{this.format_number(this.props.report.clicks)}</div>
          <div className="report-label">CLICKS</div>
        </div>
        <div className="report-item">
          <div className="report-number">{this.format_percent(this.props.report.open_rate)}</div>
          <div className="report-label">OPEN RATE</div>
        </div>
        <div className="report-item">
          <div className="report-number">{this.format_percent(this.props.report.click_rate)}</div>
          <div className="report-label">CLICK RATE</div>
        </div>
      </div>
    )
  }
}
