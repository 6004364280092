import React from 'react'
import PropTypes from 'prop-types'
import { AdCard } from './ad_card.js.jsx'
import { AdHelper } from './helpers/ad_helper.js.jsx'
import SegmentHelper from './helpers/segment_helper.js.jsx'
import { DateUtils } from './utils.js.jsx'

class AdManager extends React.Component {
  static propTypes = {
    adminMode: PropTypes.bool.isRequired,
    cp: PropTypes.object.isRequired,
    cpid: PropTypes.string.isRequired,
    editable: PropTypes.bool.isRequired,
    syncClient: PropTypes.func.isRequired,
    toggleEdit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      ads: [],
      viewAds: 'upcoming',
      segmentHelper: null,
      displaySyncedMessage: false,
      syncingClient: false,
    }

    this.adHelper = new AdHelper()
    this.updateAd = this.updateAd.bind(this)
    this.getAd = this.getAd.bind(this)
    this.viewableAds = this.viewableAds.bind(this)
    this.segmentHelper = new SegmentHelper()

    this.WHITE = '#FFFFFF'
    this.EDITGRAY = '#D4D4D4'
    this.LIGHTGRAY = '#EEEEEE'
  }

  componentDidMount() {
    this.loadAds()
    this.segmentHelper
      .initializeIndex(this.props.cpid)
      .then(segmentHelper => this.setState({ segmentHelper: segmentHelper }))
    window.addEventListener('beforeunload', this.adHelper.updateQueue.flush)
  }

  loadAds = () => {
    return this.adHelper.index(this.props.cpid).then(ads => {
      this.setState({ ads: ads })
    })
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.adHelper.updateQueue.flush)
  }

  reloadAd(adId, cpId) {
    this.adHelper.show(adId, cpId).then(newAd => {
      this.setState(prevState => {
        let index = prevState.ads.findIndex(ad => ad.id === newAd.id)
        prevState.ads.splice(index, 1, newAd)
        return { ads: prevState.ads }
      })
    })
  }

  getAd(adId) {
    return this.state.ads.find(ad => ad.id == adId)
  }

  updateAd(adId, key, newValue) {
    this.setState(prevState => {
      // Destructively modify the ad
      this.adHelper.updateSimpleAd(this.getAd(adId), key, newValue)
      return { ads: this.state.ads }
    })
    this.adHelper.queueUpdate(adId, this.props.cpid, key, newValue)
  }

  // This filters the ads array using using this.state.viewAds to show only past or upcoming ads
  viewableAds() {
    const { ads, segmentHelper, viewAds } = this.state
    if (segmentHelper == null) {
      return []
    }
    let filterFunc = null
    let past = viewAds === 'past'
    if (viewAds == 'upcoming') {
      filterFunc = ad => !DateUtils.hasPastDate(ad.send_date)
    } else {
      filterFunc = ad => DateUtils.hasPastDate(ad.send_date)
    }
    let sortedAds = ads.filter(filterFunc)
    sortedAds.sort((ad1, ad2) => {
      if (ad1.send_date < ad2.send_date) {
        return -1
      } else if (ad1.send_date > ad2.send_date) {
        return 1
      } else if (ad1.send_date === ad2.send_date && !_.isEmpty(ad1.ad_segments) && !_.isEmpty(ad2.ad_segments)) {
        let ad1FirstSeg = this.segmentHelper.nameForId(ad1.ad_segments[0].segment_id)
        let ad2FirstSeg = this.segmentHelper.nameForId(ad2.ad_segments[0].segment_id)
        if (ad1FirstSeg < ad2FirstSeg) {
          return past ? 1 : -1
        } else if (ad1FirstSeg > ad2FirstSeg) {
          return past ? -1 : 1
        }
      }
      return 0
    })
    if (past) {
      sortedAds = sortedAds.reverse()
    }
    return sortedAds
  }

  adManagerBackgroundStyle() {
    let style = {}
    if (this.props.editable) {
      style.background = this.WHITE
    } else {
      style.background = this.EDITGRAY
    }
    return style
  }

  pastAdTabBackground() {
    if (this.state.viewAds == 'past') {
      if (this.props.editable) {
        return this.WHITE
      } else {
        return this.EDITGRAY
      }
    } else {
      return this.LIGHTGRAY
    }
  }

  upcomingAdTabBackground() {
    if (this.state.viewAds == 'upcoming') {
      if (this.props.editable) {
        return this.WHITE
      } else {
        return this.EDITGRAY
      }
    } else {
      return this.LIGHTGRAY
    }
  }

  tabBackground() {
    return this.props.editable ? this.EDITGRAY : this.WHITE
  }

  syncClient = () => {
    this.setState({ syncingClient: true }, () => {
      this.props.syncClient().then(() => {
        this.loadAds().then(this.displaySyncedMessage)
      })
    })
  }

  syncClientText = () => {
    return this.state.syncingClient ? 'Syncing new Ads and Line Items' : 'Sync Client'
  }

  displaySyncedMessage = () => {
    this.setState({ displaySyncedMessage: true, syncingClient: false }, () => {
      setTimeout(() => this.setState({ displaySyncedMessage: false }), 2000)
    })
  }

  renderSyncedMessage = () => {
    if (this.state.displaySyncedMessage) {
      return <div className="sync-complete-message">Sync Completed</div>
    }
    return null
  }

  render() {
    return (
      <div id="ad-tab-contain" style={{ background: this.tabBackground() }}>
        <div id="ad-tab-spacer">
          <div
            className="ad-tab"
            onClick={() => this.setState({ viewAds: 'past' })}
            style={{ background: this.pastAdTabBackground() }}>
            View Past Ads
          </div>
          <div
            className="ad-tab"
            onClick={() => this.setState({ viewAds: 'upcoming' })}
            style={{ background: this.upcomingAdTabBackground() }}>
            View Upcoming Ads
          </div>
        </div>
        <div id="ad-manager-background" style={this.adManagerBackgroundStyle()}>
          <div className="ads-contain">
            {(() => {
              if (this.props.adminMode) {
                return (
                  <div>
                    <div>
                      {this.renderSyncedMessage()}
                      <button className="sync-button orange-button" onClick={this.syncClient}>
                        {this.syncClientText()}
                      </button>
                    </div>
                  </div>
                )
              } else {
                return null
              }
            })()}
            <div>
              {this.viewableAds().map(ad => {
                return (
                  <AdCard
                    ad={ad}
                    key={ad.id}
                    updateAd={this.updateAd}
                    editable={this.props.editable}
                    adminMode={this.props.adminMode}
                    cp={this.props.cp}
                    reloadAd={this.reloadAd.bind(this)}
                    segmentHelper={this.state.segmentHelper}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export { AdManager }
