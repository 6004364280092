import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer'
import LineChart from 'recharts/lib/chart/LineChart'
import Line from 'recharts/lib/cartesian/Line'
import XAxis from 'recharts/lib/cartesian/XAxis'
import YAxis from 'recharts/lib/cartesian/YAxis'
import CartesianGrid from 'recharts/lib/cartesian/CartesianGrid'
import Tooltip from 'recharts/lib/component/Tooltip'
import Legend from 'recharts/lib/component/Legend'
import Moment from 'moment'

export default class CampaignReportLineChart extends Component {
  static propTypes = {
    campaignReports: PropTypes.array.isRequired,
  }

  totalsForChart() {
    let results = []

    this.props.campaignReports.forEach(cr => {
      let total_opens = 0
      let total_clicks = 0
      cr.email_reports.forEach(em => {
        total_opens += em.total_opens
        em.email_section_reports.map(esr => esr.email_section_urls.map(esUrl => (total_clicks += esUrl.click_count)))
      })
      results.push({
        Date: new Moment(cr.ran_at).format('M-D-YYYY'),
        'Total Opens': total_opens,
        'Total Clicks': total_clicks,
      })
    })
    return results.map(item => item)
  }

  render() {
    return (
      // 99% per https://github.com/recharts/recharts/issues/172
      <ResponsiveContainer width="99%" height={320}>
        <LineChart data={this.totalsForChart()}>
          <XAxis dataKey="Date" />
          <YAxis yAxisId="left" dataKey="Total Opens" orientation="left" />
          <YAxis yAxisId="right" dataKey="Total Clicks" orientation="right" />
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          <Line yAxisId="left" type="monotone" dataKey="Total Opens" stroke="#8884d8" />
          <Line yAxisId="right" type="monotone" dataKey="Total Clicks" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
    )
  }
}
