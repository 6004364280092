import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  Divider,
  Drawer,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { styles } from '../styles/page_builder_styles.js.jsx'
import PageSectionBoard from './page_section_board.js.jsx'

export default class PageBuilderSidebar extends Component {
  static propTypes = {
    addSection: PropTypes.func.isRequired,
    page: PropTypes.object.isRequired,
    pageId: PropTypes.number.isRequired,
    pagesSections: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    configured: PropTypes.bool.isRequired,
    editable: PropTypes.bool.isRequired,
    handleListItemClick: PropTypes.func.isRequired,
    propertyPresentInPage: PropTypes.func.isRequired,
    sectionsList: PropTypes.array.isRequired,
    selectSidebarSection: PropTypes.func.isRequired,
    selectedIndex: PropTypes.number,
    selectedSidebarSection: PropTypes.number,
    setDefaultSection: PropTypes.func.isRequired,
    updateSectionOrderings: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.setDefaultSection(0)
  }

  render() {
    const {
      addSection,
      page,
      pageId,
      pagesSections,
      classes,
      configured,
      editable,
      handleListItemClick,
      propertyPresentInPage,
      sectionsList,
      selectedIndex,
      selectedSidebarSection,
      selectSidebarSection,
      updateSectionOrderings,
    } = this.props

    return (
      <div id="page-sidebar">
        <Drawer
          elevation={16}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}>
          <div className={classes.toolbar} />
          <List>
            <ListItem
              button
              className={classes.h4}
              selected={selectedIndex === 0}
              onClick={() => handleListItemClick(0)}>
              <Typography className={selectedIndex === 0 ? classes.headingOpen : classes.heading}>Page</Typography>
              {configured && (
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
              )}
            </ListItem>
          </List>
          <Divider />
          <List>
            <Accordion className={classes.h4} expanded={selectedIndex === 1} onChange={() => handleListItemClick(1)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={selectedIndex === 1 ? classes.headingOpen : classes.heading}>
                  Sections
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <PageSectionBoard
                  addSection={addSection}
                  pageId={pageId}
                  pagesSections={pagesSections}
                  classes={classes}
                  editable={editable}
                  sectionsList={sectionsList}
                  selectedSidebarSection={selectedSidebarSection}
                  selectSidebarSection={selectSidebarSection}
                  updateSectionOrderings={updateSectionOrderings}
                />
              </AccordionDetails>
            </Accordion>
          </List>
        </Drawer>
      </div>
    )
  }
}

PageBuilderSidebar = withStyles(styles)(PageBuilderSidebar)
