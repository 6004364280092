import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { SectionHelper } from '../helpers/section_helper.js.jsx'
import SectionFormCheckbox from '../components/section_forms/section_form_checkbox.js.jsx'
import SectionFormTextField from '../components/section_forms/section_form_text_field.js.jsx'
import SectionFormUrlTextField from '../components/section_forms/section_form_url_text_field.js.jsx'
import SectionFormImageUrl from '../components/section_forms/section_form_image_url.js.jsx'
import SectionFormTextArea from '../components/section_forms/section_form_text_area.js.jsx'
import SectionFormCodeEditor from '../components/section_forms/section_form_code_editor.js.jsx'
import SectionFormPowerEditor from '../components/section_forms/section_form_power_editor.js.jsx'
import SectionFormRichTextEditor from '../components/section_forms/section_form_rich_text_editor.js.jsx'
import SectionFormSelectDropdown from '../components/section_forms/section_form_select_dropdown.js.jsx'
import SectionFormAuthorNameDropdown from '../components/section_forms/section_form_author_name_dropdown.js.jsx'
import SectionFormWordPressUploader from '../components/section_forms/section_form_word_press_uploader.js.jsx'
import SectionFormBriefLinkGenerator from '../components/section_forms/section_form_brief_link_generator.js.jsx'
import { TextField } from '@material-ui/core'
export default class FormHelper {
  // Takes 7 args:
  // - a `Section` (or blank if updating the section isn't needed, e.g. template section builder/preview)
  // - a `TemplateVariable`
  // - an `editable` boolean
  // - a `classes` object for style options matching required classes.{selector}s
  // - an `addedFunctions` function returning an object for holding functions handling changes and updates
  // - a `sectionHelper`, from props for ability to flush queued actions.
  // - a `builderType`, a string to describe the builder or previewer or editor that is being served`
  // - a `campaign`, the campaign object`
  // Returns the appropriate Material-UI component
  static renderVariableByType = (
    section,
    templateVariable,
    editable,
    classes,
    addedFunctions,
    sectionHelper,
    builderType,
    campaign,
  ) => {
    let varName = templateVariable.name
    let varType = templateVariable.variable_type
    let varValue = section.content[varName]
    if (varValue == null) {
      varValue = templateVariable.default_value
    }
    switch (varType) {
      case 'boolean':
        return (
          <SectionFormCheckbox
            classes={classes}
            editable={editable}
            handleSectionContent={addedFunctions().handleSectionContent}
            key={`form-checkbox-${section.id}-${templateVariable.id}-${varName}`}
            section={section}
            templateVariable={templateVariable}
          />
        )
        break
      case 'text':
        return (
          <SectionFormTextField
            classes={classes}
            editable={editable}
            handleSectionContent={addedFunctions().handleSectionContent}
            key={`form-text-field-${section.id}-${templateVariable.id}-${varName}`}
            section={section}
            templateVariable={templateVariable}
          />
        )
        break
      case 'url':
        return (
          <SectionFormUrlTextField
            classes={classes}
            editable={editable}
            handleSectionContent={addedFunctions().handleSectionContent}
            key={`form-url-text-field-${section.id}-${templateVariable.id}-${varName}`}
            section={section}
            templateVariable={templateVariable}
          />
        )
        break
      case 'image_url':
        return (
          <SectionFormImageUrl
            classes={classes}
            editable={editable}
            handleSectionContent={addedFunctions().handleSectionContent}
            handleUploadImage={addedFunctions().handleUploadImage}
            key={`form-image-url-${section.id}-${templateVariable.id}-${varName}`}
            section={section}
            templateVariable={templateVariable}
          />
        )
        break
      case 'text_area':
        return (
          <SectionFormTextArea
            classes={classes}
            editable={editable}
            handleSectionContent={addedFunctions().handleSectionContent}
            key={`form-text-area-${section.id}-${templateVariable.id}-${varName}`}
            section={section}
            templateVariable={templateVariable}
          />
        )
        break
      case 'code_editor':
        return (
          <SectionFormCodeEditor
            classes={classes}
            editable={editable}
            handleSectionContent={addedFunctions().handleSectionContent}
            key={`form-code-editor-${section.id}-${templateVariable.id}-${varName}`}
            section={section}
            templateVariable={templateVariable}
          />
        )
        break
      case 'power_editor':
        return (
          <SectionFormPowerEditor
            builderType={builderType}
            classes={classes}
            editable={editable}
            handleUploadImage={addedFunctions().handleUploadImage}
            handleSectionContent={addedFunctions().handleSectionContent}
            key={`form-power-editor-${section.id}-${templateVariable.id}-${varName}`}
            section={section}
            templateVariable={templateVariable}
          />
        )
        break
      case 'rich_text':
        return (
          <SectionFormRichTextEditor
            classes={classes}
            editable={editable}
            handleSectionContent={addedFunctions().handleSectionContent}
            key={`form-rich-text-editor-${section.id}-${templateVariable.id}-${varName}`}
            section={section}
            templateVariable={templateVariable}
          />
        )
        break
      case 'select_dropdown':
        return (
          <SectionFormSelectDropdown
            classes={classes}
            editable={editable}
            handleSectionContent={addedFunctions().handleSectionContent}
            key={`form-select-dropdown-${section.id}-${templateVariable.id}-${varName}`}
            section={section}
            templateVariable={templateVariable}
          />
        )
        break
      case 'author_name':
        return (
          <SectionFormAuthorNameDropdown
            classes={classes}
            editable={editable}
            handleSectionContent={addedFunctions().handleSectionContent}
            key={`form-select-dropdown-${section.id}-${templateVariable.id}-${varName}`}
            section={section}
            templateVariable={templateVariable}
          />
        )
        break
      case 'word_press_upload':
        return (
          <SectionFormWordPressUploader
            campaign={campaign}
            classes={classes}
            editable={editable}
            handleSectionContent={addedFunctions().handleSectionContent}
            key={`form-word-press-uploader-${section.id}-${templateVariable.id}-${varName}`}
            section={section}
            sectionHelper={sectionHelper}
            templateVariable={templateVariable}
          />
        )
        break
      case 'brief_link_generator':
        return (
          <SectionFormBriefLinkGenerator
            campaign={campaign}
            editable={editable}
            handleSectionContent={addedFunctions().handleSectionContent}
            key={`form-brief-link-generator-${section.id}-${templateVariable.id}-${varName}`}
            section={section}
            sectionHelper={sectionHelper}
          />
        )
        break
      default:
        console.error('Unknown section template variable type: ' + varType)
        return (
          (addedFunctions.renderEmptyShrugDiv && addedFunctions.renderEmptyShrugDiv()) || (
            <div key={`${templateVariable.id}-${varName}-${varType}`} />
          )
        )
        break
    }
  }

  // required args:
  // - sectionTemplateVariable
  // - classes
  // - editable
  // - updateSectionBoardVariable
  // returns a Material-UI component appropriate for the type
  static renderDefaultValueFieldByType = (sectionTemplateVariable, classes, editable, updateSectionBoardVariable) => {
    let component
    let sectionHelper = new SectionHelper()
    switch (sectionTemplateVariable.variable_type) {
      case 'boolean':
        let boolVarValue = sectionTemplateVariable.default_value === 't'
        component = (
          <FormControlLabel
            control={
              <Checkbox
                disabled={!editable}
                checked={boolVarValue}
                name="boolean"
                onChange={updateSectionBoardVariable('default_value')}
                classes={{
                  root: classes.root,
                  checked: classes.checked,
                }}
              />
            }
            label="Default Value"
          />
        )
        break
      case 'word_press_upload':
        component = <div />
        break
      default:
        component = (
          <TextField
            key={`section-form-variable-default-value-${sectionTemplateVariable.id}`}
            className="variable-default-value"
            disabled={!editable}
            label="Default Value"
            name="default_value"
            onChange={updateSectionBoardVariable('default_value')}
            value={sectionTemplateVariable.default_value}
          />
        )
        break
    }
    return <div id={`${sectionTemplateVariable.id}-section-template-variable-field-default-value`}>{component}</div>
  }
}
