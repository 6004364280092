import { SectionAPI } from '../apis/section_api.js.jsx'
import ArticlesSectionAPI from '../apis/articles_section_api.js.jsx'
import PagesSectionAPI from '../apis/pages_section_api.js.jsx'
import { EmailsSectionAPI } from '../apis/emails_section_api.js.jsx'
import { BatchFunctionCaller } from '../utils.js.jsx'

class SectionHelper {
  constructor(updateCallback) {
    this.updateQueue = new BatchFunctionCaller(this.bulkUpdateSections, 2000, updateCallback)

    this.create = this.create.bind(this)
    this.createArticlesSection = this.createArticlesSection.bind(this)
    this.createSectionForEmails = this.createSectionForEmails.bind(this)
    this.createSectionForArticle = this.createSectionForArticle.bind(this)
    this.setSectionOrdering = this.setSectionOrdering.bind(this)
    this.swapSections = this.swapSections.bind(this)
    this.orderingOf = this.orderingOf.bind(this)
    this.sortByEmailsSections = this.sortByEmailsSections.bind(this)

    this.pagesSectionAPI = new PagesSectionAPI()
  }

  bulkUpdateSections(sectionDictionary) {
    let promiseArray = []
    for (let sectionId in sectionDictionary) {
      promiseArray.push(
        SectionAPI.patch(sectionId, sectionDictionary[sectionId]).catch(error => {
          console.error('Failed to update section with id: ' + sectionId + '. ' + error)
        }),
      )
    }
    return Promise.all(promiseArray)
  }

  queueSectionContentUpdate(sectionId, sectionsList, contentUpdates) {
    return new Promise((resolve, reject) => {
      let newDictionaryEntry = {}
      let existingDictionary = this.updateQueue.dictionary
      if (existingDictionary && existingDictionary[sectionId] && existingDictionary[sectionId]['content']) {
        let existingDictionaryContent = existingDictionary[sectionId]['content']
        newDictionaryEntry['content'] = Object.assign({}, existingDictionaryContent, contentUpdates)
      } else {
        newDictionaryEntry['content'] = contentUpdates
      }
      this.updateQueue.queue(sectionId, newDictionaryEntry)
      // Update and return a sectionsList with contentUpdates
      let prevSection = sectionsList.find(sectionData => sectionData.id === sectionId) || { content: {} }
      let updatedSection = Object.assign(prevSection.content, contentUpdates)
      return resolve(sectionsList)
    }).catch(error => {
      console.error('Error updating sectionsList.', error)
      reject(error)
    })
  }

  find(sectionId) {
    return SectionAPI.find(sectionId).catch(error => {
      console.error('Could not find section with id ' + sectionId + '. ' + error)
      return error
    })
  }

  getEstimatedSize = sectionId => {
    return SectionAPI.getEstimatedSize(sectionId)
  }

  // This creates a section but does not assign it to any emails
  create(sectionData) {
    return SectionAPI.create(sectionData).catch(error => {
      console.error('Could not create new section. ' + error)
      return error
    })
  }

  // Create an ArticlesSection
  createArticlesSection(sectionId, articleId, ordering) {
    let data = { sectionId: sectionId, articleId: articleId, ordering: ordering }
    return ArticlesSectionAPI.create(data)
  }

  // Create an PagesSection
  createPagesSection(sectionId, pageId, ordering) {
    let data = { sectionId: sectionId, pageId: pageId, ordering: ordering }
    return this.pagesSectionAPI.create(data)
  }

  createSectionForArticle(sectionType, articleId, ordering) {
    let newSection = { section_type: sectionType, content: {} }

    return new Promise((resolve, reject) => {
      this.create(newSection)
        .then(sectionJson => {
          this.createArticlesSection(sectionJson.id, articleId, ordering).then(() => {
            resolve(sectionJson)
          })
        })
        .catch(error => console.error('Failed to create section type: ' + sectionType + '. ' + error))
    })
  }

  createSectionForPage(sectionType, pageId, ordering) {
    let newSection = { section_type: sectionType, content: {} }

    return new Promise((resolve, reject) => {
      this.create(newSection)
        .then(sectionJson => {
          this.createPagesSection(sectionJson.id, pageId, ordering).then(() => {
            resolve(sectionJson)
          })
        })
        .catch(error => console.error('Failed to create section type: ' + sectionType + '. ' + error))
    })
  }

  createSectionForEmails(sectionType, emailIds, ordering) {
    let newSection = { section_type: sectionType, content: {} }

    return new Promise((resolve, reject) => {
      this.create(newSection)
        .then(sectionJson => {
          let promises = Promise.all(
            emailIds.map(emailId => EmailsSectionAPI.create(sectionJson.id, emailId, ordering)),
          )
          promises.then(() => resolve(SectionAPI.find(sectionJson.id)))
        })
        .catch(error => console.error('Failed to create section type: ' + sectionType + '. ' + error))
    })
  }

  removeSectionFromEmail(section, emailId) {
    return new Promise(function(resolve, reject) {
      EmailsSectionAPI.del(section.id, emailId)
        .then(function() {
          section.emails_sections = section.emails_sections.filter(
            es => !(es.emailId == emailId && es.sectionId == section.id),
          )
          resolve(section)
        })
        .catch(function(error) {
          console.error(
            'Error deleting emails_sections with sectionId: ' + section.id + ', emailId: ' + emailId + '. ' + error,
          )
          reject(error)
        })
    })
  }

  replicateSection = sectionId => {
    return SectionAPI.replicate(sectionId)
  }

  sectionsForEmails = emailIds => {
    return Promise.all(emailIds.map(emailId => SectionAPI.sectionsForEmail(emailId))).then(sectionArrays => {
      let allSections = []
      sectionArrays.forEach(sectionArr => {
        sectionArr.forEach(section => {
          if (allSections.every(s => section.id != s.id)) {
            allSections.push(section)
          }
        })
      })
      return this.sortByEmailsSections(allSections)
    })
  }

  sectionsForArticle = (articleId, articlesSections) => {
    return new Promise((resolve, reject) => {
      SectionAPI.sectionsForArticle(articleId)
        .then(sections => {
          resolve(this.sortByArticlesSectionsOrdering(sections, articlesSections))
        })
        .catch(error => {
          console.error(error), reject(error)
        })
    })
  }

  sectionsForPage = (pageId, pagesSections) => {
    return new Promise((resolve, reject) => {
      SectionAPI.sectionsForPage(pageId)
        .then(sections => {
          resolve(this.sortByPagesSectionsOrdering(sections, pagesSections))
        })
        .catch(error => {
          console.error(error), reject(error)
        })
    })
  }

  index(sectionIds) {
    return SectionAPI.index(sectionIds)
  }

  show(sectionId) {
    return SectionAPI.show(sectionId)
  }

  templateVariablesForSections(sectionIds) {
    return SectionAPI.templateVariables(sectionIds)
  }

  deleteSection(sectionId) {
    return SectionAPI.del(sectionId)
  }

  deleteArticlesSection(sectionId, articleId) {
    return ArticlesSectionAPI.del(sectionId, articleId)
  }

  deleteEmailsSection(sectionId, emailId) {
    return EmailsSectionAPI.del(sectionId, emailId)
  }

  deletePagesSection(sectionId, pageId) {
    return this.pagesSectionAPI.destroy(sectionId, pageId)
  }

  // This destructively changes the orderings
  setSectionOrdering(sectionData, newOrdering) {
    sectionData.emails_sections.map(function(es) {
      es.ordering = newOrdering
      EmailsSectionAPI.update(sectionData.id, es.email_id, newOrdering)
    })
    return sectionData
  }

  // This destructively changes the orderings
  setArticlesSectionOrdering(sectionData, articleId, newOrdering) {
    let data = {
      sectionId: sectionData.id,
      articleId: articleId,
      ordering: newOrdering,
    }
    ArticlesSectionAPI.patch(data)
    return sectionData
  }

  setPagesSectionOrdering = (sectionData, pageId, newOrdering) => {
    let data = {
      sectionId: sectionData.id,
      pageId: pageId,
      ordering: newOrdering,
    }
    this.pagesSectionAPI.update(data)
    return sectionData
  }

  // This destructively changes the orderings
  swapSections(section1, section2) {
    let tmp = this.orderingOf(section1)
    this.setSectionOrdering(section1, this.orderingOf(section2))
    this.setSectionOrdering(section2, tmp)
  }

  orderingOf(sectionData) {
    if (sectionData.emails_sections[0] == null) {
      return 0
    } else {
      return sectionData.emails_sections[0].ordering
    }
  }

  orderingOfArticlesSection(sectionData, articlesSections) {
    if (articlesSections.length == 0) {
      return 0
    } else {
      return articlesSections.find(articlesSection => articlesSection.section_id == sectionData.id).ordering
    }
  }

  orderingOfPagesSection(sectionData, pagesSections) {
    if (pagesSections.length == 0) {
      return 0
    } else {
      return pagesSections.find(pagesSection => pagesSection.section_id == sectionData.id).ordering
    }
  }

  sortByEmailsSections(list) {
    return list.sort((a, b) => this.orderingOf(a) - this.orderingOf(b))
  }

  sortByArticlesSectionsOrdering(list, articlesSections) {
    if (list == null || list.length == 0) {
      return []
    } else {
      return list.sort(
        (a, b) =>
          this.orderingOfArticlesSection(a, articlesSections) - this.orderingOfArticlesSection(b, articlesSections),
      )
    }
  }

  sortByPagesSectionsOrdering = (list, pagesSections) => {
    if (list == null || list.length == 0) {
      return []
    } else {
      return list.sort(
        (a, b) => this.orderingOfPagesSection(a, pagesSections) - this.orderingOfPagesSection(b, pagesSections),
      )
    }
  }

  getMultiplePreviewHTML(sectionIds) {
    return Promise.all(
      sectionIds.map(sectionId => {
        return SectionAPI.previewHtml(sectionId).then(jsonData => {
          return jsonData.preview_html
        })
      }),
    ).then(htmlArray => htmlArray.join('\n'))
  }

  uploadImage(sectionId, imgData) {
    return SectionAPI.uploadImage(sectionId, imgData)
  }

  createWpImage(sectionId, imgFile, filename) {
    return SectionAPI.createWpImage(sectionId, imgFile, filename)
  }

  sectionsForAd(ad) {
    return new Promise((resolve, reject) => {
      AdsSectionAPI.index(ad.client_profile_id, ad.id)
        .then(ads_sections => {
          SectionHelper.index(ads_sections.map(as => as.id))
            .then(sections => {
              sections.json().then(resolve)
            })
            .catch(error => {
              console.error(`Failed to load sections while loading ads_sections: ${error}`)
            })
        })
        .catch(error => {
          console.error(`Failed to load ads_sections: ${error}`)
        })
    })
  }

  upsertWordPress(sectionId) {
    return SectionAPI.upsertWordPress(sectionId)
  }

  blank = id => {
    return {
      content: {},
      created_at: '',
      emails_sections: [],
      id: id,
      nickname: null,
      section_type: '',
      template_version: null,
      updated_at: '',
    }
  }
}

export { SectionHelper }
