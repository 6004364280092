import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  DragSource,
  ConnectDragSource,
  ConnectDragPreview,
  DragSourceConnector,
  DragSourceMonitor,
  DragSourceCollector,
} from 'react-dnd'
import Delete from '@material-ui/icons/Delete'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import NativeSelect from '@material-ui/core/NativeSelect'
import TextField from '@material-ui/core/TextField'
import FormHelper from '../helpers/form_helper.js.jsx'

const ItemTypes = {
  SECTION_TEMPLATE_VARIABLE: 'sectionTemplateVariable',
}

const sectionSource = {
  beginDrag(props) {
    return {
      originalIndex: props.findSectionTemplateVariable(props.sectionTemplateVariable.id).index,
      sectionTemplateVariable: props.sectionTemplateVariable,
      variables: props.variables,
      editable: props.editable,
      updateSectionTemplateVariableOrderings: props.updateSectionTemplateVariableOrderings,
    }
  },

  endDrag(props, monitor: DragSourceMonitor) {
    const item = monitor.getItem()
    const dropResult = monitor.getDropResult()

    if (!props.editable) {
      console.log('Section Template is not editable')
      return
    }
    if (dropResult == null) {
      console.log('Drop result not available.')
      return
    }
    if (dropResult) {
      let startTargetIndex = item.originalIndex
      let dropTargetIndex = dropResult.sectionTemplateVariableSlotIndex
      let updatedSectionTemplateVariables
      const rotatingSections = item.variables.slice(
        Math.min(startTargetIndex, dropTargetIndex),
        Math.max(startTargetIndex, dropTargetIndex) + 1,
      )
      const rotateDirection = startTargetIndex < dropTargetIndex ? 'down' : 'up'
      if (rotateDirection == 'up') {
        rotatingSections.unshift(rotatingSections.pop())
        item.variables.splice(dropTargetIndex, rotatingSections.length, ...rotatingSections)
      } else {
        rotatingSections.push(rotatingSections.shift())
        item.variables.splice(startTargetIndex, rotatingSections.length, ...rotatingSections)
      }
      updatedSectionTemplateVariables = item.variables.slice()
      props.updateSectionTemplateVariableOrderings(updatedSectionTemplateVariables)
    }
  },
}

const collect = (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
  connectDragSource: connect.dragSource(),
  getItem: monitor.getItem(),
  getDropResult: monitor.getDropResult(),
  isDragging: monitor.isDragging(),
})

@DragSource(ItemTypes.SECTION_TEMPLATE_VARIABLE, sectionSource, collect)
export default class SectionTemplateVariable extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    connectDragSource: PropTypes.func,
    deleteVariable: PropTypes.func.isRequired,
    editable: PropTypes.bool.isRequired,
    findSectionTemplateVariable: PropTypes.func.isRequired,
    getDropResult: PropTypes.bool,
    getItem: PropTypes.func,
    isDragging: PropTypes.bool,
    sectionTemplateVariable: PropTypes.object.isRequired,
    variables: PropTypes.array.isRequired,
    updateSectionTemplateVariableOrderings: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {}
    this.typeOptions = [
      'boolean',
      'image_url',
      'rich_text',
      'code_editor',
      'power_editor',
      'text',
      'author_name',
      'text_area',
      'url',
      'word_press_upload',
      'brief_link_generator',
    ]
  }

  updateSectionBoardVariable = name => event => {
    const { sectionTemplateVariable, updateSectionTemplateVariable } = this.props
    if (event.persist) {
      event.persist()
    }
    let eventValue
    if (event.target.name == 'boolean') {
      eventValue = event.target.checked == 't' || event.target.checked == true ? 't' : 'f'
    } else {
      eventValue = event.target.value
    }
    updateSectionTemplateVariable(sectionTemplateVariable.id)(name)(eventValue)
  }

  renderOptionsHtml = () => {
    return this.typeOptions.map(opt => (
      <option key={`option-${opt}`} value={opt}>
        {opt}
      </option>
    ))
  }

  renderSectionTemplateVariable = sectionTemplateVariable => {
    const { classes, editable } = this.props
    return (
      <div className="section-template-variable" key={`section-template-variable-${sectionTemplateVariable.id}`}>
        <div id={`section-template-variable-field-version-name`}>
          <TextField
            key={`stv-text-field-name-${sectionTemplateVariable.id}`}
            disabled={!editable}
            className="variable-version-name"
            label="Variable Name"
            value={sectionTemplateVariable.name}
            name="name"
            onChange={this.updateSectionBoardVariable('name')}
          />
        </div>
        <FormControl key={`form-control-${sectionTemplateVariable.id}`} className="variable-version-type">
          <InputLabel
            key={`input-label-${sectionTemplateVariable.id}`}
            htmlFor={`select-dropdown-${sectionTemplateVariable.id}`}
            shrink={true}>
            Variable Type
          </InputLabel>
          <NativeSelect
            disabled={!editable}
            key={`native-select-${sectionTemplateVariable.id}`}
            name="variable_type"
            onChange={this.updateSectionBoardVariable('variable_type')}
            input={
              <Input key={`input-${sectionTemplateVariable.id}`} id={`select-dropdown-${sectionTemplateVariable.id}`} />
            }>
            <option key={`default-option-${sectionTemplateVariable.id}`}>
              {sectionTemplateVariable.variable_type}
            </option>
            {this.renderOptionsHtml()}
          </NativeSelect>
        </FormControl>
        <div className="section-template-variable-field-default-value">
          {FormHelper.renderDefaultValueFieldByType(
            sectionTemplateVariable,
            classes,
            editable,
            this.updateSectionBoardVariable,
          )}
        </div>
      </div>
    )
  }

  render() {
    const { classes, connectDragSource, deleteVariable, isDragging, sectionTemplateVariable } = this.props

    return (
      connectDragSource &&
      connectDragSource(
        <div
          key={`${sectionTemplateVariable.id}-stv`}
          className={`${classes.dragging} section-template-variable`}
          style={{ opacity: isDragging ? 0.4 : 1 }}>
          {this.renderSectionTemplateVariable(sectionTemplateVariable)}
          <Delete onClick={deleteVariable(sectionTemplateVariable.id)} className="section-template-variable-trash" />
          <DragHandleIcon className="section-template-variable-drag-handle" />
        </div>,
      )
    )
  }
}
