import EmailListAPI from '../apis/email_list_api.js.jsx'
import _ from 'lodash'

export default class EmailListHelper {
  initializeIndex = () => {
    return new Promise((resolve, reject) => {
      EmailListAPI.index()
        .then(emailLists => {
          let regex = new RegExp(/global|marketing/i)
          this.emailLists = emailLists.filter(emailList => !emailList.name.match(regex)).map(this.cleanEmailListData)
          this.idEmailListMapping = {}
          this.emailLists.map(emailList => {
            this.idEmailListMapping[emailList.id] = emailList
          })
          resolve(this)
        })
        .catch(error => {
          console.error(`Failed to initialize EmailList index: ${error}`)
          reject(error)
        })
    })
  }

  emailListForId = id => {
    return this.idEmailListMapping[id]
  }

  nameForId = id => {
    if (id && !_.isEmpty(this.idEmailListMapping[id])) {
      return this.idEmailListMapping[id].name
    } else if (!_.isEmpty(id)) {
      console.error(`Name not found for EmailList with id: ${id || '(invalid or missing id)'}`)
      return '[EMAIL LIST NOT FOUND]'
    }
  }

  // Remove any nulls or undefined values from the email list data. Then
  // default any missing values to blank
  cleanEmailListData = emailList => {
    for (let key in emailList) {
      if (emailList[key] == null) {
        delete emailList[key]
      }
    }
    return Object.assign(this.blank(), emailList)
  }

  // Return a blank EmailList
  blank = () => {
    return {
      id: '',
      name: '',
      sgid: '',
      brand_id: '',
      default_email_envelope_id: '',
    }
  }
}
