import React from 'react'
import Moment from 'moment'

class DateUtils {
  static months() {
    return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
  }

  // Returns a date in the form YYYY-MM-DD or an empty string if given null
  static prettyDateFromISOString(isostring) {
    if (isostring) {
      return new Date(isostring).toISOString().split('T')[0]
    } else {
      return ''
    }
  }

  // Returns a string in this format: Mar 14, 2018 8:06:13 PM
  static prettyTimestampFromEpoch(epoch) {
    let time = new Date(epoch * 1000)
    let month = DateUtils.months()[time.getMonth()]
    let minutes = time.getMinutes().toString()
    if (minutes.length == 1) minutes = '0' + minutes
    return month + ' ' + time.getDate() + ' ' + time.getFullYear() + ' ' + time.getHours() + ':' + minutes
  }

  static momentDayOfWeekMonthNumberYear(isoString) {
    if (isoString) {
      return new Moment(isoString).toDate().toString()
    } else {
      return ''
    }
  }

  static momentDayOfWeekMonthNumberYearHourMinute(isoString) {
    if (isoString) {
      return new Moment(isoString).toDate().toString()
    } else {
      return ''
    }
  }

  static getDow(dateObj) {
    let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    return days[dateObj.getDay()]
  }

  static hasPast(isostring) {
    return new Date(isostring) < new Date()
  }

  static hasPastDate(isostring) {
    return new Moment(isostring).startOf('day') < new Moment().startOf('day')
  }

  // Returns the pacific time offset for the given date in a format
  // for ISO date strings
  static getPacificTimeOffset(date) {
    if (new Date(date).getTimezoneOffset() == new Date(2018, 0, 1).getTimezoneOffset()) {
      return '-08:00'
    } else {
      return '-07:00'
    }
  }
}

// BatchFunctionCaller accepts a function and a number of milliseconds when created
// It provides the queue function which adds objects to a 'queue' and it will call the provided
// function on a timer of length milliseconds
class BatchFunctionCaller {
  constructor(func, milliseconds, callback) {
    this.func = func
    this.dictionary = {}
    this.callback = callback
    this.timer = null
    this.milliseconds = milliseconds
    if (this.milliseconds == null) {
      this.milliseconds = 3000
    }

    this.runFunc = this.runFunc.bind(this)
    this.flush = this.flush.bind(this)
    this.queue = this.queue.bind(this)
    this.getQueueItem = this.getQueueItem.bind(this)
  }

  // Adds the object to the dictionary under the specified key. There is no ordering
  // If an object already exists under the key, it is shallow merged
  // object must be an Object
  // It starts the timer if it is not already started
  queue(key, object) {
    if (key in this.dictionary) {
      this.dictionary[key] = Object.assign(this.dictionary[key], object)
    } else {
      this.dictionary[key] = object
    }

    if (this.timer == null) {
      this.timer = setTimeout(this.runFunc, this.milliseconds)
    }
  }

  runFunc() {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
    let funcResult = this.func(this.dictionary)
    this.dictionary = {}
    if (this.callback != null && typeof this.callback == 'function') {
      if (funcResult != null && typeof funcResult == 'object' && funcResult.constructor.name == 'Promise') {
        funcResult.then(result => this.callback(result))
      } else {
        this.callback(funcResult)
      }
    }
    return funcResult
  }

  flush() {
    return this.runFunc()
  }

  getQueueItem(key) {
    return this.dictionary[key]
  }
}

// This displays a simple file upload button that calls fileCallback
// with the file contents and the filename
class FileUploader extends React.Component {
  parseFile(event) {
    event.persist()
    let reader = new FileReader()
    reader.addEventListener('load', () => {
      this.props.fileCallback(reader.result, event.target.files[0].name)
    })
    reader.readAsDataURL(event.target.files[0])
  }

  render() {
    return <input type="file" onChange={this.parseFile.bind(this)} />
  }
}

class ClassName {
  static generate(classNamesObj) {
    let classes = []
    Object.keys(classNamesObj).forEach(key => {
      if (classNamesObj[key] === true) {
        classes.push(key)
      }
    })
    return classes.join(' ')
  }
}

class StringUtils {
  static capitalize = variableName => {
    let varToTransform = variableName.replace(/([A-Z]+)*([A-Z][a-z])/g, '$1 $2')
    return varToTransform.replace(/\b[a-z]/g, letter => {
      return letter.toUpperCase()
    })
  }

  // Generates a 6-char hex char code from a string.
  // Useful for finding unique & predictable color choices.
  static strToRGBHexCode = str => {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }
    let c = (hash & 0x00ffffff).toString(16).toUpperCase()
    return `#${'00000'.substring(0, 6 - c.length)}${c}65`
  }

  static truncate = (input, maxLength) => {
    if (_.isEmpty(input)) {
      return ''
    }
    return input.length > maxLength ? `${input.substring(0, maxLength)}...` : input
  }
}

class ArrayUtils {
  static removeElementAtIndex(array, index) {
    return array.slice(0, index).concat(array.slice(index + 1))
  }
}

class BrandUtils {
  static getLogoForEmailList = emailList => {
    let img
    if (emailList.name.includes('Trends')) {
      img = 'https://thdaily.s3-us-west-1.amazonaws.com/med-trends_logo_20191216224210.png'
    } else if (emailList.name.includes('The Hustle')) {
      img = 'https://thdaily.s3-us-west-1.amazonaws.com/thehustle_black_300px_20200823171117.png'
    } else {
      img = '(Unknown Brand)'
    }
    return img
  }
}

class EmailValidator {
  constructor(email) {
    this.email = email
  }

  valid(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return re.test(String(this.email).toLowerCase())
  }
}

class UrlHelper {
  // Returns a string of url parameters that is interpreted as an array. It does not start with a ?
  // Example: UrlHelper.arrayToParams('ids', [1,2]) => 'ids[]=1&ids[]=2'
  static arrayToParams(paramName, array) {
    let urlParams = new URLSearchParams()
    array.map(value => urlParams.append(`${paramName}[]`, value))
    return urlParams.toString()
  }
}

export {
  ArrayUtils,
  BatchFunctionCaller,
  BrandUtils,
  ClassName,
  DateUtils,
  EmailValidator,
  FileUploader,
  StringUtils,
  UrlHelper,
}

