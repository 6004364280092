import { UrlHelper } from '../utils.js.jsx'

class SectionAPI {
  static jsonHeaders() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    }
  }

  static passcodeParamPath(path) {
    let passcode = new URL(window.location).searchParams.get('passcode')
    if (passcode != null) {
      let url = new URL(path, 'https://example.com')
      url.searchParams.append('passcode', passcode)
      return url.pathname + url.search
    } else {
      return path
    }
  }

  // This updates the specified section with new section data
  // data is an object like { ordering: 1 } or { content: { previewText: 'foobar' } }
  // The server will merge content updates into the DB. You only
  // need to specify content updates, not the entire content hash
  static patch(sectionId, data) {
    return new Promise(
      (function(that) {
        return function(resolve, reject) {
          let body = { section: data }
          let options = {
            credentials: 'same-origin',
            method: 'PATCH',
            body: JSON.stringify(body),
            headers: that.jsonHeaders(),
          }
          let url = '/sections/' + sectionId + '.json'
          let request = new Request(url, options)
          fetch(request)
            .then(function(response) {
              response
                .json()
                .then(jsonData => resolve(jsonData))
                .catch(error => reject(error))
            })
            .catch(error => reject(error))
        }
      })(this),
    )
  }

  static uploadImage(sectionId, imgData) {
    return new Promise(
      function(resolve, reject) {
        let reader = new FileReader()
        reader.addEventListener(
          'load',
          function() {
            let body = {
              section: {
                image: {
                  src: reader.result,
                  url_field: imgData.urlField,
                  filename: imgData.srcFile.name,
                  tiny_mce_image: imgData.tiny_mce_image,
                },
              },
            }
            let url = '/sections/' + sectionId + '.json'
            let options = {
              credentials: 'same-origin',
              method: 'PATCH',
              body: JSON.stringify(body),
              headers: this.jsonHeaders(),
            }
            let request = new Request(url, options)
            fetch(request)
              .then(function(response) {
                response
                  .json()
                  .then(jsonData => resolve(jsonData))
                  .catch(error => reject(error))
              })
              .catch(error => reject(error))
          }.bind(this),
          false,
        )

        reader.readAsDataURL(imgData.srcFile)
      }.bind(this),
    )
  }

  static upsertWordPress(sectionId) {
    return new Promise((resolve, reject) => {
      let body = {}
      let options = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = '/sections/' + sectionId + '/upsert_word_press.json'
      let request = new Request(url, options)
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  }

  static save(sectionData) {
    return new Promise(
      (function(that) {
        return function(resolve, reject) {
          let body = { section: sectionData }
          let options = {
            credentials: 'same-origin',
            method: 'PATCH',
            body: JSON.stringify(body),
            headers: that.jsonHeaders(),
          }
          let url = '/sections/' + sectionData.id + '.json'
          let request = new Request(url, options)
          fetch(request)
            .then(function(response) {
              response
                .json()
                .then(jsonData => resolve(jsonData))
                .catch(error => reject(error))
            })
            .catch(error => reject(error))
        }
      })(this),
    )
  }

  // This deletes the specified section using the API
  // Returns a promise that will yield nothing, but will expose an error
  // delete is a JS keyword
  static del(sectionId) {
    let options = {
      credentials: 'same-origin',
      method: 'DELETE',
    }
    let url = '/sections/' + sectionId + '.json'
    let request = new Request(url, options)

    return fetch(request)
  }

  // Returns a promise that will yield new section json data
  static create(sectionData) {
    return new Promise(
      function(resolve, reject) {
        let body = { section: sectionData }
        let options = {
          credentials: 'same-origin',
          method: 'POST',
          body: JSON.stringify(body),
          headers: this.jsonHeaders(),
        }
        let url = '/sections.json'
        let request = new Request(url, options)
        fetch(request)
          .then(function(response) {
            response
              .json()
              .then(jsonData => resolve(jsonData))
              .catch(error => reject(error))
          })
          .catch(error => reject(error))
      }.bind(this),
    )
  }

  // Replicates a section and its emails_sections for a Campaign
  static replicate(sectionId) {
    return new Promise((resolve, reject) => {
      let body = { id: sectionId }
      let options = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = `/sections/${sectionId}/replicate.json`
      let request = new Request(url, options)
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => {
              if (!jsonData['success']) {
                reject(jsonData)
                return
              } else {
                resolve(jsonData['section'])
              }
            })
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  }

  static templateVariables(sectionIds) {
    let baseUrl = '/sections/template_variables/'
    let url = baseUrl + '?' + sectionIds.map(id => 'ids[]=' + id).join('&')
    let request = new Request(url, { credentials: 'same-origin' })
    return fetch(request)
      .then(response => response.json())
      .catch(error => {
        console.error('Unable to fetch template variables for sectionIds: ' + sectionIds + '. ' + error)
      })
  }

  static createWpImage(sectionId, imgFile, filename) {
    return new Promise(
      function(resolve, reject) {
        let reader = new FileReader()
        reader.addEventListener(
          'load',
          function() {
            let body = {
              image: {
                src: reader.result,
                filename: filename,
              },
            }
            let url = '/sections/' + sectionId + '/wp_image.json'
            let options = {
              credentials: 'same-origin',
              method: 'POST',
              body: JSON.stringify(body),
              headers: this.jsonHeaders(),
            }
            let request = new Request(url, options)
            fetch(request)
              .then(function(response) {
                response
                  .json()
                  .then(jsonData => resolve(jsonData))
                  .catch(error => reject(error))
              })
              .catch(error => reject(error))
          }.bind(this),
          false,
        )

        reader.readAsDataURL(imgFile)
      }.bind(this),
    )
  }

  // Returns a promise that will yield an array of section json data
  // with the specified articleId
  static sectionsForArticle(articleId) {
    return new Promise((resolve, reject) => {
      let url = '/articles/' + articleId + '/sections.json'
      let request = new Request(url, { credentials: 'same-origin' })
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  }

  // Returns a promise that will yield an array of section json data
  // with the specified pageId
  static sectionsForPage(pageId) {
    return new Promise((resolve, reject) => {
      let url = '/pages/' + pageId + '/sections.json'
      let request = new Request(url, { credentials: 'same-origin' })
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  }

  // Returns a promise that will yield an array of section json data
  // with the specified emailId
  static sectionsForEmail(emailId) {
    return new Promise((resolve, reject) => {
      let url = '/emails/' + emailId + '/sections.json'
      let request = new Request(url, { credentials: 'same-origin' })
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  }

  // Returns a promise that will yield an array of section json data
  // with the specified ids
  static index(sectionIds) {
    return new Promise((resolve, reject) => {
      if (sectionIds.length == 0) {
        resolve([])
        return
      }
      let url = '/sections?' + UrlHelper.arrayToParams('ids', sectionIds)
      let request = new Request(this.passcodeParamPath(url), { credentials: 'same-origin' })
      fetch(request)
        .then(response => {
          response
            .json()
            .then(resolve)
            .catch(reject)
        })
        .catch(error => reject(error))
    })
  }

  // Returns a promise that will yield an array of section json data
  // with the specified ids
  static show(sectionId) {
    return new Promise(function(resolve, reject) {
      let url = `/sections/${sectionId}`
      let request = new Request(url, { credentials: 'same-origin' })
      fetch(request)
        .then(response => {
          response
            .json()
            .then(resolve)
            .catch(reject)
        })
        .catch(error => reject(error))
    })
  }

  static find(sectionId) {
    return new Promise(function(resolve, reject) {
      let url = '/sections/' + sectionId + '.json'
      let request = new Request(url, { credentials: 'same-origin' })
      fetch(request)
        .then(function(response) {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  }

  static previewHtml(sectionId) {
    return new Promise((resolve, reject) => {
      let url = '/sections/' + sectionId + '/preview_html.json'
      let request = new Request(this.passcodeParamPath(url), { credentials: 'same-origin' })
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  }

  static getEstimatedSize = sectionId => {
    return new Promise((resolve, reject) => {
      let url = '/sections/' + sectionId + '/estimate_size.json'
      let request = new Request(url, { credentials: 'same-origin' })
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  }
}

export { SectionAPI }
