export default class SegmentAPI {
  static jsonHeaders() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    }
  }

  static passcodeParamPath(path) {
    let passcode = new URL(window.location).searchParams.get('passcode')
    if (passcode != null) {
      let url = new URL(path, 'https://example.com')
      url.searchParams.append('passcode', passcode)
      return url.pathname + url.search
    } else {
      return path
    }
  }

  // Returns a promise that will yield an array of segments json data
  static index(cpId) {
    return new Promise((resolve, reject) => {
      let url = `/segments.json?client_profile_id=${cpId}`
      let request = new Request(this.passcodeParamPath(url), { credentials: 'same-origin' })
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => {
              console.error('Failed to retrieve segments', request)
              reject(error)
            })
        })
        .catch(error => {
          console.error('Failed to retrieve segments', request)
          reject(error)
        })
    })
  }

  // This updates the specified segments enabled boolean
  static patch(segmentId, updates) {
    return new Promise((resolve, reject) => {
      let body = { segment: updates }
      let options = {
        credentials: 'same-origin',
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = `/segments/${segmentId}`
      let request = new Request(this.passcodeParamPath(url), options)
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => {
              console.error('Failed to update segment property.', request)
              reject(error)
            })
        })
        .catch(error => {
          console.error('Failed to update segment property.', request)
          reject(error)
        })
    })
  }

  static create(params) {
    return new Promise((resolve, reject) => {
      let body = { segment: params }
      let options = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = '/segments.json'
      let request = new Request(url, options)
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => {
              console.error('Error while creating segment.', request)
              reject(error)
            })
        })
        .catch(error => {
          console.error('Error while creating segment.', request)
          reject(error)
        })
    })
  }
}
