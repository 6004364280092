import { ClientProfileAPI } from '../apis/client_profile_api.js.jsx'
import { BatchFunctionCaller } from '../utils.js.jsx'

class ClientProfileHelper {
  constructor() {
    this.updateQueue = new BatchFunctionCaller(this.bulkUpdateClientProfiles, 3000)

    this.queueUpdate = this.queueUpdate.bind(this)
    this.index = this.index.bind(this)
  }

  // cpDictionary is an object that looks like { id: { name: 'leesa', elevator_pitch: 'soft mattresses'} }
  bulkUpdateClientProfiles(cpDictionary) {
    let promiseArray = []
    for (let cpId in cpDictionary) {
      ClientProfileAPI.patch(cpId, cpDictionary[cpId]).catch(error => {
        console.error('Failed to update client profile with id: ' + cpId + '. ' + error)
      })
    }
    return Promise.all(promiseArray)
  }

  queueUpdate(cpid, key, newValue) {
    let queueObject = this.updateQueue.getQueueItem(cpid) || {}
    if (['name', 'image_url', 'client_logo_url', 'brief_submitted'].indexOf(key) >= 0) {
      queueObject[key] = newValue
    } else {
      if (queueObject['brief_data'] != null) {
        queueObject['brief_data'][key] = newValue
      } else {
        queueObject['brief_data'] = { [key]: newValue }
      }
    }
    this.updateQueue.queue(cpid, queueObject)
  }

  uploadLogo(cpId, rawImgData, filename, imageColumn) {
    return ClientProfileAPI.patch(cpId, {
      image_data: { src: rawImgData, filename: filename, image_column: imageColumn },
    }).catch(error => {
      console.error('Failed to upload logo for client profile id: ' + cpId + '. ' + error)
    })
  }

  find(cpid) {
    return ClientProfileAPI.show(cpid).then(cp => {
      return this.cleanCpData(cp)
    })
  }

  index(params) {
    return ClientProfileAPI.index(params || {}).then(cps => {
      return cps.map(this.cleanCpData.bind(this))
    })
  }

  sync(id) {
    return ClientProfileAPI.sync(id)
  }

  resetClientPasscode(cpId) {
    return ClientProfileAPI.resetClientPasscode(cpId)
      .then(cp => {
        return this.cleanCpData(cp)
      })
      .catch(error => {
        console.error('Failed to reset client passcode for id: ' + cpId + '. ' + error)
        return error
      })
  }

  cleanCpData(cp) {
    cp.brief_data = Object.assign(this.blank()['brief_data'], cp.brief_data)
    return Object.assign(this.blank(), cp)
  }

  // Updates the key with the new value in the given client
  // profile. It understands the specific data structure of a client
  // profile
  updateSimpleClientProfile(cp, key, newValue) {
    if (['name', 'image_url', 'client_logo_url', 'brief_submitted'].indexOf(key) >= 0) {
      cp[key] = newValue
    } else {
      cp['brief_data'][key] = newValue
    }
    return cp
  }

  displayName(cp) {
    if (cp.agency_name != 'No Agency') {
      return `${cp.name} (${cp.agency_name})`
    } else {
      return cp.name
    }
  }

  // Return a blank client profile
  blank() {
    return {
      name: '',
      image_url: '',
      client_logo_url: '',
      brief_submitted: '',
      brief_data: {
        elevator_pitch: '',
        brand_values: '',
        target_audience: '',
        goals: '',
        avoid: '',
        approval: '',
        anything_else: '',
      },
    }
  }
}

export { ClientProfileHelper }
