import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import CampaignBuilderDeliverButton from './campaign_builder_deliver_button.js.jsx'
import CheckIcon from '@material-ui/icons/Check'
import CodeIcon from '@mui/icons-material/Code'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MailOutlineSharpIcon from '@mui/icons-material/MailOutlineSharp'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Drawer,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { styles } from '../styles/campaign_builder_styles.js.jsx'
import SectionBoard from './section_board.js.jsx'
import { BrandUtils, StringUtils } from '../utils.js.jsx'
import swal from 'sweetalert'

export default class CampaignBuilderSidebar extends Component {
  static propTypes = {
    aBTestCampaign: PropTypes.object.isRequired,
    addSection: PropTypes.func.isRequired,
    availableEmails: PropTypes.object.isRequired,
    campaign: PropTypes.object.isRequired,
    campaignId: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    chooseSchedule: PropTypes.bool.isRequired,
    composed: PropTypes.bool.isRequired,
    configured: PropTypes.bool.isRequired,
    deliverable: PropTypes.bool.isRequired,
    editable: PropTypes.bool.isRequired,
    emailList: PropTypes.object.isRequired,
    emails: PropTypes.array.isRequired,
    handleListItemClick: PropTypes.func.isRequired,
    handleSchedulingCampaign: PropTypes.func.isRequired,
    handleShowDeliverDialogTree: PropTypes.func.isRequired,
    isAbTestCampaign: PropTypes.func.isRequired,
    loadCampaign: PropTypes.func.isRequired,
    markCampaignSent: PropTypes.func.isRequired,
    propertyPresentInCampaign: PropTypes.func.isRequired,
    scheduledHour: PropTypes.string.isRequired,
    scheduledMinute: PropTypes.string.isRequired,
    schedulingCampaign: PropTypes.bool.isRequired,
    sectionsList: PropTypes.array.isRequired,
    segmentHelper: PropTypes.object.isRequired,
    selectSidebarSection: PropTypes.func.isRequired,
    selectedIndex: PropTypes.number,
    selectedSidebarSection: PropTypes.number,
    setDefaultSection: PropTypes.func.isRequired,
    subjectsValid: PropTypes.bool.isRequired,
    updateSectionOrderings: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      updatingSailthruCampaigns: false,
    }
  }

  componentDidMount() {
    this.props.setDefaultSection(0)
  }

  handleClickDeliver = () => {
    const { handleListItemClick, handleShowDeliverDialogTree } = this.props
    handleListItemClick(4)
    handleShowDeliverDialogTree()
  }

  handleClickSendToSailthru = () => {
    const { handleListItemClick, handleShowSendToSailthruDialog } = this.props
    handleListItemClick(4)
    handleShowSendToSailthruDialog()
  }

  handleClickUpdateSailthruCampaigns = () => {
    const { aBTestCampaign, aBTestCampaignHelper, isAbTestCampaign, campaignId, campaignHelper } = this.props
    const { updatingSailthruCampaigns } = this.state
    if (isAbTestCampaign()) {
      this.setState({ updatingSailthruCampaigns: true })
      aBTestCampaignHelper.patchSailthruAbTestCampaign(aBTestCampaign.ab_test_campaign.id).then(results => {
        let swalConfig = {
          title: 'Sailthru AB Test Campaigns Updated',
          text: `Continue`,
          icon: 'success',
          buttons: true,
        }
        if (results['success'] == true) {
          swal(swalConfig)
        } else {
          swal('Error', 'Something went wrong', 'error')
        }
        this.setState({ updatingSailthruCampaigns: false })
      })
    } else {
      campaignHelper.patchSailthruCampaigns(campaignId).then(results => {
        let swalConfig = {
          title: 'Sailthru Campaigns Updated',
          text: `Continue`,
          icon: 'success',
          buttons: true,
        }
        if (results['success'] == true) {
          swal(swalConfig)
        } else {
          swal('Error', 'Something went wrong', 'error')
        }
        this.setState({ updatingSailthruCampaigns: false })
      })
    }
  }

  renderDeliverTitle = () => {
    const { propertyPresentInCampaign, schedulingCampaign } = this.props
    let title

    if (propertyPresentInCampaign('sent_at')) {
      title = 'Delivered'
    } else if (schedulingCampaign) {
      title = 'Scheduling CMS Tasks...'
    } else if (propertyPresentInCampaign('scheduled_time')) {
      title = 'CMS Tasks Scheduled'
    } else {
      title = 'Schedule CMS Tasks (Review Required)'
    }

    return title
  }

  renderUpdateSailthruCampaigns = () => {
    const { propertyPresentInCampaign } = this.props
    const { updatingSailthruCampaigns } = this.state

    let title

    if (!this.checkForSailthruBlastIds()) {
      title = 'No Sailthru Campaigns To Update'
    } else if (this.checkForSailthruBlastIds()) {
      title = updatingSailthruCampaigns ? 'Updating...' : 'Update Sailthru Campaigns'
    }

    return title
  }

  renderSendToSailthruTitle = () => {
    const { isAbTestCampaign, campaign, emails, propertyPresentInCampaign, segmentHelper } = this.props
    let title
    let href

    if (this.checkForSailthruBlastIds()) {
      title = []
      emails
        .filter(email => email.blast_id != null)
        .map(email => {
          let segmentName = segmentHelper.segmentList.find(segment => segment.id == email.segment_id).name
          if (isAbTestCampaign()) {
            href = `https://my.sailthru.com/blast?bid=${email.blast_id}#${email.blast_id}`
          } else {
            href = `https://my.sailthru.com/campaign#${email.blast_id}/confirmation`
          }

          title.push(
            <Link
              key={`sailthru-segment-link-${email.blast_id}`}
              className="sailthru-segment-link"
              href={href}
              target="_blank">
              View Segment {segmentName} on Sailthru
            </Link>,
            <hr />,
          )
        })
    } else {
      title = 'Send To Sailthru'
    }

    return title
  }

  checkForSailthruBlastIds = () => {
    const { emails } = this.props
    let emailsHaveBlastIds = emails.some(email => ['', null, undefined].indexOf(email['blast_id']) == -1)
    if (emailsHaveBlastIds) {
      return true
    }
    return false
  }

  checkForSentOrScheduled = () => {
    const { propertyPresentInCampaign } = this.props
    if (propertyPresentInCampaign('scheduled_time') || propertyPresentInCampaign('sent_at')) {
      return true
    }
    return false
  }

  handleOnClickForSailthruDelivery = () => {
    const { deliverable } = this.props
    if (!deliverable) {
      return false
    }
    if (this.checkForSentOrScheduled() && !this.checkForSailthruBlastIds()) {
      this.handleClickSendToSailthru()
    }
  }

  showAbTestBanner = () => {
    const { campaign, isAbTestCampaign } = this.props
    return (
      isAbTestCampaign() && (
        <Typography
          style={{
            backgroundColor: '#000000',
            border: 'orange solid 10px',
            color: '#45ffa2',
            margin: '-30px 0px 35px',
            padding: '10px',
            textAlign: 'center',
            fontWeight: 600,
            fontSize: '16px',
            fontFamily: 'courier, sans-serif',
            display: 'block',
          }}>
          AB Test ID: {campaign.ab_test_campaign_id}
          <br />
          AB Segment: {campaign.ab_test_segment}
        </Typography>
      )
    )
  }

  renderHubSpotAndScheduleButtons = () => {
    const {
      campaign,
      chooseSchedule,
      classes,
      configured,
      deliverable,
      editable,
      handleSchedulingCampaign,
      loadCampaign,
      markCampaignSent,
      propertyPresentInCampaign,
      scheduledHour,
      scheduledMinute,
      schedulingCampaign,
    } = this.props

    const campaignBuilderDeliverButton = (
      <CampaignBuilderDeliverButton
        campaign={campaign}
        configured={configured}
        deliverable={deliverable}
        editable={editable}
        handleSchedulingCampaign={handleSchedulingCampaign}
        loadCampaign={loadCampaign}
        markCampaignSent={markCampaignSent}
        propertyPresentInCampaign={propertyPresentInCampaign}
        scheduledHour={scheduledHour}
        scheduledMinute={scheduledMinute}
        scheduling={chooseSchedule}
        schedulingCampaign={schedulingCampaign}
      />
    )

    const campaignScheduledOrSent = propertyPresentInCampaign('sent_at') || propertyPresentInCampaign('scheduled_time')

    if (!configured) {
      return (
        <List>
          <ListItem button>{campaignBuilderDeliverButton}</ListItem>
        </List>
      )
    } else {
      return (
        <List>
          <ListItem button>{campaignBuilderDeliverButton}</ListItem>
          <ListItem button>
            <Button
              children="HubSpot Marketing Emails"
              className={`${classes.hubSpotMediaButton} hubspot-p19-button`}
              disabled={!campaignScheduledOrSent}
              endIcon={<CodeIcon />}
              onClick={() => window.open(`https://app.hubspot.com/email/20627419/manage/state/all`)}
              variant="contained"
            />
          </ListItem>
          <ListItem button>
            <Button
              children="HubSpot Design Tools"
              className={`${classes.hubSpotMediaButton} hubspot-p19-button`}
              disabled={!campaignScheduledOrSent}
              endIcon={<MailOutlineSharpIcon />}
              onClick={() => window.open(`https://app.hubspot.com/design-manager/20627419/`)}
              variant="contained"
            />
          </ListItem>
        </List>
      )
    }
  }

  render() {
    const {
      addSection,
      availableEmails,
      campaign,
      campaignId,
      chooseSchedule,
      classes,
      composed,
      configured,
      deliverable,
      editable,
      emailList,
      handleListItemClick,
      handleSchedulingCampaign,
      isAbTestCampaign,
      loadCampaign,
      markCampaignSent,
      propertyPresentInCampaign,
      scheduledHour,
      scheduledMinute,
      schedulingCampaign,
      sectionsList,
      selectedIndex,
      selectedSidebarSection,
      selectSidebarSection,
      subjectsValid,
      updateSectionOrderings,
    } = this.props
    const { updatingSailthruCampaigns } = this.state

    return (
      <div id="campaign-sidebar">
        <Drawer
          elevation={16}
          variant="permanent"
          style={{ backgroundColor: StringUtils.strToRGBHexCode(emailList.name) }}
          classes={{
            paper: classes.drawerPaper,
          }}>
          <div className={classes.toolbar} />
          <List>
            {this.showAbTestBanner()}
            <ListItem
              style={{
                backgroundColor: StringUtils.strToRGBHexCode(emailList.name),
                margin: '-34px 0px -16px',
                display: 'block',
              }}
              className={classes.h2}>
              <p>
                <img className="brand-image" src={BrandUtils.getLogoForEmailList(emailList)} alt={emailList.name} />
              </p>
              <Typography
                style={{
                  textAlign: 'center',
                  fontWeight: 600,
                  fontSize: '18px',
                  fontFamily: 'courier, sans-serif',
                  display: 'block',
                }}>
                {emailList.name}
              </Typography>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem
              button
              className={classes.h4}
              selected={selectedIndex === 0}
              key={campaign.id}
              onClick={() => handleListItemClick(0)}>
              <Typography className={selectedIndex === 0 ? classes.headingOpen : classes.heading}>Configure</Typography>
              {configured && (
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
              )}
            </ListItem>
          </List>
          <Divider />
          <List>
            <Accordion className={classes.h4} expanded={selectedIndex === 1} onChange={() => handleListItemClick(1)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={selectedIndex === 1 ? classes.headingOpen : classes.heading}>Build</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SectionBoard
                  addSection={addSection}
                  availableEmails={availableEmails}
                  campaignId={campaignId}
                  classes={classes}
                  editable={editable}
                  sectionsList={sectionsList}
                  selectedSidebarSection={selectedSidebarSection}
                  selectSidebarSection={selectSidebarSection}
                  updateSectionOrderings={updateSectionOrderings}
                />
              </AccordionDetails>
            </Accordion>
          </List>
          <Divider />
          <List>
            <ListItem
              button
              className={classes.h4}
              selected={selectedIndex === 4}
              onClick={() => handleListItemClick(4)}>
              <Typography className={selectedIndex === 4 ? classes.headingOpen : classes.heading}>Review</Typography>
              {campaign.reviewed && (
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
              )}
            </ListItem>
          </List>
          <Divider />
          {this.renderHubSpotAndScheduleButtons()}
        </Drawer>
      </div>
    )
  }
}

CampaignBuilderSidebar = withStyles(styles)(CampaignBuilderSidebar)
