import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import RichTextEditorSectionContainer from '../rich_text_editor_section_container.js.jsx'
import FormHelper from '../../helpers/form_helper.js.jsx'
import _ from 'lodash'

const SectionFormRichTextEditor = props => (
  <FormControl className={`rich-text-form-control`}>
    <label className="form-control-rte">{_.capitalize(props.templateVariable.name)}</label>
    <RichTextEditorSectionContainer
      editable={props.editable}
      name={props.templateVariable.name}
      onChange={(event, htmlContent) => props.handleSectionContent(props.section, props.templateVariable, htmlContent)}
      value={props.section.content[props.templateVariable.name] || ''}
    />
  </FormControl>
)

export default SectionFormRichTextEditor

SectionFormRichTextEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  handleSectionContent: PropTypes.func.isRequired,
  section: PropTypes.object.isRequired,
  templateVariable: PropTypes.object.isRequired,
}
