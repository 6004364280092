import BaseAPI from './base_api.js'
import _ from 'lodash'

export default class EmailSendJobAPI extends BaseAPI {
  jsonHeaders = () => {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    }
  }

  resourceName = params => 'email_send_job'
  baseUrl = params => '/email_send_jobs'
  indexUrl = params => `${this.baseUrl(params)}${this.buildParams(params)}`
  showUrl = params => `${this.resourceUrl(params)}${this.buildParams(params)}`

  buildParams = params => {
    let paramsStr = Object.keys(params)
      .map(key => key + '=' + encodeURIComponent(params[key]))
      .join('&')
    return `?${paramsStr}`
  }

  testWithEmails = params => {
    return new Promise((resolve, reject) => {
      let body = { email_send_job: { id: params.id, emails: params.emails } }
      let options = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = '/email_send_jobs/' + params.id + '/test_with_emails.json'
      let request = new Request(url, options)
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => {
              console.error(
                `Failed to send test email for Drip Campaign ID: ${params.id} to emails: ${params.emails}, error: ${error}`,
              )
              reject(error)
            })
        })
        .catch(error => {
          console.error(
            `Failed to send test email for Drip Campaign ID: ${params.id} to emails: ${params.emails}, error: ${error}`,
          )
          reject(error)
        })
    })
  }
}
