import React from 'react'
import { FormGroup, FormLabel, TextField } from '@material-ui/core'
import Moment from 'moment'
import _ from 'lodash'

export default class MaterialTableHelper {
  formatDate = value => {
    if (value) {
      return value.slice(0, 10).replace(/-/g, '/')
    } else {
      return ''
    }
  }

  formatFilterListRender = (value, label) =>
    !_.isEmpty(value) && value[0] !== null && `${label}: ${value.join(',').replace(',', ' => ')}`

  customizeFilterOptionsDisplay = (filterList, onChange, index, column, rangeLabel) =>
    !_.isEmpty(filterList) && (
      <div>
        <FormLabel style={{ fontSize: '12px' }}>{rangeLabel}</FormLabel>
        <FormGroup>
          <TextField
            type="date"
            label="From"
            InputLabelProps={{
              shrink: true,
              style: { margin: '5px 0px', fontSize: '13px' },
            }}
            InputProps={{
              style: { fontSize: '13px', width: '90%' },
            }}
            value={filterList[index][0] || ''}
            onChange={event => {
              filterList[index][0] = event.target.value
              onChange(filterList[index], index, column)
            }}
          />
          <TextField
            type="date"
            label="To"
            InputLabelProps={{
              shrink: true,
              style: { margin: '5px 0px', fontSize: '13px' },
            }}
            InputProps={{
              style: { fontSize: '13px', width: '90%' },
            }}
            value={filterList[index][1] || ''}
            onChange={event => {
              filterList[index][1] = event.target.value
              onChange(filterList[index], index, column)
            }}
          />
        </FormGroup>
      </div>
    )

  customizeFilterOptionsLogicForIso8601Sorting = (iso8601Date, filters) => {
    if (_.isEmpty(filters) || _.isEmpty(iso8601Date)) {
      return false
    }
    let fromDate = filters[0] && new Moment(filters[0], 'YYYY-MM-DD')
    let toDate = filters[1] && new Moment(filters[1], 'YYYY-MM-DD')
    let momentizedIso8601Param = new Moment(iso8601Date.split(' ')[0], 'L LT Z')
    if (fromDate && !fromDate.isValid()) {
      return false
    } else if (toDate && !toDate.isValid() && momentizedIso8601Param) {
      return momentizedIso8601Param < fromDate
    } else if (fromDate && toDate) {
      return momentizedIso8601Param < fromDate || momentizedIso8601Param > toDate
    } else if (fromDate && momentizedIso8601Param) {
      return momentizedIso8601Param < fromDate
    } else if (toDate && momentizedIso8601Param) {
      return momentizedIso8601Param > toDate
    }
    return false
  }

  handleRowClick = (id, metaKey, pathnameTuple) => {
    let pathname = `/${pathnameTuple[0]}/${id}/${pathnameTuple[1]}`
    if (!metaKey) {
      window.location.href = pathname
    } else {
      let newWindow = window.open()
      newWindow.opener = null
      newWindow.target = '_blank'
      newWindow.location = pathname
    }
  }

  getConfigsFromParams = (name, type) => {
    if (history.pushState) {
      let sp = new URLSearchParams(document.location.search)
      let configParams = []

      if (!_.isEmpty(sp.get(name))) {
        configParams.push(sp.getAll(name))
      }

      let flattened = configParams.flat()
      switch (type) {
        case 'boolean':
          configParams = flattened.map(el => el === 'true')
          break
        case 'string[]':
          configParams = flattened.length > 0 ? flattened[0].split(',') : []
          break
        default:
          break
      }

      return configParams
    }
  }

  getSortDirection = name => {
    let sortParam = this.getConfigsFromParams(`sortColumn,${name}`, 'string[]')
    if (sortParam.length > 0 && sortParam[1] !== null && ['asc', 'desc'].includes(sortParam[1])) {
      return sortParam[1]
    }
  }

  displayColumn = name => {
    let columnParam = this.getConfigsFromParams(`displayColumn,${name}`, 'string[]')
    if (columnParam.length > 0 && columnParam[1] !== null && ['add', 'remove'].includes(columnParam[1])) {
      return columnParam[1] === 'remove' ? 'false' : 'true'
    }
  }
}
