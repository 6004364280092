export default class BaseAPI {
  jsonHeaders = () => {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    }
  }

  resourceName = () => 'General API resource'
  baseUrl = params => `/`
  resourceUrl = params => `${this.baseUrl(params)}/${params['id']}`
  indexUrl = params => this.baseUrl(params)
  createUrl = params => this.baseUrl(params)
  showUrl = params => this.resourceUrl(params)
  updateUrl = params => this.resourceUrl(params)
  destroyUrl = params => this.resourceUrl(params)

  paramsToBody = params => {
    return { [this.resourceName()]: params }
  }

  index = params => {
    return new Promise((resolve, reject) => {
      let request = new Request(this.indexUrl(params), { credentials: 'same-origin' })
      fetch(request).then(response => {
        response
          .json()
          .then(resolve)
          .catch(error => {
            console.error(`Failed to retrieve index action for ${this.resourceName()}: ${error}`)
            console.error(params)
            console.error(request)
            reject(error)
          })
      })
    })
  }

  create = params => {
    return new Promise((resolve, reject) => {
      let options = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(this.paramsToBody(params)),
        headers: this.jsonHeaders(),
      }
      let url = this.createUrl(params)
      let request = new Request(url, options)
      fetch(request).then(response => {
        response
          .json()
          .then(resolve)
          .catch(error => {
            console.error(`Failed to create ${this.resourceName()}: ${error}`)
            console.error(params)
            console.error(request)
            reject(error)
          })
      })
    })
  }

  show = params => {
    return new Promise((resolve, reject) => {
      let request = new Request(this.showUrl(params), { credentials: 'same-origin' })
      fetch(request).then(response => {
        response
          .json()
          .then(resolve)
          .catch(error => {
            console.error(`Failed to retrieve show action for ${this.resourceName()}: ${error}`)
            console.error(params)
            console.error(request)
            reject(error)
          })
      })
    })
  }

  update = params => {
    return new Promise((resolve, reject) => {
      let options = {
        credentials: 'same-origin',
        method: 'PATCH',
        body: JSON.stringify(this.paramsToBody(params)),
        headers: this.jsonHeaders(),
      }
      let url = this.updateUrl(params)
      let request = new Request(url, options)
      fetch(request).then(response => {
        response
          .json()
          .then(resolve)
          .catch(error => {
            console.error(`Failed to update ${this.resourceName()}: ${error}`)
            console.error(params)
            console.error(request)
            reject(error)
          })
      })
    })
  }

  destroy = params => {
    return new Promise((resolve, reject) => {
      let options = {
        credentials: 'same-origin',
        method: 'DELETE',
        headers: this.jsonHeaders(),
      }
      let url = this.destroyUrl(params)
      let request = new Request(url, options)
      fetch(request).then(response => {
        response
          .json()
          .then(resolve)
          .catch(error => {
            console.error(`Failed to delete ${this.resourceName()}: ${error}`)
            console.error(params)
            console.error(request)
            reject(error)
          })
      })
    })
  }

  new = params => {
    console.error('New Not Implemented')
  }
  edit = params => {
    console.error('Edit Not Implemented')
  }
}
