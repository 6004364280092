export default class UsersAPI {
  static jsonHeaders() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    }
  }

  // Returns the current user object as a JSON object
  static currentUser() {
    return new Promise((resolve, reject) => {
      let url = `/users/current.json`
      let request = new Request(url, { credentials: 'same-origin' })
      fetch(request)
        .then(response => response.json())
        .then(jsonData => resolve(jsonData))
        .catch(err => {
          console.error(request, err)
          reject(err)
        })
    })
  }
}
