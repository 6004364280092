import React from 'react'
import PropTypes from 'prop-types'
import { SectionTemplateHelper } from '../helpers/section_template_helper.js.jsx'

export default class SectionTemplatePicker extends React.Component {
  static propTypes = {
    addSection: PropTypes.func.isRequired,
    editable: PropTypes.bool.isRequired,
    newSectionType: PropTypes.string.isRequired,
    setSectionType: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.sectionTemplateHelper = new SectionTemplateHelper()
    this.selectSectionTemplateNames = [
      'Centered Masthead',
      'Native Advertorial',
      'Extended Graphic Ad',
      'Spunky Sunday Masthead',
      'Sunday Masthead',
      'Sunday Sponsor Note',
      'Sunday Sponsor Note Rich Text',
      'The Hustle Says... Entry',
    ]
    this.state = { templates: [] }
  }

  componentDidMount() {
    this.sectionTemplateHelper.activeTemplates().then(templates => {
      this.setState({ templates: templates })
    })
  }

  render() {
    const { templates } = this.state
    const { editable, newSectionType, setSectionType, addSection } = this.props
    if (templates.length == 0 || !editable) {
      return null
    }
    let presentSelectedSectionTemplates = templates.filter(template =>
      this.selectSectionTemplateNames.includes(template.version_name),
    )
    return (
      <div className="addsection">
        <select className="sectionselect" value={newSectionType} onChange={setSectionType}>
          {presentSelectedSectionTemplates.map(template => {
            return (
              <option key={template.id} value={template.version_name}>
                {template.version_name}
              </option>
            )
          })}
          <option disabled>──────────</option>
          {templates.map(template => {
            return (
              <option key={template.id} value={template.version_name}>
                {template.version_name}
              </option>
            )
          })}
        </select>
        <button className="addsectionbutt" onClick={addSection}>
          + Add New Section
        </button>
      </div>
    )
  }
}
