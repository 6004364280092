import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import CloseIcon from '@material-ui/icons/Close'
import { SubjectHelper } from '../helpers/subject_helper.js.jsx'
import _ from 'lodash'

export default class CampaignBuilderSubjectLines extends Component {
  static propTypes = {
    aBTestCampaign: PropTypes.object.isRequired,
    aBTestCampaignHelper: PropTypes.object.isRequired,
    campaign: PropTypes.object.isRequired,
    campaignReviewed: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    editable: PropTypes.bool.isRequired,
    isAbTestCampaign: PropTypes.func.isRequired,
    isAbTestCampaignPopulated: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    updateSubjectsValid: PropTypes.func.isRequired,
    updateSubjectLines: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      subjects: [],
    }

    this.subjectHelper = new SubjectHelper()
  }

  componentDidMount() {
    const { aBTestCampaign, campaign, isAbTestCampaign, isAbTestCampaignPopulated } = this.props
    this.loadSubjects().then(subjects => {
      if (isAbTestCampaign() && isAbTestCampaignPopulated()) {
        aBTestCampaign.variant_campaigns.forEach(campaign => {
          if (!subjects.find(subject => subject.campaign_id == campaign.id)) {
            this.addSubject(campaign.id)
          }
        })
      } else {
        if (subjects.length < 1) {
          this.addSubject(campaign.id)
        }
      }
    })
  }

  flushUpdates = () => {
    if (this.props.editable) {
      this.subjectHelper.updateQueue.flush()
    }
  }

  loadSubjects = () => {
    const { campaign, isAbTestCampaign } = this.props
    return new Promise((resolve, reject) => {
      this.subjectHelper
        .index({ campaign_id: this.props.campaign.id })
        .then(subjects => {
          let sortedSubjects = subjects
          if (isAbTestCampaign()) {
            sortedSubjects = subjects.sort((a, b) => {
              return a.campaign.ab_test_segment > b.campaign.ab_test_segment ? 1 : -1
            })
          } else {
            sortedSubjects = [_.head(subjects)]
          }
          this.setState({ subjects: sortedSubjects }, this.updateSubjects)
          resolve(subjects)
        })
        .catch(error => {
          console.error('Error resolving subject lines from server')
          reject(error)
        })
    })
  }

  updateSubjects = () => {
    const { subjects } = this.state
    let valid = true
    if (subjects.length == 0) {
      valid = false
    }
    subjects.map(subject => {
      let sub = _.get(subject, 'subject')
      if (!sub || (sub && sub.length == 0) || sub == 'CHANGE ME') {
        valid = false
      }
      if (sub != null) {
        sub = sub.trim()
      }
    })
    this.props.updateSubjectsValid(valid)
    this.props.updateSubjectLines(subjects)
  }

  updateSubject = id => event => {
    const { editable } = this.props
    if (!editable) {
      console.log('Cannot update subjects because campaign has been sent')
      return
    }
    event.persist()
    this.setState(prevState => {
      let subject = prevState.subjects.find(subject => subject.id == id)
      subject[event.target.name] = event.target.value
      return { subjects: prevState.subjects }
    }, this.updateSubjects)
    this.subjectHelper.queueUpdate(id, event.target.name, event.target.value)
  }

  deleteSubject = id => {
    return () => {
      if (!this.props.editable) {
        console.log('Cannot update subjects because campaign has been sent')
        return
      }
      this.subjectHelper.delete(id).then(() => {
        this.setState(prevState => {
          let subject = prevState.subjects.find(subject => subject.id == id)
          let updatedSubjects = prevState.subjects.filter(subject => subject.id != id)
          return { subjects: updatedSubjects }
        }, this.updateSubjects)
      })
    }
  }

  addSubject = campaignId => {
    if (!this.props.editable) {
      console.log('Cannot update subjects because campaign has been sent')
      return
    }
    this.subjectHelper.create({ campaign_id: campaignId }).then(subject => {
      this.setState(prevState => {
        return {
          subjects: prevState.subjects.concat(subject).sort((a, b) => {
            return a.campaign.ab_test_segment > b.campaign.ab_test_segment ? 1 : -1
          }),
        }
      }, this.updateSubjects)
    })
  }

  render() {
    const { subjects } = this.state
    const { campaign, classes, editable, isAbTestCampaign, show } = this.props
    if (!show) {
      return null
    }

    return (
      <div id="subject-lines">
        <div className="subjects-line-item">
          {subjects.map(subject => {
            return (
              <SubjectLineItem
                campaign={campaign}
                disabled={!editable}
                classes={classes}
                isAbTestCampaign={isAbTestCampaign}
                key={subject.id}
                subject={subject}
                updateSubject={this.updateSubject(subject.id)}
                deleteSubject={this.deleteSubject(subject.id)}
              />
            )
          })}
        </div>
      </div>
    )
  }
}

class SubjectLineItem extends React.Component {
  static propTypes = {
    campaign: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    deleteSubject: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    isAbTestCampaign: PropTypes.func.isRequired,
    subject: PropTypes.object.isRequired,
    updateSubject: PropTypes.func.isRequired,
  }

  render() {
    const { campaign, classes, deleteSubject, disabled, isAbTestCampaign, subject, updateSubject } = this.props
    let aBTestCampaignSegment = _.get(subject, 'campaign.ab_test_segment')
    if (subject == null || campaign == null) {
      return null
    }
    return (
      <div className="subject-item">
        <TextField
          required
          disabled={disabled}
          fullWidth
          className={classes.textField}
          name="subject"
          type="text"
          onChange={updateSubject}
          value={subject.subject}
          placeholder="Loris Ipsum"
        />
      </div>
    )
  }
}
