import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import LinkIcon from '@material-ui/icons/Link'
import TextField from '@material-ui/core/TextField'
import _ from 'lodash'

const renderImageView = (section, varName) => {
  let imgUrl = _.get(section, `content.${varName}`)
  let imgElement
  if (_.isEmpty(imgUrl)) {
    imgElement = <label>No image uploaded yet</label>
  } else {
    imgElement = <img src={imgUrl} className="img-inner" />
  }

  return <FormControl className="img-view">{imgElement}</FormControl>
}

const SectionFormImageUrl = props => {
  let varName = props.templateVariable.name

  return (
    <div className={props.classes.formControl}>
      <FormControl className={`image-url-form-control`}>
        <Button disabled={!props.editable} className="image-url-button" color="default" variant="contained">
          <span className="image-url-button-label">Upload Image</span>
          <Input
            onChange={props.handleUploadImage(varName, props.section.id, props.templateVariable.id)}
            type="file"
            name={varName}
            className="image-url-button-input"
          />
          <CloudUploadIcon className="right-icon-upload" />
        </Button>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LinkIcon />
              </InputAdornment>
            ),
            endAdornment: props.editable && (
              <Button
                onClick={event => props.handleSectionContent(props.section, props.templateVariable, '')}
                color="secondary"
                variant="contained"
                children="X"
              />
            ),
            style: {
              width: '-webkit-fill-available',
            },
          }}
          disabled
          className="image-url-upload-text-field"
          placeholder="https://logourl.com"
          name={varName}
          type="text"
          margin="dense"
          label={_.capitalize(varName)}
          onChange={event =>
            props.handleSectionContent(props.section, props.templateVariable, event.target.value.trim())
          }
          value={props.section.content[varName] || ''}
        />
      </FormControl>

      <FormControl className="form-control-view-image">{renderImageView(props.section, varName)}</FormControl>
    </div>
  )
}

export default SectionFormImageUrl

SectionFormImageUrl.propTypes = {
  classes: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  handleSectionContent: PropTypes.func.isRequired,
  handleUploadImage: PropTypes.func.isRequired,
  section: PropTypes.object.isRequired,
  templateVariable: PropTypes.object.isRequired,
}
