import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import { DisablingButton } from '../disabling_button.js.jsx'

export default class SectionFormBriefLinkGenerator extends Component {
  static propTypes = {
    campaign: PropTypes.object,
    editable: PropTypes.bool.isRequired,
    handleSectionContent: PropTypes.func.isRequired,
    section: PropTypes.object.isRequired,
    sectionHelper: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
  }

  render() {
    let { campaign, handleSectionContent, section, sectionHelper } = this.props
    let invalidToGenerateBriefLink =
      _.isEmpty(campaign.name) || _.isEmpty(campaign.date) || _.isEmpty(section.content['Headline'])
    return (
      <div id={`${section.id}-brief-link-generator-form-control-container`}>
        <FormControl className="form-control-generate-brieflink">
          <GenerateBriefLinkButton
            campaign={campaign}
            className="generate-brieflink"
            disabled={invalidToGenerateBriefLink}
            handleSectionContent={handleSectionContent}
            section={section}
            sectionHelper={sectionHelper}
          />
        </FormControl>
      </div>
    )
  }
}

class GenerateBriefLinkButton extends Component {
  static propTypes = {
    campaign: PropTypes.object,
    className: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    handleSectionContent: PropTypes.func.isRequired,
    section: PropTypes.object.isRequired,
    sectionHelper: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
  }

  generateBriefLink = () => {
    const { campaign, handleSectionContent, section, sectionHelper } = this.props
    let slugName = section.content['Headline'].toLowerCase().replace(/[^A-Za-z0-9]/g, '-')

    // remove any trailing dashes in the brief_slug
    while (slugName.charAt(slugName.length - 1) == '-') {
      slugName = slugName.substr(0, slugName.length - 1)
    }
    // replace any repeated dashes with single dash in the brief_slug
    const regex = /-+/g
    slugName = slugName.replace(regex, '-')

    let generatedBriefLink = `https://thehustle.co/news/${slugName}?utm_campaign=${campaign.name.replace(
      /\s/g,
      '+',
    )}&amp;utm_content=${campaign.date}-${slugName}&amp;utm_medium=email&amp;utm_source=daily`
    return new Promise((resolve, reject) => {
      this.props.sectionHelper.updateQueue
        .flush()
        .then(() => {
          handleSectionContent(section, { name: 'briefLink' }, generatedBriefLink)
          resolve()
        })
        .catch(error => {
          console.error('failed to generate briefLink', error)
          reject(error)
        })
    })
  }

  render() {
    const { disabled } = this.props
    return (
      <div>
        <DisablingButton
          disabled={disabled}
          buttonAction={this.generateBriefLink}
          buttonText={'Generate HubSpot briefLink'}
        />
      </div>
    )
  }
}
