export default class ArticlesSectionAPI {
  static jsonHeaders() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    }
  }

  static create(data) {
    let body = {
      articles_section: {
        section_id: data.sectionId,
        article_id: data.articleId,
        ordering: data.ordering,
      },
    }
    let options = {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(body),
      headers: this.jsonHeaders(),
    }
    let url = `/sections/${data.sectionId}/articles/${data.articleId}.json`
    let request = new Request(url, options)
    return new Promise((resolve, reject) => {
      fetch(request)
        .then(response => {
          response
            .json()
            .then(resolve)
            .catch(error => {
              console.error(
                `Failed to create articles_section, section_id:
                    ${data.sectionId} article_id: ${data.articleId}.`,
              )
              reject(error)
            })
        })
        .catch(error => reject(error))
    })
  }

  // This replicates an articles_section with a replicant of its section
  static replicate(articleId, sectionId) {
    return new Promise((resolve, reject) => {
      let body = { article_id: articleId, section_id: sectionId }
      let options = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = `/sections/${sectionId}/articles/${articleId}/replicate.json`
      let request = new Request(url, options)
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => {
              if (!jsonData['success']) {
                reject(jsonData)
                return
              } else {
                resolve(jsonData)
              }
            })
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  }

  // This updates the specified ArticlesSection with new ArticlesSection ordering
  static patch(data) {
    return new Promise((resolve, reject) => {
      let body = {
        articles_section: {
          section_id: data.sectionId,
          article_id: data.articleId,
          ordering: data.ordering,
        },
      }
      let options = {
        credentials: 'same-origin',
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = `/sections/${data.sectionId}/articles/${data.articleId}.json`
      let request = new Request(url, options)
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => {
              console.error(
                `Failed to update articles_section, section_id:
                    ${data.sectionId} article_id: ${data.articleId}.`,
              )
              reject(error)
            })
        })
        .catch(error => {
          console.error(
            `Failed to update articles_section, section_id:
                    ${data.sectionId} article_id: ${data.articleId}.`,
          )
          reject(error)
        })
    })
  }

  static del(sectionId, articleId) {
    return new Promise((resolve, reject) => {
      let options = {
        credentials: 'same-origin',
        method: 'DELETE',
      }
      let url = `/sections/${sectionId}/articles/${articleId}.json`
      let request = new Request(url, options)
      return fetch(request)
        .then(resolve)
        .catch(error => {
          console.error(
            `Failed to delete articles_section, section_id:
            ${articlesSectionId} article_id: ${articleId}.`,
          )
          reject(error)
        })
    })
  }
}
