class EmailsSectionAPI {
  static jsonHeaders() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    }
  }

  static upsert(sectionId, emailId, ordering) {
    var body = {
      emails_section: {
        section_id: sectionId,
        email_id: emailId,
        ordering: ordering,
      },
    }
    var options = {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(body),
      headers: this.jsonHeaders(),
    }
    var url = '/sections/' + sectionId + '/emails/' + emailId + '.json'
    var request = new Request(url, options)
    return new Promise(function(resolve, reject) {
      fetch(request).then(function(response) {
        response
          .json()
          .then(resolve)
          .catch(function(error) {
            console.log(
              'Failed to upsert emails_section, section_id: ' + sectionId + ' email_id: ' + emailId + '. ' + error,
            )
            reject(error)
          })
      })
    })
  }

  static update(sectionId, emailId, ordering) {
    var body = {
      emails_section: {
        section_id: sectionId,
        email_id: emailId,
        ordering: ordering,
      },
    }
    var options = {
      credentials: 'same-origin',
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: this.jsonHeaders(),
    }
    var url = '/sections/' + sectionId + '/emails/' + emailId + '.json'
    var request = new Request(url, options)
    return new Promise(function(resolve, reject) {
      fetch(request).then(function(response) {
        response
          .json()
          .then(resolve)
          .catch(function(error) {
            console.log(
              'Failed to upsert emails_section, section_id: ' + sectionId + ' email_id: ' + emailId + '. ' + error,
            )
            reject(error)
          })
      })
    })
  }

  static create(sectionId, emailId, ordering) {
    var body = {
      emails_section: {
        section_id: sectionId,
        email_id: emailId,
        ordering: ordering,
      },
    }
    var options = {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(body),
      headers: this.jsonHeaders(),
    }
    var url = '/sections/' + sectionId + '/emails/' + emailId + '.json'
    var request = new Request(url, options)
    return new Promise(function(resolve, reject) {
      fetch(request).then(function(response) {
        response
          .json()
          .then(resolve)
          .catch(function(error) {
            console.log(
              'Failed to create emails_section, section_id: ' + sectionId + ' email_id: ' + emailId + '. ' + error,
            )
            reject(error)
          })
      })
    })
  }

  static del(sectionId, emailId) {
    var options = {
      credentials: 'same-origin',
      method: 'DELETE',
    }
    var url = '/sections/' + sectionId + '/emails/' + emailId + '.json'
    var request = new Request(url, options)

    return fetch(request).catch(function(error) {
      console.log('Failed to delete emails_section, section_id: ' + sectionId + ' email_id: ' + emailId + '. ' + error)
    })
  }
}

export { EmailsSectionAPI }
