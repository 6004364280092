import BaseAPI from './base_api.js'

export default class PagesSectionAPI extends BaseAPI {
  resourceName = params => 'pages_section'
  baseUrl = params => '/pages_sections'
  createUrl = params => `/sections/${params.sectionId}/pages/${params.pageId}.json`
  updateUrl = params => this.createUrl(params)

  destroy = (sectionId, pageId) => {
    var options = {
      credentials: 'same-origin',
      method: 'DELETE',
    }
    var url = '/sections/' + sectionId + '/pages/' + pageId + '.json'
    var request = new Request(url, options)

    return fetch(request).catch(function(error) {
      console.log('Failed to delete pages_section, section_id: ' + sectionId + ' page_id: ' + pageId + '. ' + error)
    })
  }
}
