import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Moment from 'moment'
import UsersHelper from '../helpers/users_helper.js.jsx'

const navBarTheme = createMuiTheme({
  overrides: {
    MuiAppBar: {
      root: {
        boxSizing: 'none',
      },
    },
    MuiButton: {
      label: {
        color: '#aaaaaa',
        fontSize: '16px',
        fontWeight: '600',
        fontFamily: "'Arial', sans-serif",
        textTransform: 'none',
        '&:hover': {
          color: '#ffffff',
        },
      },
    },
  },
})

const styles = {
  root: {
    color: '#ffffff',
    backgroundColor: '#000000',
    height: 60,
    flexGrow: 1,
    position: 'fixed',
    zIndex: '10000',
  },
  grow: {
    flexGrow: 1,
  },
  logo: {
    padding: '0px 10px',
  },
  allButtons: {
    width: '100%',
    minWidth: '825px',
  },
  navButtons: {
    margin: '0px 30px auto',
    top: '6px',
    position: 'relative',
  },
  account: {
    float: 'right',
  },
  menu: {
    top: '50px',
  },
}

class NavBar extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      anchorEl: null,
    }
    this.usersHelper = new UsersHelper()
  }

  componentDidMount() {
    this.usersHelper.getCurrentUser().then(user => {
      this.setState({ user })
    })
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handleSignOut = () => {
    window.location.href = '/users/sign_out'
  }

  checkUrlToMarkAsActive = urlToCheck => {
    if (window.location.pathname.includes(urlToCheck)) {
      return 'active'
    }
  }

  renderTitleButtonsOrClientName = () => {
    const { classes, cpName } = this.props
    const { anchorEl, user } = this.state
    const open = Boolean(anchorEl)

    if (!user) {
      return <div id="client-name">{cpName}</div>
    }
    return (
      <div className={classes.allButtons}>
        <span className={classes.navButtons}>
          <Button href="/articles" className={this.checkUrlToMarkAsActive('/articles')}>
            Articles
          </Button>
          <Button href="/campaigns" className={this.checkUrlToMarkAsActive('/campaigns')}>
            Campaigns
          </Button>
          <Button href="/pages" className={this.checkUrlToMarkAsActive('/pages')}>
            Pages
          </Button>
          <Button href="/email_send_jobs" className={this.checkUrlToMarkAsActive('/email_send_jobs')}>
            Drips
          </Button>
          <Button href="/contacts" className={this.checkUrlToMarkAsActive('/contacts')}>
            Contacts
          </Button>
          <Button href="/clients" className={this.checkUrlToMarkAsActive('/clients')}>
            Clients
          </Button>
          <Button href="/section_templates" className={this.checkUrlToMarkAsActive('/section_templates')}>
            Templates
          </Button>
          <Button href="/segments" className={this.checkUrlToMarkAsActive('/segments')}>
            Segments
          </Button>
          <Button href="/config_values" className={this.checkUrlToMarkAsActive('/config_values')}>
            Settings
          </Button>
        </span>
        <IconButton
          className={classes.account}
          aria-owns={open ? 'menu-appbar' : null}
          aria-haspopup="true"
          onClick={this.handleMenu}
          color="inherit">
          <span className="username">{user.email}</span>
          <AccountCircle />
        </IconButton>
        <Menu
          className={classes.menu}
          id="menu-appbar"
          style={{
            inset: '60px 20px 0px 0px',
          }}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={this.handleClose}>
          <MenuItem onClick={this.handleSignOut}>Sign Out</MenuItem>
        </Menu>
      </div>
    )
  }

  render() {
    const { classes } = this.props

    return (
      <MuiThemeProvider theme={navBarTheme}>
        <div id="nav-bar-contain" className={classes.root}>
          <AppBar position="static" color="default" className={classes.root}>
            <Toolbar>
              <div className={classes.logo}>
                <a href="/" alt="The Hustle Logo">
                  <img
                    src="https://thdaily.s3-us-west-1.amazonaws.com/thehustle_logo_white_20200529182349.png"
                    width="160px;"
                  />
                </a>
              </div>
              {this.renderTitleButtonsOrClientName()}
            </Toolbar>
          </AppBar>
        </div>
      </MuiThemeProvider>
    )
  }
}

export default withStyles(styles)(NavBar)
