import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/core/styles'
import { styles, campaignBuilderTheme } from '../styles/campaign_builder_styles.js.jsx'
import { SectionHelper } from '../helpers/section_helper.js.jsx'
import FormHelper from '../helpers/form_helper.js.jsx'

export default class SectionTemplateBuilderPreview extends Component {
  static propTypes = {
    builderType: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    handleSectionContent: PropTypes.func.isRequired,
    previewUpdated: PropTypes.func.isRequired,
    renderEmptyShrugDiv: PropTypes.func.isRequired,
    section: PropTypes.object.isRequired,
    updateSectionTemplateVariable: PropTypes.func.isRequired,
    uploadImage: PropTypes.func.isRequired,
    variables: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {}
    this.sectionHelper = new SectionHelper()
  }

  handleUploadImage = (name, sectionId, templateVariableId) => event => {
    const { uploadImage } = this.props
    let file = event.target.files[0]
    if (file) {
      uploadImage(event.target.name, { srcFile: file, urlField: event.target.name })
    }
  }

  addedFunctions = () => {
    return {
      handleUploadImage: this.handleUploadImage,
      handleSectionContent: this.props.handleSectionContent,
      renderEmptyShrugDiv: this.props.renderEmptyShrugDiv,
    }
  }

  renderTemplateVariables = () => {
    const { builderType, classes, variables } = this.props
    return variables.map(templateVariable => {
      // Adding an empty object {} for the sectionHelper that isn't required for functionality but
      // is required for this component building FormHelper function
      return FormHelper.renderVariableByType(
        this.props.section,
        templateVariable,
        true,
        classes,
        this.addedFunctions,
        {},
        builderType,
        {},
      )
    })
  }

  render() {
    const { renderEmptyShrugDiv, variables } = this.props

    return (
      <MuiThemeProvider theme={campaignBuilderTheme}>
        <div id="builder-preview">
          <div id="header">Builder Preview</div>
          {variables.length < 1 && renderEmptyShrugDiv()}
          <div id="preview-viewer">{this.renderTemplateVariables()}</div>
        </div>
      </MuiThemeProvider>
    )
  }
}
