import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import NativeSelect from '@material-ui/core/NativeSelect'
import _ from 'lodash'

const SectionFormAuthorNameDropdown = props => {
  let varName = props.templateVariable.name
  let varValue = props.section.content[varName]
  let optionSelections = (
    <>
      <option value=""></option>
      <option value="Ben Berkley">Ben Berkley</option>
      <option value="Brad Wolverton">Brad Wolverton</option>
      <option value="Jacob Cohen">Jacob Cohen</option>
      <option value="Juliet Bennett Rylah">Juliet Bennett Rylah</option>
      <option value="Olivia Deng">Olivia Deng</option>
      <option value="Mia Sullivan">Mia Sullivan</option>
      <option value="Mark Dent">Mark Dent</option>
      <option value="Rob Litterst">Rob Litterst</option>
      <option value="Samir Javer">Samir Javer</option>
      <option value="Sara Friedman">Sara Friedman</option>
      <option value="Zachary Crockett">Zachary Crockett</option>
    </>
  )

  return (
    <FormControl className={props.classes.formControl}>
      <InputLabel htmlFor={`author-name-${props.templateVariable.id}`}>{_.capitalize(varName)}</InputLabel>
      <NativeSelect
        disabled={!props.editable}
        value={varValue}
        onChange={event =>
          props.handleSectionContent(props.section, props.templateVariable, _.get(event, 'target.value'))
        }
        name={varName}
        input={<Input name={varName} />}>
        {optionSelections}
      </NativeSelect>
    </FormControl>
  )
}

export default SectionFormAuthorNameDropdown

SectionFormAuthorNameDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  handleSectionContent: PropTypes.func.isRequired,
  section: PropTypes.object.isRequired,
  templateVariable: PropTypes.object.isRequired,
}
