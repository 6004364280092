import { ConfigValueAPI } from '../apis/config_value_api.js.jsx'
import { BatchFunctionCaller } from '../utils.js.jsx'

class ConfigValueHelper {
  constructor() {
    this.updateQueue = new BatchFunctionCaller(this.bulkUpdateConfigValues, 3000)
    this.cvApi = new ConfigValueAPI()
  }

  bulkUpdateConfigValues = configValueDictionary => {
    let promiseArray = []
    for (let configValueId in configValueDictionary) {
      this.cvApi.update(configValueDictionary[configValueId])
    }
    return Promise.all(promiseArray)
  }

  queueUpdate = (configValueId, key, newValue) => {
    let queueObject = this.updateQueue.getQueueItem(configValueId) || { id: configValueId }
    if (this.columnAttributes().includes(key)) {
      queueObject[key] = newValue
    } else {
      if (queueObject['value'] == undefined) {
        queueObject['value'] = {}
      }
      queueObject['value'][key] = newValue
    }
    this.updateQueue.queue(configValueId, queueObject)
  }

  index = params => {
    return this.cvApi.index(params || {})
  }

  columnAttributes = () => {
    return ['name']
  }

  blank = () => {
    return {
      name: '',
      value: { value: '' },
    }
  }
}

export { ConfigValueHelper }
