import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Editor } from '@tinymce/tinymce-react'
import FormControl from '@material-ui/core/FormControl'
import FormHelper from '../../helpers/form_helper.js.jsx'
import _ from 'lodash'

export default class SectionFormPowerEditor extends Component {
  static propTypes = {
    builderType: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    editable: PropTypes.bool.isRequired,
    handleSectionContent: PropTypes.func.isRequired,
    handleUploadImage: PropTypes.func.isRequired,
    section: PropTypes.object.isRequired,
    templateVariable: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = { content: '' }
  }

  componentDidMount = () => {
    const { section, templateVariable } = this.props
    this.setState({ content: section.content[templateVariable.name] })
  }

  handleEditorChange = (content, editor) => {
    const { section, handleSectionContent, templateVariable } = this.props
    this.setState({ content })
    handleSectionContent(section, templateVariable, content)
  }

  campaignBuilderTypeConfigs = () => {
    return {
      setup: editor => {
        editor.on('init', () => {
          let content = editor.getContent()
          let wrappedContent = content.includes(`<div class="mce-email-content">`)
            ? content
            : `<div class="mce-email-content">${content}</div>`
          editor.setContent(wrappedContent)
        })
      },
      init_instance_callback: editor => {
        editor.on('change', e => {
          let content = editor.getContent()
          let wrappedContent = content.includes(`<div class="mce-email-content">`)
            ? content
            : `<div class="mce-email-content">${content}</div>`
          editor.setContent(wrappedContent)
        })
      },
    }
  }

  articleBuilderTypeConfigs = () => {
    return {}
  }

  pageBuilderTypeConfigs = () => {
    return this.campaignBuilderTypeConfigs()
  }

  sectionTemplatesBuilderTypeConfigs = () => {
    return {}
  }

  sharedConfigs = () => {
    const { handleUploadImage, section, templateVariable } = this.props
    return {
      branding: false,
      font_formats: 'Helvetica, arial, sans-serif;',
      fontsize_formats: '8px 10px 12px 14px 18px 24px 36px',
      height: 800,
      image_advtab: true,
      images_upload_handler: (blobInfo, success, failure) => {
        let namedBlob

        if (_.isEmpty(blobInfo.blob().name)) {
          namedBlob = Object.assign(blobInfo.blob(), { name: blobInfo.filename() })
        } else {
          namedBlob = blobInfo.blob()
        }

        let fakeEvent = {
          target: {
            name: templateVariable.name,
            files: [namedBlob],
            tiny_mce_image: true,
          },
        }

        handleUploadImage(
          templateVariable.name,
          section.id,
          templateVariable.id,
        )(fakeEvent)
          .then(response => success(response['location']))
          .catch(e => failure(console.error('Failed to upload image for tinyMCE editor', e)))
      },
      plugins: `preview powerpaste searchreplace autolink directionality
                advcode visualblocks visualchars fullscreen image link media mediaembed
                template codesample table charmap pagebreak nonbreaking anchor
                insertdatetime advlist lists wordcount tinymcespellchecker a11ychecker
                help formatpainter pageembed linkchecker`,
      powerpaste_googledocs_import: 'clean',
      powerpaste_html_import: 'clean',
      powerpaste_word_import: 'clean',
      toolbar: `formatselect | bold italic strikethrough forecolor backcolor
                formatpainter | code | link image media pageembed | alignleft aligncenter
                alignright alignjustify | numlist bullist outdent indent | removeformat`,
    }
  }

  builderTypeConfigs = () => {
    const { builderType } = this.props

    const builderTypes = {
      article: this.articleBuilderTypeConfigs,
      campaign: this.campaignBuilderTypeConfigs,
      page: this.pageBuilderTypeConfigs,
      sectionTemplate: this.sectionTemplatesBuilderTypeConfigs,
    }

    return builderTypes[builderType]() || {}
  }

  configs = () => Object.assign(this.sharedConfigs(), this.builderTypeConfigs())

  render() {
    const { editable, section, templateVariable } = this.props
    const { content } = this.state

    return (
      <FormControl className={`power-editor-form-control`}>
        <label className="form-control-pe">{_.capitalize(templateVariable.name)}</label>
        <Editor
          apiKey="o5ya9woneiutzc8z8ocl70xkompseo1d7mc2feuakw393034"
          disabled={!editable}
          name={templateVariable.name}
          init={this.configs()}
          onEditorChange={this.handleEditorChange}
          value={content}
        />
      </FormControl>
    )
  }
}
