export default class ArticleAPI {
  static jsonHeaders() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    }
  }

  // This updates the specified article with new article data
  static patch(articleId, data) {
    return new Promise((resolve, reject) => {
      let body = { article: data }
      let options = {
        credentials: 'same-origin',
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = '/articles/' + articleId + '.json'
      let request = new Request(url, options)
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => {
              if (jsonData['status'] >= 400) {
                reject(jsonData)
              } else {
                resolve(jsonData)
              }
            })
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  }

  // This replicates an article with new copies of its sections and articles_sections
  static replicate(articleId) {
    return new Promise((resolve, reject) => {
      let body = { id: articleId }
      let options = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = '/articles/' + articleId + '/replicate.json'
      let request = new Request(url, options)
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => {
              if (!jsonData['success']) {
                reject(jsonData)
                return
              } else {
                resolve(jsonData)
              }
            })
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  }

  static show(articleId) {
    return new Promise((resolve, reject) => {
      let url = '/articles/' + articleId + '.json'
      let request = new Request(url, { credentials: 'same-origin' })
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => {
              if (jsonData['status'] >= 400) {
                reject(jsonData)
              } else {
                resolve(jsonData)
              }
            })
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  }

  static articlesSections = articleId => {
    return new Promise((resolve, reject) => {
      let url = `/articles/${articleId}/articles_sections.json`
      let request = new Request(url, { credentials: 'same-origin' })
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  }

  static create(data) {
    return new Promise((resolve, reject) => {
      let body = { article: data }
      let options = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = '/articles.json'
      let request = new Request(url, options)
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  }

  static del(articleId) {
    return new Promise((resolve, reject) => {
      let options = {
        credentials: 'same-origin',
        method: 'DELETE',
      }
      let url = `/articles/${articleId}.json`
      let request = new Request(url, options)
      return fetch(request)
        .then(resolve)
        .catch(error => {
          console.error(`Failed to delete article, article_id: ${articleId}.`)
          reject(error)
        })
    })
  }

  // Returns a promise that will yield an array of article json data.
  static index() {
    return new Promise((resolve, reject) => {
      let url = '/articles.json'
      let request = new Request(url, { credentials: 'same-origin' })
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  }

  static deliver(articleId) {
    return new Promise((resolve, reject) => {
      let body = {}
      let options = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = '/articles/' + articleId + '/deliver.json'
      let request = new Request(url, options)
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  }
}
