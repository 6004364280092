import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CampaignAPI } from '../apis/campaign_api.js.jsx'
import Button from '@material-ui/core/Button'
import { CampaignHelper } from '../helpers/campaign_helper.js.jsx'
import { DateUtils } from '../utils.js.jsx'
import Moment from 'moment'

export default class CampaignBuilderSendToSailthruButton extends Component {
  static propTypes = {
    aBTestCampaignHelper: PropTypes.object.isRequired,
    campaign: PropTypes.object.isRequired,
    handleCloseSendToSailthruDialog: PropTypes.func,
    refreshEmails: PropTypes.func.isRequired,
    propertyPresentInCampaign: PropTypes.func.isRequired,
    scheduledSailThruDate: PropTypes.string,
    scheduledSailThruHour: PropTypes.string,
    scheduledSailThruMinute: PropTypes.string,
    scheduling: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    this.state = {
      error: false,
      processing: false,
    }

    this.campaignHelper = new CampaignHelper()
  }

  displayText = () => {
    const { propertyPresentInCampaign } = this.props
    if (propertyPresentInCampaign('sent_at')) {
      return 'Campaign has been sent'
    }
    if (this.state.error) {
      return 'ERROR'
    }
    if (this.state.processing) {
      return 'Sending'
    } else {
      return 'Send to Sailthru'
    }
  }

  deliver = () => {
    const {
      aBTestCampaignHelper,
      campaign,
      refreshEmails,
      handleCloseSendToSailthruDialog,
      scheduledSailThruDate,
      scheduledSailThruHour,
      scheduledSailThruMinute,
    } = this.props
    let time = new Moment(
      scheduledSailThruDate + 'T' + (scheduledSailThruHour || 8) + ':' + (scheduledSailThruMinute || 30),
    ).toISOString()
    if (this.state.processing) {
      return
    }
    this.setState({ processing: true })
    if (campaign.ab_test_campaign_id != null) {
      aBTestCampaignHelper
        .patchSailthruAbTestCampaign(campaign.ab_test_campaign_id)
        .then(() => refreshEmails())
        .then(result => {
          this.setState({ processing: false }, () => {
            handleCloseSendToSailthruDialog()
          })
        })
        .catch(error => {
          console.error(error)
          this.setState({ processing: false, error: true })
        })
    } else {
      this.campaignHelper
        .sendToSailthru(campaign.id, time)
        .then(() => refreshEmails())
        .then(result => {
          this.setState({ processing: false }, () => {
            handleCloseSendToSailthruDialog()
          })
        })
        .catch(error => {
          console.error(error)
          this.setState({ processing: false, error: true })
        })
    }
  }

  render() {
    const { scheduling, scheduledSailThruDate, scheduledSailThruHour, scheduledSailThruMinute } = this.props

    let readyToSend =
      scheduledSailThruDate.length > 4 && scheduledSailThruHour.length > 0 && scheduledSailThruMinute > 0

    return (
      <div>
        <Button
          id="deliver-button"
          color="secondary"
          disabled={this.state.processing || !readyToSend}
          variant="contained"
          onClick={!scheduling && this.deliver}>
          {this.displayText()}
        </Button>
      </div>
    )
  }
}
