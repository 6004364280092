import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputLabel,
  NativeSelect,
  Paper,
  TextField,
} from '@material-ui/core'
import CrontabGenerator from './crontab_generator.js.jsx'
import Moment from 'moment'

export default class DripCampaignBuilderConfigure extends Component {
  static propTypes = {
    dripCampaign: PropTypes.object.isRequired,
    dripCampaignHelper: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    editable: PropTypes.bool.isRequired,
    show: PropTypes.bool.isRequired,
    updateDripCampaign: PropTypes.func.isRequired,
    updateDripCampaignName: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      name: '',
      nameUpdating: false,
      nameUpdateError: false,
    }
  }

  handleRenameName = event => {
    this.setState({ name: event.target.value, nameUpdateError: false })
  }

  handleUpdateDripCampaignName = () => {
    this.setState({ nameUpdating: true, name: this.state.name.trim() })
    this.props
      .updateDripCampaignName(this.state.name.trim())
      .then(result => {
        this.setState({ nameUpdating: false, nameUpdateError: false })
      })
      .catch(error => {
        this.setState({ nameUpdating: false, nameUpdateError: true })
      })
  }

  deleteDripCampaign = dripCampaign => {
    const { dripCampaignHelper, editable } = this.props

    if (!editable) {
      console.log('Drip Campaign is not editable')
      return
    }
    if (window.confirm(`Really delete the ${dripCampaign.name} Drip Campaign?`)) {
      dripCampaignHelper.deleteDripCampaign(dripCampaign)
      document.location.href = '/email_send_jobs'
    }
  }

  renderUpdateNameText = () => {
    const { nameUpdateError } = this.state
    let text

    if (this.state.nameUpdating) {
      text = 'Name Updating...'
    } else if (!nameUpdateError) {
      text = 'Update Name'
    } else {
      text = 'Error updating name. Try again with a unique name.'
    }

    return text
  }

  renderDeleteDripCampaignButton = dripCampaign => {
    const { editable } = this.props

    return (
      <Button
        disabled={!editable}
        className="delete-button"
        onClick={() => {
          this.deleteDripCampaign(dripCampaign)
        }}
        children="Delete DripCampaign"
        color="secondary"
        variant="contained"
      />
    )
  }

  render() {
    const { dripCampaign, dripCampaignHelper, classes, editable, show, updateDripCampaign } = this.props
    const { name, nameUpdateError } = this.state
    if (!show) {
      return null
    }

    return (
      <div id="drip-campaign-configure-container">
        <FormControl className={classes.formControl} key={`${dripCampaign.id}-form-select-id`}>
          <TextField
            disabled
            key={`${dripCampaign.id}-configure-id`}
            type="text"
            name="id"
            margin="normal"
            className="drip-campaign-configure-id"
            label="Drip Campaign ID"
            onChange={updateDripCampaign}
            value={dripCampaign.id}
          />
        </FormControl>
        <FormControl className="csv-url">
          <TextField
            disabled={!editable}
            id="csv-url"
            label=".csv URL"
            type="text"
            onChange={updateDripCampaign}
            value={dripCampaign.csv_url || ''}
            className={classes.textField}
            name="csv_url"
          />
        </FormControl>
        <FormControl className={classes.formControl} key={`${dripCampaign.id}-form-name`}>
          <TextField
            disabled={!editable}
            key={`${dripCampaign.id}-configure-name`}
            type="text"
            name="name"
            margin="normal"
            className="drip-campaign-configure-name"
            label="Name"
            onChange={this.handleRenameName}
            value={name.length > 0 ? name : _.get(dripCampaign, ['email_envelope', 'name'])}
          />
          <Button
            disabled={!dripCampaignHelper.validateName(dripCampaign.name, name)}
            color={nameUpdateError ? 'secondary' : 'primary'}
            variant="contained"
            onClick={this.handleUpdateDripCampaignName}
            children={this.renderUpdateNameText()}
          />
        </FormControl>
        <FormControl className="crontab-schedule-form">
          <InputLabel>Crontab Schedule</InputLabel>
          <CrontabGenerator
            id="crontab-schedule"
            editable={editable}
            updateDripCampaign={updateDripCampaign}
            value={dripCampaign.crontab_schedule}
          />
        </FormControl>
        <FormControl className="delete-drip-campaign-form">
          {this.renderDeleteDripCampaignButton(dripCampaign)}
        </FormControl>
      </div>
    )
  }
}
