class CampaignAPI {
  static jsonHeaders() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    }
  }

  // This updates the specified campaign with new campaign data
  static patch(campaignId, data) {
    return new Promise((resolve, reject) => {
      let body = { campaign: data }
      let options = {
        credentials: 'same-origin',
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = '/campaigns/' + campaignId + '.json'
      let request = new Request(url, options)
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  }

  static show(campaignId) {
    return new Promise((resolve, reject) => {
      let url = '/campaigns/' + campaignId + '.json'
      let request = new Request(url, { credentials: 'same-origin' })
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  }

  static create(data) {
    return new Promise((resolve, reject) => {
      let body = { campaign: data }
      let options = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = '/campaigns.json'
      let request = new Request(url, options)
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  }

  // Returns a promise that will yield an array of campaign json data.
  // If params :include_reports is true and :ids is a list of campaign ids,
  // this will yield extra reporting data on each campaign specified,
  // else it will return all campaigns without special reports data.
  static index(params) {
    let paramsStr = Object.keys(params)
      .map(key => {
        return key + '=' + encodeURIComponent(params[key])
      })
      .join('&')
    return new Promise((resolve, reject) => {
      let url = '/campaigns.json?' + paramsStr
      let request = new Request(url, { credentials: 'same-origin' })
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  }

  static testSend(campaignId, emailId, testSendEmails) {
    return new Promise((resolve, reject) => {
      let body = { email_id: emailId, emails: testSendEmails }
      let options = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = '/campaigns/' + campaignId + '/test_send.json'
      let request = new Request(url, options)
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => {
              console.error(`Failed to test send campaign id: ${campaignId} to email id: ${emailId}, error: ${error}`)
              reject(error)
            })
        })
        .catch(error => {
          console.error(`Failed to test send campaign id: ${campaignId} to email id: ${emailId}, error: ${error}`)
          reject(error)
        })
    })
  }

  static deliver(campaignId) {
    return new Promise((resolve, reject) => {
      let body = {}
      let options = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = '/campaigns/' + campaignId + '/deliver.json'
      let request = new Request(url, options)
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  }

  static associateAds(campaignId) {
    let options = {
      credentials: 'same-origin',
      method: 'POST',
      headers: this.jsonHeaders(),
    }
    return new Promise((resolve, reject) => {
      let url = `/campaigns/${campaignId}/associate_ads.json`
      let request = new Request(url, options)
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  }

  static sendToSailthru(campaignId, scheduledIsoTime) {
    let body = { time: scheduledIsoTime }
    let options = {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(body),
      headers: this.jsonHeaders(),
    }
    return new Promise((resolve, reject) => {
      let url = '/campaigns/' + campaignId + '/send_to_sailthru.json'
      let request = new Request(url, options)
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => {
              console.error('Failed to schedule campaign ID: ' + campaignId, error)
              reject(error)
            })
        })
        .catch(error => reject(error))
    })
  }

  // This updates the specified campaign on Sailthru with new campaign data
  static patchSailthruCampaigns(campaignId) {
    let body = { id: campaignId }
    let options = {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(body),
      headers: this.jsonHeaders(),
    }
    return new Promise((resolve, reject) => {
      let url = '/campaigns/' + campaignId + '/patch_sailthru_campaigns.json'
      let request = new Request(url, options)
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => {
              console.error('Failed to patch campaign ID: ' + campaignId, error)
              reject(error)
            })
        })
        .catch(error => reject(error))
    })
  }

  static schedule(campaignId, isotime) {
    let body = { time: isotime }
    let options = {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(body),
      headers: this.jsonHeaders(),
    }
    return new Promise((resolve, reject) => {
      let url = '/campaigns/' + campaignId + '/schedule.json'
      let request = new Request(url, options)
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => {
              console.error('Failed to schedule campaign ID: ' + campaignId, error)
              reject(error)
            })
        })
        .catch(error => reject(error))
    })
  }

  static unschedule(campaignId) {
    let options = {
      credentials: 'same-origin',
      method: 'POST',
      headers: this.jsonHeaders(),
    }
    return new Promise((resolve, reject) => {
      let url = '/campaigns/' + campaignId + '/unschedule.json'
      let request = new Request(url, options)
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => {
              console.error('Failed to unschedule campaign ID: ' + campaignId, error)
              reject(error)
            })
        })
        .catch(error => reject(error))
    })
  }

  // Returns a promise that will yield campaign reports json data
  // for a specified campaign.
  // Includes CampaignReport, EmailReports, EmailSectionReports, and EmailSectionUrls.
  static reports(campaignId) {
    return new Promise((resolve, reject) => {
      let url = `/campaigns/${campaignId}/report.json`
      let request = new Request(url, { credentials: 'same-origin' })
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  }

  static getEstimatedEmailSizes = campaignId => {
    return new Promise((resolve, reject) => {
      let url = '/campaigns/' + campaignId + '/estimate_email_sizes.json'
      let request = new Request(url, { credentials: 'same-origin' })
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  }
}

export { CampaignAPI }
