import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  Button,
  Checkbox,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import AceEditor from 'react-ace'
import 'brace/mode/html'
import 'brace/theme/monokai'
import 'brace/snippets/html'
import 'brace/ext/language_tools'
import SectionTemplateVariableBoard from './section_template_variable_board.js.jsx'

export default class SectionTemplateBuilderEditor extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    createSectionTemplateVariable: PropTypes.func.isRequired,
    deleteVariable: PropTypes.func.isRequired,
    previewDirty: PropTypes.bool.isRequired,
    previewUpdated: PropTypes.func.isRequired,
    sectionTemplate: PropTypes.object.isRequired,
    sectionTemplateHelper: PropTypes.object.isRequired,
    updateSectionTemplate: PropTypes.func.isRequired,
    updateSectionTemplateVariable: PropTypes.func.isRequired,
    variables: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      expandedPanels: [],
      openTooltip: false,
    }
  }

  updateTemplate = event => {
    this.props.updateSectionTemplate(event.currentTarget.name)(event.currentTarget.value)
  }

  updateTemplateHtml = html => {
    this.props.updateSectionTemplate('template')(html)
  }

  updateCheckbox = event => {
    this.props.updateSectionTemplate(event.target.name)(event.target.checked)
  }

  setExpandedPanel = number => {
    this.setState(prevState => {
      let toUpdate = prevState.expandedPanels
      let toRemoveIndex = toUpdate.indexOf(number)
      if (toRemoveIndex > -1) {
        toUpdate.splice(toRemoveIndex, 1)
      } else {
        toUpdate.push(number)
      }
      return { expandedPanels: toUpdate }
    })
  }

  toggleTooltip = () => {
    if (!this.props.sectionTemplate.published) {
      this.setState({ openTooltip: !this.state.openTooltip })
    }
  }

  createNewVersionAndEdit = () => {
    const { sectionTemplate, sectionTemplateHelper } = this.props
    sectionTemplateHelper
      .create({ version_name: sectionTemplate.version_name, division_name: sectionTemplate.division_name })
      .then(newSectionTemplate => {
        window.location.href = `/section_templates/${newSectionTemplate.id}/build`
      })
  }

  render() {
    const {
      classes,
      createSectionTemplateVariable,
      deleteVariable,
      editable,
      renderEmptyShrugDiv,
      sectionTemplate,
      updateSectionTemplateVariable,
      updateSectionTemplateVariableOrderings,
      variables,
    } = this.props
    // Disable error messaging in the ACE editor gutter, since we use the editor
    //   for fragments which are invalid as standalone HTML.
    let editor = ace.edit(document.getElementById('template'))
    editor.getSession().setUseWorker(false)
    editor.getSession().setUseWrapMode(true)

    return (
      <div id="builder-editor">
        <div id="header-fields-and-actions">
          <TextField
            disabled={!editable}
            key={`form-control-version-name`}
            label="Template Name"
            value={sectionTemplate.version_name}
            name="version_name"
            id="version-name"
          />
          <label id="label-version" key={`label-version-${sectionTemplate.id}`}>
            Version
          </label>
          <span id="version">{sectionTemplate.version}</span>
          <label id="label-division" key={`label-division-${sectionTemplate.id}`}>
            Division
          </label>
          <span id="division">{sectionTemplate.division_name}</span>
          <label id="label-published" key={`label-published-${sectionTemplate.id}`}>
            Published
          </label>
          <Checkbox
            key={`checkbox-published-${sectionTemplate.published}`}
            disabled
            checked={sectionTemplate.published}
            onChange={this.updateCheckbox}
            value=""
            name="published"
            classes={{
              checked: classes.checked,
            }}
          />
          <label id="label-active" key={`label-active-${sectionTemplate.id}`}>
            Active
          </label>

          <Tooltip open={this.state.openTooltip} title="Must be Published to toggle Active." placement="bottom">
            <div id="active-tooltip" onMouseOver={this.toggleTooltip} onMouseOut={this.toggleTooltip}>
              <Checkbox
                key={`checkbox-active-${sectionTemplate.active}`}
                disabled={!sectionTemplate.published}
                checked={sectionTemplate.active}
                onChange={this.updateCheckbox}
                value=""
                name="active"
                classes={{
                  checked: classes.checked,
                }}
              />
            </div>
          </Tooltip>
          <div id="header-actions">
            <Button
              key="button-publish"
              id={!editable ? 'button-published' : 'button-publish'}
              name="published"
              value="true"
              variant="contained"
              onClick={!editable ? this.createNewVersionAndEdit : this.updateTemplate}>
              {!editable ? 'Create New' : 'Publish'}
            </Button>
          </div>
        </div>
        <Accordion expanded={this.state.expandedPanels.includes(0)}>
          <AccordionSummary
            style={{ marginTop: '102px' }}
            onClick={() => {
              this.setExpandedPanel(0)
            }}
            expandIcon={<ExpandMoreIcon />}>
            <label id="section-variables-label">Section Variables</label>
          </AccordionSummary>
          <AccordionDetails id="section-variables-expanded-root">
            <div id="section-variables">
              <SectionTemplateVariableBoard
                classes={classes}
                createSectionTemplateVariable={createSectionTemplateVariable}
                editable={editable}
                deleteVariable={deleteVariable}
                renderEmptyShrugDiv={renderEmptyShrugDiv}
                sectionTemplate={sectionTemplate}
                updateSectionTemplateVariable={updateSectionTemplateVariable}
                updateSectionTemplateVariableOrderings={updateSectionTemplateVariableOrderings}
                variables={variables}
              />
            </div>
            <Button
              disabled={!editable}
              key="button-add-new-variable"
              id={editable ? 'button-add-new-variable-editable' : 'button-add-new-variable-disabled'}
              onClick={createSectionTemplateVariable}>
              {editable ? 'Add New Variable' : 'Not Editable'}
            </Button>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={this.state.expandedPanels.includes(1)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            onClick={() => {
              this.setExpandedPanel(1)
            }}>
            <label id="template-html-label">Template HTML</label>
          </AccordionSummary>
          <AccordionDetails>
            <AceEditor
              editorProps={{
                $blockScrolling: Infinity,
              }}
              mode="html"
              theme="monokai"
              name="template"
              onChange={this.updateTemplateHtml}
              readOnly={!editable}
              fontSize={14}
              showGutter={true}
              style={{ width: '100%' }}
              highlightActiveLine={true}
              value={sectionTemplate.template}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2,
              }}
            />
          </AccordionDetails>
        </Accordion>
      </div>
    )
  }
}
