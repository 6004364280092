import { ContactAPI } from '../apis/contact_api.js.jsx'
import { BatchFunctionCaller } from '../utils.js.jsx'

class ContactHelper {
  constructor() {
    this.updateQueue = new BatchFunctionCaller(this.bulkUpdateContacts, 3000)
  }

  bulkUpdateContacts(contactDictionary) {
    let promiseArray = []
    for (let contactId in contactDictionary) {
      ContactAPI.patch(contactId, contactDictionary[contactId])
    }
    return Promise.all(promiseArray)
  }

  queueUpdate(contactId, key, newValue) {
    let queueObject = this.updateQueue.getQueueItem(contactId) || { id: contactId }
    queueObject[key] = newValue
    this.updateQueue.queue(contactId, queueObject)
  }

  anonymize(email) {
    return ContactAPI.anonymize(email)
  }

  index(params) {
    return ContactAPI.index(params || {})
  }

  show(id) {
    return ContactAPI.show(id)
  }

  extendedData(contactId) {
    return ContactAPI.extendedData(contactId)
  }

  stats(contactId) {
    return ContactAPI.stats(contactId)
  }

  events(contactId) {
    return ContactAPI.events(contactId)
  }

  transferReferrals(contactId, email) {
    return ContactAPI.transferReferrals(contactId, email)
  }

  blank() {
    return {
      email: '',
      referral_url: '',
      signup_source: '',
      date_subscribed: '',
      subscribed: '',
      segment: '',
      weekdays_subscribed: '',
    }
  }
}

export { ContactHelper }
