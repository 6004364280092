import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import NativeSelect from '@material-ui/core/NativeSelect'
import _ from 'lodash'

const SectionFormSelectDropdown = props => {
  let varName = props.templateVariable.name
  let varValue = props.section.content[varName]
  let options = _.get(props.templateVariable, 'options.options')
  let optionSelections =
    options &&
    options.map(option => (
      <option key={option.value} value={option.value}>
        {option.friendly_name}
      </option>
    ))

  return (
    <FormControl className={props.classes.formControl}>
      <InputLabel htmlFor={`select-dropdown-${props.templateVariable.id}`}>{_.capitalize(varName)}</InputLabel>
      <NativeSelect
        disabled={!props.editable}
        value={varValue}
        onChange={event =>
          props.handleSectionContent(props.section, props.templateVariable, _.get(event, 'target.value'))
        }
        name={varName}
        input={<Input name={varName} />}>
        <option value={varValue}>{varValue}</option>
        {optionSelections}
      </NativeSelect>
    </FormControl>
  )
}

export default SectionFormSelectDropdown

SectionFormSelectDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  handleSectionContent: PropTypes.func.isRequired,
  section: PropTypes.object.isRequired,
  templateVariable: PropTypes.object.isRequired,
}
