import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputLabel,
  NativeSelect,
  Paper,
  TextField,
} from '@material-ui/core'
import ArticleHelper from '../helpers/article_helper.js.jsx'
import { SectionHelper } from '../helpers/section_helper.js.jsx'
import swal from 'sweetalert'
import Moment from 'moment'

export default class ArticleBuilderConfigure extends Component {
  static propTypes = {
    article: PropTypes.object.isRequired,
    articleHelper: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    editable: PropTypes.bool.isRequired,
    show: PropTypes.bool.isRequired,
    updateArticle: PropTypes.func.isRequired,
    updateArticleSlug: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      replicating: false,
      replicateError: false,
      slug: '',
      slugUpdating: false,
      slugUpdateError: false,
    }

    this.articleHelper = new ArticleHelper()
  }

  handleRenameSlug = event => {
    this.setState({ slug: event.target.value.trim(), slugUpdateError: false })
  }

  handleUpdateArticleSlug = () => {
    this.setState({ slugUpdating: true })
    this.props
      .updateArticleSlug(this.state.slug)
      .then(result => {
        this.setState({ slugUpdating: false, slugUpdateError: false })
      })
      .catch(error => {
        this.setState({ slugUpdating: false, slugUpdateError: true })
      })
  }

  handleReplicateArticle = () => {
    const { article } = this.props

    let swalConfig = {
      title: 'Are you sure?',
      text: `Do you want to replicate this article, "${article.name}"?`,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }
    swal(swalConfig).then(willReplicate => {
      if (willReplicate) {
        this.setState({ replicating: true })
        this.articleHelper
          .replicateAndGoToNewArticle(article.id)
          .then(result => this.setState({ replicating: false, replicateError: false }))
          .catch(error => this.setState({ replicating: false, replicateError: true }))
        swal('Article replication complete! You are being redirected to the replicant article.', {
          icon: 'success',
        })
      } else {
        swal('Article replication aborted!')
      }
    })
  }

  getListingOptions = () => {
    return ['Unpublished', 'Available by URL Only', 'Published']
  }

  getListingStatus = () => {
    const { article } = this.props

    if (article.published) {
      return 'Published'
    } else if (article.unlisted) {
      return 'Available by URL Only'
    } else {
      return 'Unpublished'
    }
  }

  deleteArticle = article => {
    const { articleHelper, editable } = this.props

    if (!editable) {
      console.log('Article is not editable')
      return
    }
    if (window.confirm(`Really delete the ${article.title} article?`)) {
      articleHelper.deleteArticle(article.id).then(() => {
        document.location.href = '/articles'
      })
    }
  }

  renderUpdateSlugText = () => {
    const { slugUpdateError } = this.state
    let text

    if (this.state.slugUpdating) {
      text = 'Slug Updating...'
    } else if (!slugUpdateError) {
      text = 'Update Slug'
    } else {
      text = 'Error updating slug. Try again with a unique slug.'
    }

    return text
  }

  renderReplicateText = () => {
    const { replicating, replicateError } = this.state
    const { article } = this.props
    let text

    if (replicating) {
      text = 'Replicating Article...'
    } else if (!replicateError) {
      text = 'Replicate Article'
    } else {
      text = `Error replicating Article. Does article slug: "${article.slug}_copy" already exist?.`
    }

    return text
  }

  renderReplicateArticleButton = () => {
    return (
      <Button
        className="replicate-button"
        onClick={this.handleReplicateArticle}
        children={this.renderReplicateText()}
        color="primary"
        variant="contained"
      />
    )
  }

  renderDeleteArticleButton = article => {
    const { editable } = this.props

    return (
      <Button
        disabled={!editable}
        className="delete-button"
        onClick={() => {
          this.deleteArticle(article)
        }}
        children="Delete Article"
        color="secondary"
        variant="contained"
      />
    )
  }

  renderArticleLink = () => {
    const { article } = this.props

    if (article.unlisted || article.published) {
      return this.articleHelper.renderArticleLink(article.slug)
    }

    return <span className="unpublished">N/A (Unpublished)</span>
  }

  render() {
    const { article, articleHelper, classes, editable, show, updateArticle } = this.props
    const { slug, slugUpdateError } = this.state
    if (!show) {
      return null
    }
    let listingStatus = this.getListingStatus()

    return (
      <div id="article-configure-container">
        <FormControl className={classes.formControl} key={`${article.id}-form-select-name`}>
          <TextField
            disabled={!this.props.editable}
            key={`${article.id}-configure-name`}
            type="text"
            name="name"
            margin="normal"
            className="article-configure-name"
            label="Name"
            onChange={updateArticle}
            value={article.name}
          />
        </FormControl>
        <FormControl className={classes.formControl} key={`${article.id}-form-slug`}>
          <TextField
            disabled={!this.props.editable}
            key={`${article.id}-configure-slug`}
            type="text"
            name="slug"
            margin="normal"
            className="article-configure-slug"
            label="Slug"
            onChange={this.handleRenameSlug}
            value={slug.length > 0 ? slug : article.slug}
          />
          <Button
            disabled={!articleHelper.validateSlug(article.slug, slug)}
            color={slugUpdateError ? 'secondary' : 'primary'}
            variant="contained"
            onClick={this.handleUpdateArticleSlug}
            children={this.renderUpdateSlugText()}
          />
        </FormControl>
        <FormControl className="replicate-article-form">{this.renderReplicateArticleButton()}</FormControl>
        <FormControl className="article-link-form-control">Link to Article: {this.renderArticleLink()}</FormControl>
        <FormControl key={`form-control-listing`} className="select-dropdown-listing">
          <InputLabel key={`input-label-listing`} htmlFor={`select-dropdown-listing`}>
            Listing Status
          </InputLabel>
          <NativeSelect
            disabled={!this.props.editable}
            key={`native-select-listing`}
            value={listingStatus}
            onChange={updateArticle}
            name={'Listing Status'}
            input={<Input key={`input-listing`} name={'Listing Status'} id={`select-dropdown-listing`} />}>
            <option key={`default-option-listing`} value={listingStatus}>
              {listingStatus}
            </option>
            {this.getListingOptions()
              .filter(listingOption => listingOption != listingStatus)
              .map(listingOption => (
                <option key={`option-${listingOption}`} value={listingOption}>
                  {listingOption}
                </option>
              ))}
          </NativeSelect>
        </FormControl>
        <FormControl key={`form-control-published-at`} className="published-at-date-form">
          <TextField
            id="published-at-date"
            label="Published Date"
            type="date"
            onChange={updateArticle}
            value={article.published_at ? new Moment(article.published_at).format('YYYY-MM-DD') : ''}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl className="delete-article-form">{this.renderDeleteArticleButton(article)}</FormControl>
      </div>
    )
  }
}
