import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputLabel,
  NativeSelect,
  Paper,
  TextField,
} from '@material-ui/core'
import { StringUtils } from '../utils.js.jsx'
import _ from 'lodash'

export default class CampaignBuilderCompose extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    editable: PropTypes.bool.isRequired,
    show: PropTypes.bool.isRequired,
    updateEmailEnvelope: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  formatUnsubscribeGroupToString = unsubGroup => {
    return `${unsubGroup.id} - ${unsubGroup.name}`
  }

  checkAndUpdateEmailEnvelope = event => {
    this.props.updateEmailEnvelope(event)
  }

  render() {
    const { classes, editable, emailEnvelope, show, updateEmailEnvelope } = this.props
    if (!show) {
      return null
    }

    return (
      <div id="campaign-compose-container">
        <FormControl className={`${classes.formControl} compose-form-field`}>
          <TextField
            disabled={!this.props.editable}
            id="from_email"
            label="from_email:"
            type="email"
            pattern="/.@thehustle.co$/"
            required
            onChange={this.checkAndUpdateEmailEnvelope}
            value={emailEnvelope['from_email'] || ''}
            className={classes.textField}
            name="from_email"
          />
        </FormControl>
        <FormControl className={`${classes.formControl} compose-form-field`}>
          <TextField
            disabled={!this.props.editable}
            id="from-name"
            label="From: [name]"
            type="text"
            onChange={updateEmailEnvelope}
            value={emailEnvelope['from_name'] || ''}
            className={classes.textField}
            name="from_name"
          />
        </FormControl>
        <FormControl className={`${classes.formControl} compose-form-field`}>
          <TextField
            disabled={!this.props.editable}
            id="reply-name"
            label="Reply: [name]"
            type="text"
            onChange={updateEmailEnvelope}
            value={emailEnvelope['reply_name'] || ''}
            className={classes.textField}
            name="reply_name"
          />
        </FormControl>
        <FormControl className={`${classes.formControl} compose-form-field`}>
          <TextField
            disabled={!this.props.editable}
            id="reply_to_email"
            label="reply_to_email:"
            type="email"
            onChange={updateEmailEnvelope}
            value={emailEnvelope['reply_to_email'] || ''}
            className={classes.textField}
            name="reply_to_email"
          />
        </FormControl>
      </div>
    )
  }
}
