class AdSegmentAPI {
  static jsonHeaders() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    }
  }

  static create(adId, segmentId) {
    var body = {
      ad_segment: {
        segment_id: segmentId,
        ad_id: adId,
      },
    }
    var options = {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(body),
      headers: this.jsonHeaders(),
    }
    var url = '/ads/' + adId + '/segments/' + segmentId + '.json'
    var request = new Request(url, options)
    return new Promise(function(resolve, reject) {
      fetch(request).then(function(response) {
        response
          .json()
          .then(resolve)
          .catch(function(error) {
            console.log('Failed to upsert ad_segment, segment_id: ' + segmentId + ' ad_id: ' + adId + '. ' + error)
            reject(error)
          })
      })
    })
  }

  static del(adId, segmentId) {
    var options = {
      credentials: 'same-origin',
      method: 'DELETE',
    }
    var url = '/ads/' + adId + '/segments/' + segmentId + '.json'
    var request = new Request(url, options)

    return fetch(request).catch(function(error) {
      console.log('Failed to delete ad_segment, segment_id: ' + segmentId + ' ad_id: ' + adId + '. ' + error)
    })
  }
}

export { AdSegmentAPI }
