import ArticlesSectionAPI from '../apis/articles_section_api.js.jsx'

export default class ArticlesSectionHelper {
  constructor() {
    this.updateQueue = []
    this.updateTimer = null
  }

  flushUpdates = () => {
    if (this.updateTimer) {
      clearTimeout(this.updateTimer)
      this.updateTimer = null
    }
    let promise = Promise.all(
      this.updateQueue.map(simpleArticlesSection => {
        let data = {
          id: simpleArticlesSection.id,
          ordering: simpleArticlesSection.ordering,
        }
        return ArticlesSectionAPI.patch(data).catch(error => {
          console.error(`Failed to update article with id: ${simpleArticlesSection.id}.`)
          reject(error)
        })
      }),
    )
    this.updateQueue = []
    return promise
  }

  // This only supports creating with a data arg with properties of `articleId`, `sectionId` and `ordering`
  create(data) {
    let cleanedData = {}
    if (data.articleId != null) {
      cleanedData.articleId = data.articleId
    }
    if (data.sectionId != null) {
      cleanedData.sectionId = data.sectionId
    }
    if (data.ordering != null) {
      cleanedData.ordering = data.ordering
    }
    return new Promise((resolve, reject) => {
      ArticlesSectionAPI.create(cleanedData)
        .then(jsonData => resolve(this.cleanSimpleArticlesSectionJsonData(jsonData)))
        .catch(error => {
          console.error('Failed to create new article: ' + error)
          reject(error)
        })
    })
  }

  // Pushes updates into a time-throttled and flushable queue. The data argument
  // should have properties of `id` `ordering`
  update(data) {
    if (!this.updateTimer) {
      this.updateTimer = setTimeout(this.flushUpdates, 3000)
    }
    let index = this.updateQueue
      .map(queuedArticlesSection => {
        return queuedArticlesSection.id === data.id
      })
      .indexOf(true)
    if (index >= 0) {
      this.updateQueue[index] = Object.assign(this.updateQueue[index], data)
    } else {
      this.updateQueue.push(data)
    }
  }

  preview(articleId) {
    return new Promise((resolve, reject) => {
      ArticlesSectionAPI.preview(articleId)
        .then(previewHTML => {
          return resolve(previewHTML)
        })
        .catch(error => {
          console.error('Error resolving response text for ArticlesSectionAPI.preview: ')
          reject(error)
        })
    })
  }

  del(articleId) {
    return ArticlesSectionAPI.del(articleId)
  }

  blank(id) {
    return {
      id: id,
      article_id: -1,
      section_id: -1,
      ordering: 0,
    }
  }

  cleanSimpleArticlesSectionJsonData(jsonData) {
    if (jsonData.ordering === null) {
      jsonData.ordering = -1
    }
    if (jsonData.article_id === null) {
      jsonData.article_id = -1
    }
    if (jsonData.section_id === null) {
      jsonData.section_id = -1
    } else {
      jsonData.section_id = jsonData.section_id.toString()
    }
    return jsonData
  }
}
