import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import NativeSelect from '@material-ui/core/NativeSelect'
import TextField from '@material-ui/core/TextField'
import { DateUtils } from '../utils.js.jsx'
import CampaignBuilderDeliverButton from './campaign_builder_deliver_button.js.jsx'
import Moment from 'moment'

export default class CampaignBuilderDeliverDialog extends Component {
  static propTypes = {
    aBTestCampaignHelper: PropTypes.object.isRequired,
    campaign: PropTypes.object.isRequired,
    challengeDialogScheduleText: PropTypes.string.isRequired,
    challengeDialogScheduleTitle: PropTypes.string.isRequired,
    challengeDialogSendNowText: PropTypes.string.isRequired,
    challengeDialogSendNowTitle: PropTypes.string.isRequired,
    challengeDialogEmailListText: PropTypes.string.isRequired,
    challengeHintEmailList: PropTypes.string.isRequired,
    challengeHintSchedule: PropTypes.string.isRequired,
    challengeHintSendNow: PropTypes.string.isRequired,
    correctAnswerEmailList: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    deliverable: PropTypes.bool.isRequired,
    dialogTitle: PropTypes.string.isRequired,
    editable: PropTypes.bool.isRequired,
    handleChooseSchedule: PropTypes.func.isRequired,
    handleChooseSendNow: PropTypes.func.isRequired,
    handleCloseDeliverDialog: PropTypes.func.isRequired,
    handleSchedulingCampaign: PropTypes.func.isRequired,
    handleShowChallengeDialog: PropTypes.func.isRequired,
    handleShowDeliverDialog: PropTypes.func.isRequired,
    isAbTestCampaign: PropTypes.func.isRequired,
    loadCampaign: PropTypes.func.isRequired,
    markCampaignSent: PropTypes.func.isRequired,
    propertyPresentInCampaign: PropTypes.func.isRequired,
    scheduledHour: PropTypes.string.isRequired,
    scheduledMinute: PropTypes.string.isRequired,
    schedulingCampaign: PropTypes.bool.isRequired,
    showChallengeDialog: PropTypes.bool.isRequired,
    showDeliverDialog: PropTypes.bool.isRequired,
    updateCampaign: PropTypes.func.isRequired,
    updateCampaignScheduledTime: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      challengeAnswer: '',
      emailListChallengeAnswer: '',
    }
  }

  setChallengeAnswer = event => {
    this.setState({ challengeAnswer: event.target.value })
  }

  setEmailListChallengeAnswer = event => {
    this.setState({ emailListChallengeAnswer: event.target.value })
  }

  clearChallengeAnswer = () => {
    this.setState({ challengeAnswer: '', emailListChallengeAnswer: '' })
  }

  checkChallenge = () => {
    const { chooseSchedule, correctAnswerEmailList, correctAnswerSchedule, correctAnswerSendNow } = this.props
    const { challengeAnswer, emailListChallengeAnswer } = this.state
    let correctAnswer

    if (chooseSchedule == false) {
      correctAnswer = correctAnswerSendNow
    } else {
      correctAnswer = correctAnswerSchedule
    }

    if (challengeAnswer == correctAnswer && correctAnswerEmailList == emailListChallengeAnswer) {
      return true
    } else {
      return false
    }
  }

  renderSendButton = () => {
    const {
      aBTestCampaignHelper,
      campaign,
      chooseSchedule,
      deliverable,
      editable,
      handleCloseDeliverDialog,
      handleSchedulingCampaign,
      handleShowChallengeDialog,
      isAbTestCampaign,
      loadCampaign,
      markCampaignSent,
      propertyPresentInCampaign,
      scheduledHour,
      scheduledMinute,
      schedulingCampaign,
      showChallengeDialog,
      showDeliverDialog,
    } = this.props

    if (!deliverable) {
      return null
    }
    return (
      <CampaignBuilderDeliverButton
        aBTestCampaignHelper={aBTestCampaignHelper}
        scheduling={chooseSchedule}
        campaign={campaign}
        checkChallenge={this.checkChallenge}
        clearChallengeAnswer={this.clearChallengeAnswer}
        deliverable={deliverable}
        editable={editable}
        handleSchedulingCampaign={handleSchedulingCampaign}
        handleCloseDeliverDialog={handleCloseDeliverDialog}
        handleShowChallengeDialog={handleShowChallengeDialog}
        isAbTestCampaign={isAbTestCampaign}
        loadCampaign={loadCampaign}
        markCampaignSent={markCampaignSent}
        propertyPresentInCampaign={propertyPresentInCampaign}
        showChallengeDialog={showChallengeDialog}
        showDeliverDialog={showDeliverDialog}
        scheduledHour={scheduledHour}
        scheduledMinute={scheduledMinute}
        schedulingCampaign={schedulingCampaign}
      />
    )
  }

  renderTreeButtons = () => {
    const {
      campaign,
      classes,
      handleChooseSchedule,
      handleChooseSendNow,
      handleShowChallengeDialog,
      isAbTestCampaign,
      propertyPresentInCampaign,
    } = this.props

    return (
      <FormControl className={classes.formControl} id="deliver-tree" key={`${campaign.id}-form-deliver-tree`}>
        <Button
          id="choose-schedule"
          onClick={propertyPresentInCampaign('scheduled_time') ? handleShowChallengeDialog : handleChooseSchedule}
          color={propertyPresentInCampaign('scheduled_time') ? 'secondary' : 'primary'}
          variant="contained"
          children={propertyPresentInCampaign('scheduled_time') ? 'Unschedule' : 'Schedule'}
        />
        {!isAbTestCampaign() && !propertyPresentInCampaign('scheduled_time') && (
          <Button
            id="choose-send-now"
            onClick={handleChooseSendNow}
            color="primary"
            variant="contained"
            children="Send Now"
          />
        )}
      </FormControl>
    )
  }

  renderChallengeDialogTitle = () => {
    const {
      chooseSchedule,
      challengeDialogScheduleTitle,
      challengeDialogSendNowTitle,
      propertyPresentInCampaign,
    } = this.props

    if (propertyPresentInCampaign('scheduled_time')) {
      return 'Are you sure you want to unschedule this delivery?'
    } else if (chooseSchedule) {
      return challengeDialogScheduleTitle
    } else {
      return challengeDialogSendNowTitle
    }
  }

  render() {
    const {
      campaign,
      challengeDialogScheduleTitle,
      challengeDialogScheduleText,
      challengeDialogEmailListText,
      challengeDialogSendNowTitle,
      challengeDialogSendNowText,
      challengeHintSchedule,
      challengeHintSendNow,
      challengeHintEmailList,
      chooseSchedule,
      classes,
      dialogTitle,
      handleCloseDeliverDialog,
      propertyPresentInCampaign,
      scheduledHour,
      scheduledMinute,
      showChallengeDialog,
      showDeliverDialog,
      showDeliverDialogTree,
      updateCampaign,
      updateCampaignScheduledTime,
    } = this.props

    const { emailListChallengeAnswer } = this.state

    let hourOptions = []

    for (var i = 0; i < 24; i++) {
      hourOptions.push(
        <option key={`hour-option-${i}`} value={('0' + i).slice(-2)}>
          {i}
        </option>,
      )
    }

    return (
      <div>
        <Dialog
          id="deliver-dialog-tree"
          maxWidth="md"
          open={showDeliverDialogTree}
          onClose={handleCloseDeliverDialog}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContent>
            {(campaign.scheduled_time && (
              <DialogContentText id="scheduled-dialog-description">
                Campaign delivery scheduled for
                <br />
                <strong>{DateUtils.momentDayOfWeekMonthNumberYearHourMinute(campaign.scheduled_time)}</strong>
              </DialogContentText>
            )) || (
              <DialogContentText id="schedule-dialog-tree-description">
                Do you want to schedule a campaign delivery or send it now?
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeliverDialog} color="primary">
              Cancel
            </Button>
            {this.renderTreeButtons()}
          </DialogActions>
        </Dialog>

        <Dialog
          id="deliver-dialog"
          maxWidth="md"
          open={showDeliverDialog}
          onClose={handleCloseDeliverDialog}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContent>
            {campaign.scheduled_time && (
              <DialogContentText id="scheduled-dialog-description">
                Campaign delivery scheduled for
                <br />
                <strong>{DateUtils.momentDayOfWeekMonthNumberYearHourMinute(campaign.scheduled_time)}</strong>
              </DialogContentText>
            )}
            <FormControl className={classes.formControl} id="deliver-date" key={`${campaign.id}-form-deliver-date`}>
              <TextField
                disabled
                required
                fullWidth
                id="date"
                label="Send Date"
                type="date"
                name="date"
                InputLabelProps={{ shrink: true }}
                value={campaign.date}
              />
            </FormControl>
            <FormControl id="select-hour" key={`form-control-deliver-hour`} className={classes.formControl}>
              <InputLabel key={`input-label-deliver-hour`} htmlFor={`select-dropdown-deliver-hour`}>
                Hour
              </InputLabel>
              <NativeSelect
                disabled={propertyPresentInCampaign('scheduled_time') || propertyPresentInCampaign('sent_at')}
                key={`native-select-deliver-hour`}
                className="select-hour"
                name="scheduledHour"
                value={scheduledHour}
                onChange={updateCampaignScheduledTime}
                input={<Input key={`input-deliver-hour`} name="scheduledHour" id={`select-dropdown-deliver-hour`} />}>
                {hourOptions.map(hourOption => hourOption)}
              </NativeSelect>
            </FormControl>
            <FormControl id="select-minute" key={`form-control-deliver-minute`} className={classes.formControl}>
              <InputLabel key={`input-label-deliver-minute`} htmlFor={`select-dropdown-deliver-minute`}>
                Minute
              </InputLabel>
              <NativeSelect
                disabled={propertyPresentInCampaign('scheduled_time') || propertyPresentInCampaign('sent_at')}
                key={`native-select-deliver-minute`}
                className="select-minute"
                name="scheduledMinute"
                value={scheduledMinute}
                onChange={updateCampaignScheduledTime}
                placeholder="Minute"
                input={
                  <Input key={`input-deliver-minute`} name="scheduledMinute" id={`select-dropdown-deliver-minute`} />
                }>
                <option value="00">00</option>
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="45">45</option>
              </NativeSelect>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeliverDialog} color="primary">
              Cancel
            </Button>
            {this.renderSendButton()}
          </DialogActions>
        </Dialog>

        <Dialog
          id="challenge-dialog"
          maxWidth="md"
          open={showChallengeDialog}
          onClose={handleCloseDeliverDialog}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{this.renderChallengeDialogTitle()}</DialogTitle>
          <DialogContent>
            <DialogContentText id="challenge-dialog-description">
              {chooseSchedule ? challengeDialogScheduleText : challengeDialogSendNowText}
            </DialogContentText>
            <FormControl
              className={classes.formControl}
              id="deliver-challenge"
              key={`${campaign.id}-form-deliver-challenge`}>
              <TextField
                required
                fullWidth
                id="challenge"
                type="text"
                name="challenge"
                placeholder={chooseSchedule ? challengeHintSchedule : challengeHintSendNow}
                onChange={this.setChallengeAnswer}
                InputLabelProps={{ shrink: true }}
                value={this.state.challengeAnswer}
              />
              <DialogContentText id="challenge-dialog-description-email-list">
                {challengeDialogEmailListText}
              </DialogContentText>
              <TextField
                required
                fullWidth
                id="emailListChallenge"
                type="text"
                name="emailListChallenge"
                placeholder={challengeHintEmailList}
                onChange={this.setEmailListChallengeAnswer}
                InputLabelProps={{ shrink: true }}
                value={emailListChallengeAnswer}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeliverDialog} color="primary">
              Cancel
            </Button>
            {this.renderSendButton()}
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
