import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'

// This button will call a method when clicked, and disable itself
// while the method is running. The method must return a promise
// object.
class DisablingButton extends React.Component {
  static propTypes = {
    buttonAction: PropTypes.func.isRequired,
    buttonText: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)
    if (this.props.buttonText == null || this.props.buttonAction == null) {
      throw 'Must specify buttonText, url, and buttonAction when making a EndpointButton'
    }
    let loadingText = this.props.loadingText || 'loading...'
    this.state = { processing: false, loadingText: loadingText }
  }

  displayText = () => {
    if (this.state.processing) {
      return this.state.loadingText
    } else {
      return this.props.buttonText
    }
  }

  performAction = () => {
    if (!this.state.processing) {
      this.setState({ processing: true })
      this.props
        .buttonAction()
        .then(() => {
          this.setState({ processing: false })
        })
        .catch(error => {
          this.setState({ processing: false })
          console.error('Failed to perform action: ' + error)
        })
    }
  }

  render() {
    const { disabled } = this.props
    return (
      <div>
        <Button disabled={disabled} color="default" variant="contained" onClick={this.performAction}>
          {this.displayText()}
        </Button>
      </div>
    )
  }
}

export { DisablingButton }
