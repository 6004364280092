import BaseAPI from './base_api.js'

export default class AbTestCampaignAPI extends BaseAPI {
  static jsonHeaders = () => {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    }
  }
  static resourceName = () => 'ab_test_campaign'
  static baseUrl = params => '/ab_test_campaigns'
  static showUrl = params => `${this.baseUrl(params)}/${params['id']}`

  static findWithVariantCampaigns = params => {
    return new Promise((resolve, reject) => {
      let request = new Request(this.showUrl(params), { credentials: 'same-origin' })
      fetch(request).then(response => {
        response
          .json()
          .then(resolve)
          .catch(error => {
            console.error(`Failed to retrieve show action for ${this.resourceName()}: ${error}`)
            console.error(params)
            console.error(request)
            reject(error)
          })
      })
    })
  }

  static update = (aBTestCampaignId, campaign) => {
    return new Promise((resolve, reject) => {
      let body = { id: aBTestCampaignId, campaign: campaign }
      let options = {
        credentials: 'same-origin',
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = '/ab_test_campaigns/' + aBTestCampaignId + '.json'
      let request = new Request(url, options)
      fetch(request).then(response => {
        response
          .json()
          .then(resolve)
          .catch(error => {
            console.error(`Failed to update ${this.resourceName()}: ${error}`)
            console.error(body)
            console.error(request)
            reject(error)
          })
      })
    })
  }

  static replicateEmailsSectionForAbTestCampaign = (aBTestCampaignId, emailsSectionId, ordering) => {
    return new Promise((resolve, reject) => {
      let body = { id: aBTestCampaignId, emails_section: { id: emailsSectionId, ordering: ordering } }
      let options = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = '/ab_test_campaigns/' + aBTestCampaignId + '/replicate_emails_section.json'
      let request = new Request(url, options)
      fetch(request).then(response => {
        response
          .json()
          .then(resolve)
          .catch(error => {
            console.error(`Failed to update ${this.resourceName()}: ${error}`)
            console.error(body)
            console.error(request)
            reject(error)
          })
      })
    })
  }

  static schedule = (aBTestCampaignId, time) => {
    return new Promise((resolve, reject) => {
      let body = { id: aBTestCampaignId, time: time }
      let options = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = '/ab_test_campaigns/' + aBTestCampaignId + '/schedule.json'
      let request = new Request(url, options)
      fetch(request).then(response => {
        response
          .json()
          .then(resolve)
          .catch(error => {
            console.error(`Failed to update ${this.resourceName()}: ${error}`)
            console.error(body)
            console.error(request)
            reject(error)
          })
      })
    })
  }

  static unschedule = aBTestCampaignId => {
    return new Promise((resolve, reject) => {
      let body = { id: aBTestCampaignId }
      let options = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = '/ab_test_campaigns/' + aBTestCampaignId + '/unschedule.json'
      let request = new Request(url, options)
      fetch(request).then(response => {
        response
          .json()
          .then(resolve)
          .catch(error => {
            console.error(`Failed to update ${this.resourceName()}: ${error}`)
            console.error(body)
            console.error(request)
            reject(error)
          })
      })
    })
  }

  static patchSailthruAbTestCampaign = aBTestCampaignId => {
    let body = { id: aBTestCampaignId }
    let options = {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(body),
      headers: this.jsonHeaders(),
    }
    return new Promise((resolve, reject) => {
      let url = '/ab_test_campaigns/' + aBTestCampaignId + '/patch_sailthru_ab_test_campaign.json'
      let request = new Request(url, options)
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => {
              console.error('Failed to patch ab_test_campaign ID: ' + campaignId, error)
              reject(error)
            })
        })
        .catch(error => reject(error))
    })
  }
}
