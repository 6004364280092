import React, { Component } from 'react'
import PropTypes from 'prop-types'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import { Grid, FormControlLabel, Checkbox, Typography } from '@material-ui/core'
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers'
import { RadioButtonUncheckedIcon, RadioButtonCheckedIcon } from '@material-ui/icons'
import Moment from 'moment'
import _ from 'lodash'

const daysNumbers = {
  SUN: '0',
  MON: '1',
  TUE: '2',
  WED: '3',
  THU: '4',
  FRI: '5',
  SAT: '6',
}

export default class CrontabGenerator extends Component {
  static propTypes = {
    editable: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    updateDripCampaign: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedHour: '',
      selectedMinute: '',
      selectedDaysOfWeek: [],
    }
  }

  componentDidMount = () => {
    this.loadCron()
  }

  loadCron = () => {
    const { value } = this.props
    const { selectedDaysOfWeek, selectedHour, selectedMinute } = this.state
    if (_.isEmpty(value)) {
      return
    }

    this.setState(prevState => {
      let valueSplit = value.split(' ')
      let daysOfWeekTrueFromProps = valueSplit[4].split(',')
      let union = _.union(daysOfWeekTrueFromProps, selectedDaysOfWeek)

      return {
        selectedHour: valueSplit[1],
        selectedMinute: valueSplit[0],
        selectedDaysOfWeek: union,
      }
    })
  }

  updateCronTabSchedule = () => {
    const { selectedHour, selectedMinute, selectedDaysOfWeek } = this.state
    let { updateDripCampaign, value } = this.props

    if (_.isEmpty(value)) {
      value = '0 21 * * 0,1,2,3,4,5,6'
    }

    let valueSplit = value.split(' ')

    let fakeEvent = newValue => {
      return {
        target: {
          name: 'crontab_schedule',
          value: newValue,
          type: 'fake_event',
        },
      }
    }

    valueSplit[0] = selectedMinute
    valueSplit[1] = selectedHour
    valueSplit[2] = '*'
    valueSplit[3] = '*'
    valueSplit[4] = selectedDaysOfWeek.join(',')

    let newVal = valueSplit.join(' ')
    updateDripCampaign(fakeEvent(newVal))
  }

  handleTimeChange = date => {
    this.setState({ selectedHour: date.getHours(), selectedMinute: date.getMinutes() }, this.updateCronTabSchedule)
  }

  handleDayOfWeekChange = event => {
    const { selectedDaysOfWeek } = this.state
    let dayNumber = daysNumbers[event.target.value]
    this.setState(prevState => {
      if (_.includes(prevState.selectedDaysOfWeek, dayNumber)) {
        // disallow the unselection of the last remaining checkbox so there's always at least one day of week
        // in the crontab_schedule value
        if (prevState.selectedDaysOfWeek.length < 2) {
          return null
        }
        prevState.selectedDaysOfWeek = prevState.selectedDaysOfWeek.filter(n => n !== dayNumber)
      } else {
        prevState.selectedDaysOfWeek = prevState.selectedDaysOfWeek.concat(dayNumber)
      }
      return { selectedDaysOfWeek: _.sortBy(prevState.selectedDaysOfWeek) }
    }, this.updateCronTabSchedule)
  }

  renderLocalTimezoneMessage = () => {
    const { selectedHour, selectedMinute } = this.state
    let date = new Date()
    let localDate = `${date.getFullYear()}-${date.getMonth()}-${date.getDay()} ${selectedHour}:${selectedMinute}Z`
    let isoDate = new Date(localDate)
    let momentLocalTime = new Moment(isoDate).local().format('h:mm A')

    return (
      <div id="timezone-status">
        <Typography className="timezone-message" variant="subtitle2" align="center" gutterBottom>
          This is {momentLocalTime} in your local timezone.
        </Typography>
      </div>
    )
  }

  render() {
    const { editable } = this.props
    const { selectedHour, selectedMinute, selectedDaysOfWeek } = this.state
    let dateValue = new Date().setHours(selectedHour, selectedMinute)

    return (
      <div className="cron-generator-div">
        <div className="days-of-week">
          {['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].map(day => {
            return (
              <div className="day-radio-button" key={`radio-button-div-${day}`}>
                <FormControlLabel
                  key={`radio-button-label-${day}`}
                  value="top"
                  className="day-label"
                  control={
                    <Checkbox
                      checked={_.includes(selectedDaysOfWeek, daysNumbers[day])}
                      color="primary"
                      disabled={!editable}
                      value={day}
                      key={`radio-button-${day}`}
                      onChange={this.handleDayOfWeekChange}
                      inputProps={{ 'aria-label': day }}
                    />
                  }
                  label={day}
                  labelPlacement="top"
                />
              </div>
            )
          })}
        </div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around">
            <TimePicker
              disabled={!editable}
              margin="normal"
              id="time-picker"
              label="Hour picker (UTC)"
              value={dateValue}
              onChange={this.handleTimeChange}
            />
          </Grid>
          {this.renderLocalTimezoneMessage()}
        </MuiPickersUtilsProvider>
      </div>
    )
  }
}
