import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import Checkbox from '@material-ui/core/Checkbox'

class SegmentEnabledResponsiveDialog extends Component {
  static propTypes = {
    dialogText: PropTypes.string.isRequired,
    dialogTitle: PropTypes.string.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    handleRowClick: PropTypes.func,
    handleUpdateEnabled: PropTypes.func.isRequired,
    segment: PropTypes.object.isRequired,
    segmentHelper: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  onKeyPress = (event, segmentId) => {
    if (event.key == 'Enter') {
      this.props.handleUpdateEnabled(event, segmentId)
      this.handleClose()
    }
  }

  render() {
    const { fullScreen, handleConfirm, dialogText, dialogTitle, segment, handleUpdateEnabled } = this.props

    return (
      <div>
        <Checkbox onClick={this.handleClickOpen} checked={segment.enabled} />
        <Dialog
          fullScreen={fullScreen}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title">
          <DialogTitle id="responsive-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{dialogText}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" onKeyPress={this.onKeyPress}>
              Cancel
            </Button>
            <Button
              key={segment.id}
              onClick={event => {
                handleUpdateEnabled(event, segment.id), this.handleClose()
              }}
              color="primary"
              autoFocus
              onKeyPress={event => {
                this.onKeyPress(event, segment.id)
              }}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default withMobileDialog()(SegmentEnabledResponsiveDialog)
