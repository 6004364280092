import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { EmailHelper } from '../helpers/email_helper.js.jsx'
import { SectionHelper } from '../helpers/section_helper.js.jsx'
import ReactIframeResizer from 'react-iframe-resizer-super'
import FontAwesome from 'react-fontawesome'

export default class CampaignPreview extends Component {
  static propTypes = {
    availableEmails: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    emails: PropTypes.array,
    previewDirty: PropTypes.bool.isRequired,
    previewHTMLType: PropTypes.string.isRequired,
    previewUpdated: PropTypes.func.isRequired,
    sectionUpdated: PropTypes.object,
    sectionId: PropTypes.number,
  }

  constructor(props) {
    super(props)
    this.state = {
      activeLivePreview: 0,
      iframeKey: 0,
      previewHtmlUrl: '',
    }

    this.emailHelper = new EmailHelper()
    this.sectionHelper = new SectionHelper()
    // Silence a warning that occurs on every update of the iframe when switching previewHtmlUrl
    console.warn = warning => (warning == '[iFrameSizer][Host page: lp-iframe]' ? null : warning)
  }

  componentDidMount() {
    const { emails, previewHTMLType, previewUpdated, sectionId, selectedEmail } = this.props
    let previewHtmlUrl
    if (previewHTMLType == 'section') {
      previewHtmlUrl = `/sections/${sectionId}/preview_html.html`
    } else if (previewHTMLType == 'email') {
      let emailId = selectedEmail.id
      previewHtmlUrl = `/emails/${emailId}/preview`
    }
    this.setState({ previewHtmlUrl, iframeKey: this.state.iframeKey + 1 }, previewUpdated)
  }

  componentDidUpdate(prevProps) {
    const { emails, previewDirty, previewHTMLType, previewUpdated, sectionId, selectedEmail } = this.props
    if (!(prevProps.previewDirty == false && previewDirty)) {
      return null
    }
    let previewHtmlUrl
    if (previewHTMLType == 'section') {
      previewHtmlUrl = `/sections/${sectionId}/preview_html.html`
    } else if (previewHTMLType == 'email') {
      let emailId = selectedEmail.id
      previewHtmlUrl = `/emails/${emailId}/preview`
    }
    this.setState({ previewHtmlUrl, iframeKey: this.state.iframeKey + 1 }, previewUpdated)
  }

  segmentBoxes = () => {
    const { availableEmails, emails, selectedEmail } = this.props
    let segmentBoxes = []
    for (let emailId in availableEmails) {
      let enabled = selectedEmail ? selectedEmail == emailId : emails[0].id == emailId
      let segmentName = availableEmails[emailId]
      segmentBoxes.push({ name: segmentName, emailId: emailId, enabled: enabled })
    }
    return segmentBoxes.sort((a, b) => (a.name < b.name ? 1 : -1))
  }

  renderSegmentToggleBoxes = () => {
    const { selectEmail } = this.props
    return (
      <div key="toggle-boxes" id="segment-toggle-boxes">
        {this.segmentBoxes().map(box => (
          <div
            key={box.name}
            className={`segment-toggle-box ${box.enabled ? '' : 'disabled-segment-toggle-box'}`}
            onClick={() => {
              selectEmail(box.emailId)
            }}>
            {box.name}
          </div>
        ))}
      </div>
    )
  }

  toggleActiveLivePreview = id => {
    this.setState({ activeLivePreview: id, iframeKey: this.state.iframeKey + 1 }, this.props.updateReviewDirty)
  }

  render() {
    const { availableEmails, emails, previewHTMLType, selectedEmail, selectEmail } = this.props
    const { activeLivePreview, iframeKey, previewHtmlUrl } = this.state

    if (previewHTMLType == 'email' && !emails) {
      return null
    }

    return (
      <div id="campaign-builder-preview">
        <LivePreview
          activeLivePreview={activeLivePreview}
          availableEmails={availableEmails}
          emails={emails}
          key={iframeKey}
          previewHTMLType={previewHTMLType}
          previewHtmlUrl={previewHtmlUrl}
          selectEmail={selectEmail}
          selectedEmail={selectedEmail}
          toggleActiveLivePreview={this.toggleActiveLivePreview}
        />
      </div>
    )
  }
}

class LivePreview extends Component {
  static propTypes = {
    activeLivePreview: PropTypes.number.isRequired,
    availableEmails: PropTypes.object.isRequired,
    emails: PropTypes.array.isRequired,
    previewHTMLType: PropTypes.string.isRequired,
    previewHtmlUrl: PropTypes.string.isRequired,
    selectEmail: PropTypes.func,
    selectedEmail: PropTypes.object,
    toggleActiveLivePreview: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.sectionHelper = new SectionHelper()

    this.state = {
      expanded: true,
    }
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.sectionHelper.updateQueue.flush)
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.sectionHelper.updateQueue.flush)
  }

  toggleEmail = (toggleBoxId, emailId) => {
    return () => {
      this.props.toggleActiveLivePreview(toggleBoxId)
      this.props.selectEmail(emailId)
    }
  }

  segmentBoxClass = (emailId, selectedEmailId) => {
    let classNames = {
      'segment-toggle-box': true,
      'active-segment': emailId == selectedEmailId ? true : false,
    }
    let result = []
    for (let key in classNames) {
      if (classNames[key]) {
        result.push(key)
      }
    }
    return result.join(' ')
  }

  renderSelectEmails = () => {
    const { availableEmails, activeLivePreview, selectedEmail, selectEmail } = this.props
    let toggleBoxes = []
    // Create and use a array at the length of all toggleBoxes, for the id of a toggleBox.
    // The toggleBox id is used to target 'click' the correct toggleBox, since the order
    // of those is in reverse order.
    let availableEmailsKeys = Object.keys(availableEmails)
    let toggleBoxId = availableEmailsKeys.length - 1
    let idArray = Array.from(Array(availableEmailsKeys.length).keys())
    for (let emailId in availableEmails) {
      toggleBoxes.push(
        <div
          className={this.segmentBoxClass(emailId, selectedEmail.id)}
          onClick={this.toggleEmail(idArray[0], emailId)}
          key={idArray.shift()}>
          {availableEmails[emailId]}
        </div>,
      )
      toggleBoxId--
    }
    toggleBoxes.sort((box1, box2) =>
      box1.props.children > box2.props.children ? 1 : box1.props.children == box2.props.children ? 0 : -1,
    )
    // Always show 3A before 3B, etc
    return <div id="segment-boxes">{toggleBoxes}</div>
  }

  renderIframe = () => {
    const { availableEmails, activeLivePreview, emails, previewHtmlUrl } = this.props
    let availableEmailsKeys = Object.keys(availableEmails)
    let reversedIdArray = Array.from(Array(availableEmailsKeys.length).keys()).reverse()
    let emailId = reversedIdArray[activeLivePreview]
    const iframeResizerOptions = {
      log: false,
      id: 'lp-iframe',
      scrolling: false,
      autoResize: true,
      frameBorder: 0,
      minHeight: 600,
      checkOrigin: false,
      // Might be needed for automated front-end tests.
      // checkOrigin: false,
    }
    return <ReactIframeResizer iframeResizerOptions={iframeResizerOptions} src={previewHtmlUrl} />
  }

  render() {
    const { availableEmails, activeLivePreview, emails, iframeKey, previewHTMLType } = this.props
    let loadingIcon = <FontAwesome name="spinner" spin size="5x" style={{ color: 'gray' }} />

    return (
      <div id="contain-lp">
        {previewHTMLType == 'email' && <div id="live-preview-header">{this.renderSelectEmails()}</div>}
        <div key={iframeKey} id="contain-lp-iframe">
          {this.renderIframe()}
        </div>
      </div>
    )
  }
}
