import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReportSection from './report_section.js.jsx'
import { EmailHelper } from '../helpers/email_helper.js.jsx'
import Moment from 'moment'

export default class CampaignReportManager extends Component {
  static propTypes = {
    campaign: PropTypes.object.isRequired,
    campaignDate: PropTypes.string.isRequired,
    dataList: PropTypes.array.isRequired,
    emails: PropTypes.array.isRequired,
    filteredESRs: PropTypes.array.isRequired,
    activeSegments: PropTypes.array.isRequired,
    segmentHelper: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.getSection = this.getSection.bind(this)
    this.availableEmails = this.availableEmails.bind(this)

    this.emailHelper = new EmailHelper()

    this.state = {}
  }

  getSection(sectionId) {
    return this.props.dataList.find(section => section.id == sectionId)
  }

  availableEmails() {
    const { emails, segmentHelper } = this.props
    let result = {}
    if (segmentHelper) {
      emails.forEach(e => {
        result[e.id] = segmentHelper.segmentForId(e.segment_id).name
      })
    }
    return result
  }

  // Returns a recursively flattened version of the array provided
  flattenDeep(arr1) {
    return arr1.reduce((acc, val) => (Array.isArray(val) ? acc.concat(this.flattenDeep(val)) : acc.concat(val)), [])
  }

  // Returns a boolean on whether or not a Section's sectionData matches up with any of the current activeSegments ids
  belongsToActiveSegment(sectionData) {
    const { activeSegments } = this.props
    return sectionData.emails_sections.some(
      es => es.email_id == activeSegments.filter(emailId => emailId == es.email_id),
    )
  }

  // Returns a flattened array of email_section_urls associated with a Section's sectionData
  getFlattenedUrls(sectionData) {
    const { filteredESRs } = this.props
    return this.flattenDeep(
      this.flattenDeep(filteredESRs)
        .filter(esr => esr.section_id == sectionData.id)
        .map(esr => esr.email_section_urls),
    )
  }

  renderReportSection() {
    const { activeSegments, dataList } = this.props
    let activeReportSections = []
    dataList.map(sectionData => {
      if (this.belongsToActiveSegment(sectionData)) {
        activeReportSections.push(
          <ReportSection
            activeSegments={activeSegments}
            availableEmails={this.availableEmails()}
            sectionData={sectionData}
            key={`${sectionData.id}`}
            urls={this.getFlattenedUrls(sectionData)}
          />,
        )
      }
    })

    return activeReportSections.map(reportSection => (
      <div key={`report-section-${reportSection.key}`}>{reportSection}</div>
    ))
  }

  render() {
    if (this.props.filteredESRs == null) {
      return <div id="none-selected">No segments selected</div>
    } else {
      return <div id="report-manager">{this.renderReportSection()}</div>
    }
  }
}
