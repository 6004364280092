import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import Button from '@material-ui/core/Button'
import SectionTemplateVariable from './section_template_variable.js.jsx'
import SectionTemplateVariableSlot from './section_template_variable_slot.js.jsx'

@DragDropContext(HTML5Backend)
export default class SectionTemplateVariableBoard extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    createSectionTemplateVariable: PropTypes.func.isRequired,
    deleteVariable: PropTypes.func.isRequired,
    editable: PropTypes.bool.isRequired,
    renderEmptyShrugDiv: PropTypes.func.isRequired,
    sectionTemplate: PropTypes.object.isRequired,
    updateSectionTemplateVariable: PropTypes.func.isRequired,
    updateSectionTemplateVariableOrderings: PropTypes.func.isRequired,
    variables: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {}
  }

  findSectionTemplateVariable = id => {
    const { variables } = this.props
    const sectionTemplateVariable = variables.find(v => v.id === id)

    return {
      sectionTemplateVariable,
      index: variables.indexOf(sectionTemplateVariable),
    }
  }

  renderSectionTemplateVariable = sectionTemplateVariable => {
    const {
      classes,
      deleteVariable,
      editable,
      selectedSectionTemplateVariable,
      updateSectionTemplateVariable,
      updateSectionTemplateVariableOrderings,
      variables,
    } = this.props
    return (
      <SectionTemplateVariable
        classes={classes}
        deleteVariable={deleteVariable}
        editable={editable}
        findSectionTemplateVariable={this.findSectionTemplateVariable}
        sectionTemplateVariable={sectionTemplateVariable}
        updateSectionTemplateVariable={updateSectionTemplateVariable}
        updateSectionTemplateVariableOrderings={updateSectionTemplateVariableOrderings}
        variables={variables}
      />
    )
  }

  renderSectionTemplateVariableSlot = sectionTemplateVariable => {
    const { classes, variables } = this.props
    return (
      <div key={`section-template-variable-slot-${sectionTemplateVariable.id}`}>
        <SectionTemplateVariableSlot
          findSectionTemplateVariable={this.findSectionTemplateVariable}
          sectionTemplateVariable={sectionTemplateVariable}
          variables={variables}>
          {this.renderSectionTemplateVariable(sectionTemplateVariable)}
        </SectionTemplateVariableSlot>
      </div>
    )
  }

  render() {
    const { classes, renderEmptyShrugDiv, variables } = this.props
    if (variables.length < 1) {
      return renderEmptyShrugDiv()
    }
    return <div>{variables.map(variable => this.renderSectionTemplateVariableSlot(variable))}</div>
  }
}
