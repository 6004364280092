import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Button, TextField, Typography } from '@material-ui/core'
import PageBuilderPreview from './page_builder_preview.js.jsx'
import FormHelper from '../helpers/form_helper.js.jsx'
import Split from 'react-split'

export default class PageBuilderEditor extends Component {
  static propTypes = {
    builderType: PropTypes.string.isRequired,
    page: PropTypes.object.isRequired,
    pageHelper: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    deleteSection: PropTypes.func.isRequired,
    editable: PropTypes.bool.isRequired,
    previewDirty: PropTypes.bool.isRequired,
    previewUpdated: PropTypes.func.isRequired,
    sectionHelper: PropTypes.object.isRequired,
    sectionsList: PropTypes.array.isRequired,
    selectedIndex: PropTypes.number,
    selectedSectionEstimatedSize: PropTypes.object.isRequired,
    selectedSectionId: PropTypes.number,
    show: PropTypes.bool.isRequired,
    templateVariables: PropTypes.object.isRequired,
    updatePage: PropTypes.func.isRequired,
    updateSectionColumn: PropTypes.func.isRequired,
    updateSectionContent: PropTypes.func.isRequired,
    uploadImage: PropTypes.func.isRequired,
  }

  getSection = sectionId => this.props.sectionsList.find(section => section.id === sectionId)

  handleChangeNickname = event => {
    const { sectionsList, selectedSectionId, updateSectionColumn } = this.props
    let section = this.getSection(selectedSectionId)
    let eventValue
    if (event.target.value.trim() == '') {
      eventValue = ''
    } else {
      eventValue = event.target.value
    }
    updateSectionColumn(section, { nickname: eventValue })
  }

  handleSectionContent = (section, templateVariable, value) => {
    this.props.updateSectionContent(section.id, { [templateVariable.name]: value })
  }

  handleUploadImage = (name, sectionId, templateVariableId) => event => {
    const { uploadImage } = this.props
    let file = event.target.files[0]
    if (file) {
      return uploadImage(sectionId, {
        srcFile: file,
        urlField: event.target.name,
        tiny_mce_image: event.target['tiny_mce_image'],
      })
    }
  }

  addedFunctions = () => {
    return {
      handleUploadImage: this.handleUploadImage,
      handleSectionContent: this.handleSectionContent,
    }
  }

  renderTemplateVariables = section => {
    const { builderType, classes, editable, sectionHelper, selectedSectionId, templateVariables } = this.props
    let sectionTemplateVariables = templateVariables[selectedSectionId]

    // sort section template variables to fix a production only bug where page editor sections were
    // appearing with reversed section template variable ordering
    sectionTemplateVariables.sort((a, b) => a.ordering - b.ordering)
    return sectionTemplateVariables.map(templateVariable => {
      return FormHelper.renderVariableByType(
        section,
        templateVariable,
        editable,
        classes,
        this.addedFunctions,
        sectionHelper,
        builderType,
      )
    })
  }

  renderSelectedSectionEstimatedSize = () => {
    const { selectedSectionEstimatedSize, selectedSectionId } = this.props
    if (!selectedSectionId === selectedSectionEstimatedSize.section_id) {
      return null
    }
    return (
      <div className="estimated-size">
        <Typography variant="h6">Section size: ~{selectedSectionEstimatedSize['estimated_size']}</Typography>
      </div>
    )
  }

  renderDeleteSectionButton = section => {
    const { deleteSection, editable } = this.props

    return (
      <Button
        disabled={!editable}
        className="delete-button"
        onClick={() => {
          deleteSection(section)
        }}
        children="Delete Section"
        color="secondary"
      />
    )
  }

  render() {
    const { classes, editable, previewDirty, previewUpdated, sectionsList, selectedSectionId, show } = this.props
    let section = this.getSection(selectedSectionId)
    if (!show) {
      return null
    }
    if (!section || !selectedSectionId) {
      return (
        <div key="page-builder-editor" id="page-builder-editor-empty">
          <Typography>Please select a section in the sidebar.</Typography>
        </div>
      )
    }

    return (
      <div key="page-builder-editor" id="page-builder-editor">
        <Split
          sizes={[50, 50]}
          minSize={100}
          expandToMin={false}
          gutterSize={10}
          gutterAlign="center"
          snapOffset={30}
          dragInterval={1}
          direction="horizontal"
          cursor="col-resize"
          className="split-div">
          <div id="editor">
            <div id="header">
              <TextField
                key={`${section.id}-header-nickname`}
                disabled={!editable}
                type="text"
                name="nickname"
                margin="normal"
                fullWidth
                className="section-title-nickname"
                label={`Nickname for ${section.section_type}`}
                onChange={this.handleChangeNickname}
                value={section.nickname || ''}
              />
            </div>
            {this.renderSelectedSectionEstimatedSize()}
            <div key="template-variables-body" id="body">
              {this.renderTemplateVariables(section)}
              {this.renderDeleteSectionButton(section)}
            </div>
          </div>
          <PageBuilderPreview
            key="page-editor-preview"
            classes={classes}
            className="preview-div"
            previewDirty={previewDirty}
            previewHTMLType="section"
            previewUpdated={previewUpdated}
            sectionId={section.id}
            sectionContent={section.content}
          />
        </Split>
      </div>
    )
  }
}
