import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'

export default class DripCampaignTestSendButton extends Component {
  static propTypes = {
    dripCampaign: PropTypes.object.isRequired,
    dripCampaignHelper: PropTypes.object.isRequired,
    displayText: PropTypes.string.isRequired,
    testSendEmails: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = { processing: false }
  }

  displayText = () => {
    if (this.state.processing) {
      return 'SENDING'
    } else {
      return this.props.displayText
    }
  }

  testSend = () => {
    const { dripCampaign, dripCampaignHelper, testSendEmails } = this.props
    if (!this.state.processing) {
      if (!Object.values(testSendEmails).some(val => val)) {
        window.alert('Please specify which emails to test send to')
      } else {
        this.setState({ processing: true })
        let testSendEmailsFiltered = Object.keys(testSendEmails)
          .filter(k => testSendEmails[k])
          .map(String)
        let testSendRecipients = Object.values(testSendEmailsFiltered).join(', ')
        dripCampaignHelper
          .testSend(dripCampaign.id, testSendRecipients)
          .then(result => {
            this.setState({ processing: false })
          })
          .catch(error => {
            this.setState({ processing: false })
            console.error('Error sending test emails', error)
          })
      }
    }
  }

  render() {
    const { disabled } = this.props
    return (
      <Button
        key="send-test-drip-campaign"
        className="send-test-drip-campaigns-button"
        disabled={disabled}
        variant="outlined"
        color="primary"
        name="sendTestSegment"
        onClick={this.testSend}>
        {this.displayText()}
      </Button>
    )
  }
}
