import SegmentAPI from '../apis/segment_api.js.jsx'

export default class SegmentHelper {
  constructor() {
    this.initializeIndex = this.initializeIndex.bind(this)
    this.cleanSegmentData = this.cleanSegmentData.bind(this)
  }

  // cpid is optional. It is used to validate client urls (if the
  // user is not logged in)
  initializeIndex(cpid) {
    return new Promise((resolve, reject) => {
      SegmentAPI.index(cpid)
        .then(segments => {
          this.segmentList = segments.map(this.cleanSegmentData.bind(this))
          this.idSegmentMapping = {}
          segments.map(s => {
            this.idSegmentMapping[s.id] = this.cleanSegmentData(s)
          })
          this.enabledSegments = segments.filter(segment => segment.enabled)
          resolve(this)
        })
        .catch(error => {
          console.error(`Failed to initialize segment index: ${error}`)
          reject(error)
        })
    })
  }

  update(segmentId, updates) {
    return new Promise((resolve, reject) => {
      SegmentAPI.patch(segmentId, updates)
        .then(result => {
          this.segmentList.splice(this.segmentList.findIndex(seg => seg.id == result.id), 1, result)
          this.idSegmentMapping[result.id] = result
          resolve(result)
        })
        .catch(error => {
          console.error(`Failed to update segment id ${segmentId}'s enabled attribute.`)
          reject(error)
        })
    })
  }

  create(params) {
    return new Promise((resolve, reject) => {
      SegmentAPI.create(params)
        .then(result => {
          this.segmentList.push(result)
          this.idSegmentMapping[result.id] = result
          resolve(result)
        })
        .catch(error => {
          console.error(`Failed to create segment.`)
          reject(error)
        })
    })
  }

  segmentForId(id) {
    return this.idSegmentMapping[id]
  }

  nameForId(id) {
    return this.idSegmentMapping[id].name
  }

  idForName(name) {
    return this.segmentList.filter(segment => segment.name == name)[0].id
  }

  // Add up the ranges of email segments.
  // Returns true if the sum of the segmentsRange is 100, false otherwise.
  // Used in collaboration with the CampaignDeliverButton and ContactFillVerifier components
  // to check if exactly 100 contacts are represented. If so, unlock campaign delivery.
  calculateSegmentsRanges(emails) {
    let results = 0

    // Get a list of all segments for this campaign
    const campaignSegments = []

    if (this.segmentList) {
      emails.filter(email =>
        this.segmentList.forEach(
          segment => (segment.id == email.segment_id ? campaignSegments.push(segment) : () => {}),
        ),
      )

      // Build a 100 element array representing contacts, initialized to all 0s.
      // 1s in the array will signify a contact is represented by an existing emails segment.
      let segmentsRange = Array.apply(null, Array(100)).map(Number.prototype.valueOf, 0)

      // Check if each segment range is overwriting any existing 1s, error out if so.
      let segmentsOverlap = false
      campaignSegments.map(campaignSegment => {
        if (!segmentsOverlap) {
          if (
            segmentsRange
              .slice(campaignSegment['range_start'] - 1, campaignSegment['range_end'] - 1)
              .find(el => el == 1)
          ) {
            console.error(
              'Error calculating segments contacts ranges.' + ' Overlapping segments present, contacts invalid.',
            )
            segmentsOverlap = true
            return false
          } else {
            // Else, fill the array range with 1s if they're all 0s
            segmentsRange.fill(1, campaignSegment['range_start'] - 1, campaignSegment['range_end'])
          }
        }
      })

      if (segmentsOverlap) {
        return false
      } else {
        return segmentsRange.reduce((a, b) => a + b, 0) == 100
      }
    }
  }

  // Takes a segment data object and returns a boolean for whether or not it is
  // valid for creating a new segment.
  validToCreate(newSegment) {
    let requiredFieldsEmpty, rangesWithinOneHundred, rangesStartLessThanEnd

    if (newSegment.name.length > 0 && Number(newSegment.range_start) > 0 && Number(newSegment.range_end > 0)) {
      requiredFieldsEmpty = false
    } else {
      requiredFieldsEmpty = true
    }

    if (
      Number(newSegment.range_start) > 0 &&
      Number(newSegment.range_start) <= 100 &&
      Number(newSegment.range_end) > 0 &&
      Number(newSegment.range_end) <= 100
    ) {
      rangesWithinOneHundred = true
    } else {
      rangesWithinOneHundred = false
    }

    if (Number(newSegment.range_start) < Number(newSegment.range_end)) {
      rangesStartLessThanEnd = true
    } else {
      rangesStartLessThanEnd = false
    }

    if (!requiredFieldsEmpty && rangesWithinOneHundred && rangesStartLessThanEnd) {
      return true
    } else {
      return false
    }
  }

  // Remove any nulls or undefined values from the segment data. Then
  // default any missing values to blank
  cleanSegmentData(segment) {
    for (let key in segment) {
      if (segment[key] == null) {
        delete segment[key]
      }
    }
    return Object.assign(this.blank(), segment)
  }

  columnAttributes() {
    return ['id', 'name', 'range_start', 'range_end', 'friendly_name', 'enabled']
  }

  // Return a blank segment
  blank() {
    return {
      id: '',
      name: '',
      range_start: 0,
      range_end: 0,
      friendly_name: '',
      enabled: false,
    }
  }
}
