class SectionTemplateAPI {
  static jsonHeaders() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    }
  }

  static index(params) {
    let paramsStr = Object.keys(params)
      .map(key => key + '=' + encodeURIComponent(params[key]))
      .join('&')
    let url = '/section_templates?' + paramsStr
    let request = new Request(url, { credentials: 'same-origin' })
    return fetch(request)
      .then(response => response.json())
      .catch(error => {
        console.error('Unable to fetch section templates: ' + error)
      })
  }

  static show(id) {
    let url = '/section_templates/' + id
    let request = new Request(url, { credentials: 'same-origin' })
    return fetch(request)
      .then(response => response.json())
      .catch(error => {
        console.error('Unable to fetch section templates: ' + error)
      })
  }

  static create(templateData) {
    return new Promise((resolve, reject) => {
      let body = { section_template: templateData }
      let options = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = '/section_templates.json'
      let request = new Request(url, options)
      fetch(request).then(response => {
        response
          .json()
          .then(jsonData => resolve(jsonData))
          .catch(error => reject(error))
      })
    })
  }

  static patch(templateId, templateData) {
    return new Promise((resolve, reject) => {
      let body = { section_template: templateData }
      let options = {
        credentials: 'same-origin',
        method: 'PUT',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = '/section_templates/' + templateId + '.json'
      let request = new Request(url, options)
      fetch(request).then(response => {
        response
          .json()
          .then(jsonData => resolve(jsonData))
          .catch(error => reject(error))
      })
    })
  }

  static preview(templateId, variables) {
    return new Promise((resolve, reject) => {
      let body = { section_template_variables: variables }
      let options = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = '/section_templates/' + templateId + '/preview.json'
      let request = new Request(url, options)
      fetch(request).then(response => {
        response
          .json()
          .then(jsonData => resolve(jsonData))
          .catch(error => reject(error))
      })
    })
  }
}

export { SectionTemplateAPI }
