import AbTestCampaignAPI from '../apis/ab_test_campaign_api.js.jsx'

export default class AbTestCampaignHelper {
  initializeWithVariantCampaigns = id => {
    return new Promise((resolve, reject) => {
      AbTestCampaignAPI.findWithVariantCampaigns({ id: id })
        .then(AbTestCampaign => {
          resolve(AbTestCampaign)
        })
        .catch(error => {
          console.error(`Failed to initialize AbTestCampaign index: ${error}`)
          reject(error)
        })
    })
  }

  update = (aBTestCampaignId, campaign) => {
    return new Promise((resolve, reject) => {
      return AbTestCampaignAPI.update(aBTestCampaignId, campaign)
        .then(AbTestCampaign => {
          resolve(AbTestCampaign)
        })
        .catch(error => {
          console.error(`Failed to initialize AbTestCampaign index: ${error}`)
          reject(error)
        })
    })
  }

  replicateEmailsSectionForAbTestCampaign = (aBTestCampaignId, emailsSectionId, ordering) => {
    return new Promise((resolve, reject) => {
      return AbTestCampaignAPI.replicateEmailsSectionForAbTestCampaign(aBTestCampaignId, emailsSectionId, ordering)
        .then(AbTestCampaign => {
          resolve(AbTestCampaign)
        })
        .catch(error => {
          console.error(`Failed to initialize AbTestCampaign index: ${error}`)
          reject(error)
        })
    })
  }

  schedule = (aBTestCampaignId, time) => {
    return new Promise((resolve, reject) => {
      return AbTestCampaignAPI.schedule(aBTestCampaignId, time)
        .then(AbTestCampaign => {
          resolve(AbTestCampaign)
        })
        .catch(error => {
          console.error(`Failed to schedule AbTestCampaign ID: ${error}`)
          reject(error)
        })
    })
  }

  unschedule = aBTestCampaignId => {
    return new Promise((resolve, reject) => {
      return AbTestCampaignAPI.unschedule(aBTestCampaignId)
        .then(AbTestCampaign => {
          resolve(AbTestCampaign)
        })
        .catch(error => {
          console.error(`Failed to schedule AbTestCampaign ID: ${error}`)
          reject(error)
        })
    })
  }

  patchSailthruAbTestCampaign = aBTestCampaignId => {
    return new Promise((resolve, reject) => {
      return AbTestCampaignAPI.patchSailthruAbTestCampaign(aBTestCampaignId)
        .then(AbTestCampaign => {
          resolve(AbTestCampaign)
        })
        .catch(error => {
          console.error(`Failed to initialize AbTestCampaign index: ${error}`)
          reject(error)
        })
    })
  }
}
