import { SectionTemplateAPI } from '../apis/section_template_api.js.jsx'
import { BatchFunctionCaller } from '../utils.js.jsx'

class SectionTemplateHelper {
  constructor(updateCallback) {
    this.updateQueue = new BatchFunctionCaller(this.bulkUpdateSectionTemplates, 3000, updateCallback)
  }

  bulkUpdateSectionTemplates(stDictionary) {
    let promiseArray = []
    for (let stId in stDictionary) {
      SectionTemplateAPI.patch(stId, stDictionary[stId])
    }
    return Promise.all(promiseArray)
  }

  queueUpdate(stId, key, newValue) {
    let queueObject = this.updateQueue.getQueueItem(stId) || { id: stId }
    queueObject[key] = newValue
    this.updateQueue.queue(stId, queueObject)
  }

  sortTemplates = templates => {
    return templates.sort((a, b) => {
      if (a['version_name'].toLowerCase() < b['version_name'].toLowerCase()) {
        return -1
      } else if (a['version_name'].toLowerCase() > b['version_name'].toLowerCase()) {
        return 1
      } else {
        return 0
      }
    })
  }

  activeTemplates(params) {
    return SectionTemplateAPI.index(Object.assign({ active: true }, params)).then(templates =>
      this.sortTemplates(templates),
    )
  }

  index(params) {
    return SectionTemplateAPI.index(params || {}).then(templates => this.sortTemplates(templates))
  }

  show(id) {
    return SectionTemplateAPI.show(id)
  }

  create(newData) {
    return SectionTemplateAPI.create(newData)
  }

  preview(sectionTemplateId, variables) {
    return SectionTemplateAPI.preview(sectionTemplateId, variables)
  }

  blank() {
    return {
      template: '',
      version: '',
      version_name: '',
      active: true,
      published: true,
    }
  }
}

export { SectionTemplateHelper }
