import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { SectionTemplateHelper } from '../helpers/section_template_helper.js.jsx'
import { BatchFunctionCaller } from '../utils.js.jsx'
import FormHelper from '../helpers/form_helper.js.jsx'

export default class SectionTemplateSectionPreview extends Component {
  static propTypes = {
    previewDirty: PropTypes.bool.isRequired,
    previewUpdated: PropTypes.func.isRequired,
    sectionTemplate: PropTypes.object.isRequired,
    section: PropTypes.object.isRequired,
    variables: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      preview: '',
      lastUpdate: 0,
    }
    this.sectionTemplateHelper = new SectionTemplateHelper()
    this.updatePreviewThrottled = new BatchFunctionCaller(this.updatePreview, 2000)
  }

  componentDidUpdate = () => {
    this.updatePreviewThrottled.queue(0, {
      notUpdated: "this value doesn't get saved, but needs to exist for the call.",
    })
  }

  valueFor = stv => {
    const { section } = this.props
    let sectionValue = section.content[stv.name]
    let defaultValue = _.isEmpty(stv.default_value) ? null : stv.default_value
    switch (stv.variable_type) {
      case 'url':
        return sectionValue || defaultValue || 'https://xkcd.com/'
        break
      case 'image_url':
        return sectionValue || defaultValue || 'https://thdaily.s3-us-west-1.amazonaws.com/tuft_20190809201806.jpg'
        break
      case 'text':
        return sectionValue || defaultValue || 'Lorem Ipsum dolor sit amet, consectetur adipiscing elit'
        break
      case 'code_editor':
        return sectionValue || defaultValue || '<script>console.log("hello from the code editor")</script>'
        break
      case 'power_editor':
        return (
          sectionValue ||
          defaultValue ||
          '<h2>Section Template Editor - power_editor</h2><p>Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p><h2>Sed ut perspiciatis</h2><p>unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>'
        )
        break
      case 'rich_text':
        return (
          sectionValue ||
          defaultValue ||
          '<h2>Lorem ipsum dolor sit amet</h2><p>Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p><h2>Sed ut perspiciatis</h2><p>unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>'
        )
        break
      case 'text_area':
        return (
          sectionValue ||
          defaultValue ||
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
        )
        break
      case 'select_dropdown':
        return sectionValue || defaultValue || ''
        break
      case 'author_name':
        return sectionValue || defaultValue || ''
        break
      case 'word_press_upload':
        return ''
        break
      case 'brief_link_generator':
        return ''
        break
      case 'boolean':
        return sectionValue != null ? sectionValue : defaultValue === 't'
        break
      default:
        return 'I do not recognize this variable type: ' + stv.variable_type
        break
    }
  }

  updatePreview = () => {
    const { previewDirty, previewUpdated, sectionTemplate, variables } = this.props
    if (sectionTemplate.id == null || !previewDirty) {
      return null
    }
    let variablesNames = variables.map(variable => {
      let value = this.valueFor(variable)
      return { name: variable.name, value: value }
    })
    this.sectionTemplateHelper.preview(sectionTemplate.id, variablesNames).then(json => {
      this.setState({ preview: json.preview }, previewUpdated)
    })
  }

  render() {
    const { variables } = this.props
    const { preview } = this.state

    return (
      <div id="section-preview">
        <div id="header">Section Preview</div>
        <div id="section-viewer">
          <div dangerouslySetInnerHTML={{ __html: preview }} />
        </div>
      </div>
    )
  }
}
