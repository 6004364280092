import BaseAPI from './base_api.js'

export default class PageAPI extends BaseAPI {
  resourceName = params => 'page'
  baseUrl = params => '/pages'

  pagesSections = pageId => {
    return new Promise((resolve, reject) => {
      let url = `/pages/${pageId}/pages_sections.json`
      let request = new Request(url, { credentials: 'same-origin' })
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  }

  getEstimatedPageSize = pageId => {
    return new Promise((resolve, reject) => {
      let url = '/pages/' + pageId + '/estimate_page_size.json'
      let request = new Request(url, { credentials: 'same-origin' })
      fetch(request)
        .then(response => {
          response
            .json()
            .then(jsonData => resolve(jsonData))
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    })
  }
}
