import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { EmailHelper } from '../helpers/email_helper.js.jsx'
import { Button } from '@material-ui/core'

export default class EmailLineView extends Component {
  static propTypes = {
    campaign: PropTypes.object,
    email: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.emailHelper = new EmailHelper()
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.emailHelper.flushUpdates)
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.emailHelper.flushUpdates)
  }

  renderViewHtmlButton = section => {
    const { email } = this.props

    return (
      <Button
        className="view-html-button"
        onClick={() => window.open(`/emails/${email.id}/html`)}
        children="View HTML"
        variant="outlined"
        color="primary"
      />
    )
  }

  renderPreviewEmailButton = section => {
    const { email } = this.props

    return (
      <Button
        className="preview-email-button"
        onClick={() => window.open(`/emails/${email.id}/preview`)}
        children="Preview Email"
        variant="outlined"
        color="primary"
      />
    )
  }

  render() {
    const { campaign, email } = this.props
    if (campaign && email) {
      return (
        <div className="preview-and-view">
          <div className="preview-email">{this.renderPreviewEmailButton()}</div>
          <div className="view-html">{this.renderViewHtmlButton()}</div>
        </div>
      )
    } else {
      return null
    }
  }
}
