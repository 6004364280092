import BaseAPI from './base_api.js'

class ConfigValueAPI extends BaseAPI {
  resourceName = () => 'config_value'
  baseUrl = params => '/config_values'
  indexUrl = params => {
    let paramsStr = Object.keys(params)
      .map(key => key + '=' + encodeURIComponent(params[key]))
      .join('&')
    return `${this.baseUrl(params)}.json?${paramsStr}`
  }
}

export { ConfigValueAPI }
