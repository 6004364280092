class ContactAPI {
  static jsonHeaders() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    }
  }

  // Returns a promise that will yield an array of contact json data
  static index(params) {
    var paramsStr = Object.keys(params)
      .map(function(key) {
        return key + '=' + encodeURIComponent(params[key])
      })
      .join('&')
    return new Promise(function(resolve, reject) {
      var url = '/contacts.json?' + paramsStr
      var request = new Request(url, { credentials: 'same-origin' })
      fetch(request).then(function(response) {
        response
          .json()
          .then(jsonData => resolve(jsonData))
          .catch(error => reject(error))
      })
    })
  }

  static show(id) {
    var url = '/contacts/' + id + '.json'
    var request = new Request(url, { credentials: 'same-origin' })
    return fetch(request)
      .then(response => response.json())
      .catch(error => {
        console.error('Unable to fetch contact: ' + error)
      })
  }

  static extendedData(id) {
    var url = '/contacts/' + id + '/extended_data.json'
    var request = new Request(url, { credentials: 'same-origin' })
    return fetch(request)
      .then(response => response.json())
      .catch(error => {
        console.error('Unable to fetch contact extended data: ' + error)
      })
  }

  static stats(id) {
    var url = '/contacts/' + id + '/stats.json'
    var request = new Request(url, { credentials: 'same-origin' })
    return fetch(request)
      .then(response => response.json())
      .catch(error => {
        console.error('Unable to fetch contact extended data: ' + error)
      })
  }

  static events(id) {
    var url = '/contacts/' + id + '/events.json'
    var request = new Request(url, { credentials: 'same-origin' })
    return fetch(request)
      .then(response => response.json())
      .catch(error => {
        console.error('Unable to fetch contact extended data: ' + error)
      })
  }

  static patch(id, updates) {
    return new Promise((resolve, reject) => {
      let body = { contact: updates }
      let options = {
        credentials: 'same-origin',
        method: 'PUT',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = '/contacts/' + id + '.json'
      let request = new Request(url, options)
      fetch(request).then(response => {
        response
          .json()
          .then(jsonData => resolve(jsonData))
          .catch(error => reject(error))
      })
    })
  }

  static transferReferrals(id, email) {
    return new Promise((resolve, reject) => {
      let body = { email: email }
      let options = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = '/contacts/' + id + '/transfer_referrals.json'
      let request = new Request(url, options)
      fetch(request).then(response => {
        response
          .json()
          .then(jsonData => resolve(jsonData))
          .catch(error => reject(error))
      })
    })
  }

  static anonymize(email) {
    return new Promise((resolve, reject) => {
      let body = { email: email }
      let options = {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify(body),
        headers: this.jsonHeaders(),
      }
      let url = '/contacts/anonymize.json'
      let request = new Request(url, options)
      fetch(request).then(response => {
        response
          .json()
          .then(jsonData => resolve(jsonData))
          .catch(error => reject(error))
      })
    })
  }
}

export { ContactAPI }
