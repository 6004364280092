import DivisionApi from '../apis/division_api.js'

export default class DivisionHelper {
  constructor() {
    this.dApi = new DivisionApi()
  }

  index = params => {
    return this.dApi.index(params)
  }
}
