import { EmailAPI } from '../apis/email_api.js.jsx'

class EmailHelper {
  constructor() {
    this.updateQueue = []
    this.updateTimer = null

    this.flushUpdates = this.flushUpdates.bind(this)
  }

  flushUpdates() {
    if (this.updateTimer) {
      clearTimeout(this.updateTimer)
      this.updateTimer = null
    }
    let promise = Promise.all(
      this.updateQueue.map(function(simpleEmail) {
        return EmailAPI.patch(simpleEmail.id, simpleEmail).catch(function(error) {
          console.error('Failed to update email with id: ' + simpleEmail.id + '. ' + error)
        })
      }),
    )
    this.updateQueue = []
    return promise
  }

  update(simpleEmail) {
    if (!this.updateTimer) {
      this.updateTimer = setTimeout(this.flushUpdates, 3000)
    }
    let index = this.updateQueue
      .map(function(queuedEmail) {
        return queuedEmail.id === simpleEmail.id
      })
      .indexOf(true)
    if (index >= 0) {
      this.updateQueue[index] = Object.assign(this.updateQueue[index], simpleEmail)
    } else {
      this.updateQueue.push(simpleEmail)
    }
  }

  preview(emailId) {
    return new Promise((resolve, reject) => {
      EmailAPI.preview(emailId)
        .then(previewHTML => {
          return resolve(previewHTML)
        })
        .catch(error => {
          console.error('Error resolving response text for EmailAPI.preview: ')
          reject(error)
        })
    })
  }

  del(emailId) {
    return EmailAPI.del(emailId)
  }

  blank(id) {
    return {
      id: id,
      subject: '',
      identifier: '',
      campaign_id: -1,
      sgid: -1,
      segment_id: '',
      sent: true,
    }
  }

  cleanSimpleEmailJsonData(jsonData) {
    if (jsonData.subject === null) {
      jsonData.subject = ''
    }
    if (jsonData.identifier === null) {
      jsonData.identifier = ''
    }
    if (jsonData.segment_id === null) {
      jsonData.segment_id = ''
    } else {
      jsonData.segment_id = jsonData.segment_id.toString()
    }
    return jsonData
  }

  find(id) {
    return new Promise(
      function(resolve, reject) {
        return EmailAPI.show(id)
          .then(
            function(jsonData) {
              resolve(this.cleanSimpleEmailJsonData(jsonData))
            }.bind(this),
          )
          .catch(function(error) {
            console.error('Failed to load email id: ' + id + '. ' + error)
          })
      }.bind(this),
    )
  }

  emailsFromCampaignId(campaignId) {
    return new Promise((resolve, reject) => {
      return EmailAPI.index({ campaign_id: campaignId })
        .then(jsonArray => {
          resolve(jsonArray.map(jsonData => this.cleanSimpleEmailJsonData(jsonData)))
        })
        .catch(error => {
          console.error('Failed to load email from campaign id: ' + campaignId + '. ' + error)
        })
    })
  }

  // This only supports creating with campaignId
  create(data) {
    let cleanedData = {}
    if (data.campaignId != null) {
      cleanedData.campaign_id = data.campaignId
    }
    if (data.segmentId != null) {
      cleanedData.segment_id = data.segmentId
    }
    return new Promise((resolve, reject) => {
      EmailAPI.create(cleanedData)
        .then(jsonData => {
          resolve(this.cleanSimpleEmailJsonData(jsonData))
        })
        .catch(error => {
          console.error('Failed to create new email: ' + error)
        })
    })
  }

  updateSendGrid(emailId) {
    return new Promise(
      function(resolve, reject) {
        return EmailAPI.updateSendGrid(emailId)
          .then(
            function(emailJson) {
              resolve(this.cleanSimpleEmailJsonData(emailJson))
            }.bind(this),
          )
          .catch(function(error) {
            console.error('Failed to update send grid for email id: ' + emailId + '. ' + error)
          })
      }.bind(this),
    )
  }
}

export { EmailHelper }
